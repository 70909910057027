import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  customersQuery$: any;
  customers: any[] = [];
  customers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private api: ApiService
  ) { }

  getAllCustomers() {
    return this.api.getAPI('customers').pipe(map((response) => response.body));
  }

  getCustomerById(id: number) {
    return this.api.getAPI(`customers/${id}`).pipe(map((response) => response.body));
  }

  createCustomer(customer: any) {
    return this.api.postAPI('customers', customer).pipe(map((response) => response.body));
  }

  updateCustomer(customer: any, customer_id: number) {
    return this.api.updateAPI(`customers/${customer.id}`, customer_id, customer).pipe(map((response) => response.body));
  }
}
