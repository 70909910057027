import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { UserService } from 'src/app/_services';
import { DialogService } from 'primeng/dynamicdialog';
import { SwitchWebhookDialogComponent } from '../switch-webhook-dialog/switch-webhook-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-switch-webhook-admin',
  templateUrl: './switch-webhook-admin.component.html',
  styleUrls: ['./switch-webhook-admin.component.scss'],
})
export class SwitchWebhookAdminComponent implements OnInit {
  webhooks: any[] = [];
  user: any;
  loading: boolean = false;
  userWebhooks: any[] = [];

  constructor(
    private fb: FormBuilder,
    private switchWebhookService: SwitchWebhookService,
    private userService: UserService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.switchWebhookService.getWebhookByAdminId().subscribe((response: any) => {
      const groupedWebhooks: { [key: number]: any } = {};
  
      // Group webhooks by user_id
      response.body.forEach((webhook: any) => {
        if (!groupedWebhooks[webhook.user_id]) {
          groupedWebhooks[webhook.user_id] = {
            user_id: webhook.user_id, // Placeholder until user details are fetched
            user_details: null, // Will be replaced with user details
            webhooks: [],
          };
        }
        groupedWebhooks[webhook.user_id].webhooks.push(webhook);
      });
  
      // Get unique user IDs
      const userIds = Object.keys(groupedWebhooks).map(id => Number(id));
  
      // Fetch user details for each user_id
      const userRequests = userIds.map(id => this.userService.getUserById(id));
  
      forkJoin(userRequests).subscribe(users => {
        users.forEach(user => {
          if (user.id !== undefined && groupedWebhooks[user.id]) {
            groupedWebhooks[user.id].user_details = user;
          }
        });
  
        // Convert object to array
        this.userWebhooks = Object.values(groupedWebhooks);
      });
    });
  }
  

  openDialog(webhook: any) {
    const ref = this.dialogService.open(SwitchWebhookDialogComponent, {
      header: 'Submit Webhook',
      width: '40%',
      data: webhook,
    });
  }

  deleteWebhook(webhook: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${webhook.webhook_name}?`,
      accept: () => {
        this.switchWebhookService.deleteSwitchWebhookData(webhook.id).subscribe(
          () => {
            // Find the userWebhook containing the webhook
            this.userWebhooks.forEach((userWebhook) => {
              userWebhook.webhooks = userWebhook.webhooks.filter(
                (wh: any) => wh.id !== webhook.id
              );
            });

            // Optionally, remove empty userWebhooks if needed
            this.userWebhooks = this.userWebhooks.filter(
              (userWebhook) => userWebhook.webhooks.length > 0
            );

            // Show success message
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Webhook Deleted Successfully',
              key: 'delete_webhook',
            });
          },
          (error) => {
            // Handle error
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Failed to delete the webhook',
              key: 'delete_webhook',
            });
          }
        );
      },
    });
  }
}
