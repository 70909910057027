<div class="p-fluid flex flex-column p-5 lg:flex-row">
  <ul
    class="list-none m-0 p-0 flex flex-row lg:flex-column justify-content-between lg:justify-content-start mb-5 lg:mb-0"
  >
    <li>
      <a
        pRipple
        class="lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
        [ngClass]="{ 'surface-200': activeTab3 === 0 }"
        (click)="activeTab3 = 0"
      >
        <i
          class="pi pi-user md:mr-2"
          [ngClass]="{
            'text-700': activeTab3 === 0,
            'text-600': activeTab3 !== 0
          }"
        ></i>
        <span
          class="font-medium hidden md:block"
          [ngClass]="{
            'text-800': activeTab3 === 0,
            'text-700': activeTab3 !== 0
          }"
          >Profile</span
        >
      </a>
    </li>
    <li>
      <a
        pRipple
        class="lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
        [ngClass]="{ 'surface-200': activeTab3 === 1 }"
        (click)="activeTab3 = 1"
      >
        <i
          class="pi pi-cog md:mr-2"
          [ngClass]="{
            'text-700': activeTab3 === 1,
            'text-600': activeTab3 !== 1
          }"
        ></i>
        <span
          class="font-medium hidden md:block"
          [ngClass]="{
            'text-800': activeTab3 === 1,
            'text-700': activeTab3 !== 1
          }"
          >Account</span
        >
      </a>
    </li>
    <li>
      <a
        pRipple
        class="lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
        [ngClass]="{ 'surface-200': activeTab3 === 2 }"
        (click)="activeTab3 = 2"
      >
        <i
          class="pi pi-palette md:mr-2"
          [ngClass]="{
            'text-700': activeTab3 === 2,
            'text-600': activeTab3 !== 2
          }"
        ></i>
        <span
          class="font-medium hidden md:block"
          [ngClass]="{
            'text-800': activeTab3 === 2,
            'text-700': activeTab3 !== 2
          }"
          >Appearance</span
        >
      </a>
    </li>
    <li>
      <a
        pRipple
        class="lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
        [ngClass]="{ 'surface-200': activeTab3 === 3 }"
        (click)="activeTab3 = 3"
      >
        <i
          class="pi pi-sun md:mr-2"
          [ngClass]="{
            'text-700': activeTab3 === 3,
            'text-600': activeTab3 !== 3
          }"
        ></i>
        <span
          class="font-medium hidden md:block"
          [ngClass]="{
            'text-800': activeTab3 === 3,
            'text-700': activeTab3 !== 3
          }"
          >Accessibility</span
        >
      </a>
    </li>
    <li>
      <a
        pRipple
        class="lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors"
        [ngClass]="{ 'surface-200': activeTab3 === 4 }"
        (click)="activeTab3 = 4"
      >
        <i
          class="pi pi-bell md:mr-2"
          [ngClass]="{
            'text-700': activeTab3 === 4,
            'text-600': activeTab3 !== 4
          }"
        ></i>
        <span
          class="font-medium hidden md:block"
          [ngClass]="{
            'text-800': activeTab3 === 4,
            'text-700': activeTab3 !== 4
          }"
          >Notifications</span
        >
      </a>
    </li>
  </ul>
  <div class="surface-border p-5 shadow-2 border-round flex-auto xl:ml-5">
    <div class="text-900 font-semibold text-lg mt-3">Profile</div>
    <p-divider></p-divider>
    <div class="flex gap-5 flex-column-reverse md:flex-row">
      <div class="flex-auto p-fluid">
        <div class="mb-4">
          <label for="email" class="block font-normal text-900 mb-2"
            >Name</label
          >
          <input id="email" type="text" pInputText />
        </div>
        <div class="mb-4">
          <label for="bio" class="block font-normal text-900 mb-2">Bio</label>
          <textarea
            id="bio"
            type="text"
            pInputTextarea
            rows="5"
            [autoResize]="true"
          ></textarea>
          <p class="mt-2 mb-0 font-medium text-base text-600">
            You can <span class="font-medium text-900">@mention</span> other
            users and organizations to link to them.
          </p>
        </div>
        <div class="mb-4">
          <label for="website" class="block font-normal text-900 mb-2"
            >URL</label
          >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">https://</span>
            <input id="website" type="text" pInputText />
          </div>
        </div>
        <div class="mb-4">
          <label for="company" class="block font-normal text-900 mb-2"
            >Company</label
          >
          <input id="company" type="text" pInputText />
        </div>
        <div class="mb-4">
          <label for="visibility" class="block font-normal text-900 mb-2"
            >Profile Visibility</label
          >
          <div class="flex align-items-center">
            <p-checkbox
              [(ngModel)]="value1"
              [binary]="true"
              inputId="visibility"
            ></p-checkbox>
            <span class="ml-2 font-normal text-base text-color-primary"
              >Make profile private and hide all activity</span
            >
          </div>
        </div>
        <div>
          <button
            pButton
            pRipple
            label="Update Profile"
            class="w-auto mt-3"
          ></button>
        </div>
      </div>
      <div class="flex flex-column align-items-center flex-or">
        <span class="font-normal text-900 mb-2">Profile Picture</span>
        <img
          src="assets/images/blocks/avatars/circle-big/avatar-f-2.png"
          class="h-10rem w-10rem"
        />
        <button
          pButton
          type="button"
          icon="pi pi-pencil"
          class="p-button-rounded -mt-4"
        ></button>
      </div>
    </div>
  </div>
</div>
<div class="surface-section px-6 p-3 lg:px-3 mt-5">
  <div
    class="flex flex-column sm:flex-row sm:align-items-center justify-content-between"
  >
    <div>
      <img
        src="assets/images/blocks/logos/hyper-900.svg"
        alt="Image"
        height="40"
      />
      <p class="mt-2 mb-0 line-height-3 font-medium text-base text-700">
        &copy; 202X Hyper, Inc. All rights reserved.
      </p>
    </div>
    <div class="mt-3 sm:mt-0">
      <a
        class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700"
      >
        <i class="pi pi-twitter text-xl"></i>
      </a>
      <a
        class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
      >
        <i class="pi pi-facebook text-xl"></i>
      </a>
      <a
        class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
      >
        <i class="pi pi-github text-xl"></i>
      </a>
    </div>
  </div>
</div>
