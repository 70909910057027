import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakWord'
})
export class BreakWordPipe implements PipeTransform {

  transform(value: string, ...args: any[]) {
    return value.replace(/:|;/g, '<br>'); //replace all ',' and ';' with <br>
  }

}
