import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuotesService {
  quotesQuery$: any;
  quotes: any[] = [];
  quotes$: Subject<any[]> = new Subject<any[]>();

  constructor(private api: ApiService) {}

  createNewQuote(quote: any) {
    return this.api.postAPI('quote/new-quote', quote);
  }

  createQuote(quote: any) {
    return this.api.postAPI('quotes', quote);
  }

  addQuote(quote: any) {
    return this.api.postAPI('quote/save-quote', quote);
  }

  getQuotes() {
    this.quotesQuery$ = this.api.getAPI('quotes');
    this.quotesQuery$.subscribe((response: any) => {
      this.quotes = response.body;
      this.quotes$.next(this.quotes);
    });

    return this.quotesQuery$;
  }

  sendEmail(user_email: any, product_details: any) {
    return this.api.postAPI(`quote/notify-user?user_email=${user_email}`, product_details);
  }

  sendQuoteEmail(user_email: any, quote: any) {
    return this.api.postAPI(`quote/send-quote?user_email=${user_email}&quote=${quote}`, quote)
  }

  getSalesQuotes(quote_id: number) {
    return this.api.getAPI(`get-quote/${quote_id}`);
  }

  getQuoteById(user_id: any) {
    return this.api.getAPI(`quote/get-quotes/${user_id}`);   
  }

  getSalesQuoteByID(quote_id: any) {
    return this.api.getAPI(`quote/get-sales-quote/${quote_id}`);
  }
}
