import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FilesService } from 'src/app/_services/files.service';
import { QuotesService } from 'src/app/_services/quotes.service';

@Component({
  selector: 'app-artwork-details',
  templateUrl: './artwork-details.component.html',
  styleUrls: ['./artwork-details.component.scss'],
})
export class ArtworkDetailsComponent implements OnInit {
  constructor(
    public config: DynamicDialogConfig,
    public filesService: FilesService,
    public quotesService: QuotesService
  ) {}
  data: any;
  formattedDate: any;
  actions: any[] = [];
  status: any = {};
  original_filename: any;
  submission_file_name: any;
  unique_file_reference: any;
  report_data: any = {};
  quote_data: any = {};

  ngOnInit(): void {
    const data = this.config.data.formdata;
    this.original_filename = data.original_filename;
    this.unique_file_reference = data.unique_file_reference;
    

    this.filesService.get_operation_status(data.id).subscribe(
      (response: any) => {
        response.body[1].map((file: any) => {
          this.status = file;
          if (file.report_submission_details != null) {
            this.report_data = file.report_submission_details;
          }
          if(file.quote_id != null) {
            this.quotesService.getSalesQuoteByID(file.quote_id).subscribe(
              (response: any) => {
                this.quote_data = response.body;
              },
              (error) => {
                console.error('error', error);
              }
            );
          }
        });
        response.body[0].map((file: any) => {        
          this.actions.push(file);          
          this.filesService
            .get_file_submissions(this.actions[0].artwork_id)
            .subscribe((response: any) => {
              this.submission_file_name = response.body.file_name;
            }, (error) => {
              console.error('error', error)
        });
          
          
        });
      },
      (error) => {
        console.error('error', error);
      }
    );
  }

  downloadFile(artwork_id: number) {
    this.filesService.downloadSubmissionsFile(artwork_id);
  }

  downloadReportFile(report_id: number) {
    this.filesService.download_report_file(report_id);
  }
}
