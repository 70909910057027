import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/_services';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss'],
})
export class ReviewDialogComponent implements OnInit {
  data: any;
  loading: boolean = false;
  row_count: number = 0;
  user: any;
  status: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private bulkOrderFormService: BulkOrderFormService,
    private messageService: MessageService,
    private userService: UserService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;
    
    
    this.row_count = this.data.order.bulk_orders.row_count;
   

    this.bulkOrderFormService.getBulkOrderActions(this.data.order.id).subscribe((res: any) => {
      this.status = res.body.action_type;
      
      
    });
  }

  columns = [
    { field: 'refering_doctor', header: 'Refering Doctor' },
    { field: 'mnem', header: 'Mnem' },
    { field: 'copy_doctor_1', header: 'Copy Doctor 1' },
    { field: 'copy_doctor_2', header: 'Copy Doctor 2' },
    { field: 'copy_doctor_3', header: 'Copy Doctor 3' },
    { field: 'hospital_ward', header: 'Hospital Ward' },
    { field: 'quantity', header: 'Quantity' },
    { field: 'size', header: 'Size' },
    { field: 'biocross', header: 'Biocross' },
  ];

  getSeverity(status: string) {
    if (status === 'reviewed') {
      return 'success';
    } else if (status === 'rejected') {
      return 'error';
    } else if (status === 'submitted_for_review') {
      this.status = 'Pending';
      return 'info';
    }else {
      return 'info';
    }
  }

  submitOrder() {
    this.loading = true;
    
    this.bulkOrderFormService
      .reviewBulkOrder(this.data.order.id, this.data.order.bulk_orders)
      .subscribe(
        (res: any) => {
          this.bulkOrderFormService.getBulkOrderActionCount().subscribe((data) => {
            this.bulkOrderFormService.updateReviewCount(data.body);
          });
          this.bulkOrderFormService.getBulkOrderSubmitted().subscribe((response: any) => {
            this.bulkOrderFormService.updateOrders(response.body);
          });
          this.loading = false;
        },
        (error) => {
          
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Error submitting order: ${error.message}`,
            key: 'bulk-order-dialog',
          });
          this.bulkOrderFormService.getBulkOrderSubmitted().subscribe((response: any) => {
            this.bulkOrderFormService.updateOrders(response.body);
          });
        },
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Order submitted successfully',
            key: 'bulk-order-dialog',
          });
          this.ref.close(this.data);
        }
      );
  }

  rejectOrder() {
    this.loading = true;

    this.bulkOrderFormService
      .rejectBulkOrder(this.data.order.id, this.data.order.bulk_orders)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.bulkOrderFormService.getBulkOrderSubmitted().subscribe((response: any) => {
            this.bulkOrderFormService.updateOrders(response.body);
          });
          this.bulkOrderFormService.getBulkOrderActionCount().subscribe((data) => {
            this.bulkOrderFormService.updateReviewCount(data.body);
          });
          
        },
        (error) => {
          
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Error submitting order: ${error.message}`,
            key: 'review-bulk-order-dialog',
          });
          this.bulkOrderFormService.getBulkOrderSubmitted().subscribe((response: any) => {
            this.bulkOrderFormService.updateOrders(response.body);
          });
          this.ref.close(this.data);
        },
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Order rejected successfully',
            key: 'review-bulk-order-dialog',
          });
          this.ref.close(this.data);
        }
      );

    
  }

  deleteOrder() {
    this.loading = true;
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this order?',
      header: 'Delete Order',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.bulkOrderFormService.deleteBulkOrder(this.data.order.id).subscribe(
          (res: any) => {
            this.loading = false;
            this.bulkOrderFormService.getBulkOrderSubmitted().subscribe((response: any) => {
              this.bulkOrderFormService.updateOrders(response.body);
            });
            this.bulkOrderFormService.getBulkOrderActionCount().subscribe((data) => {
              this.bulkOrderFormService.updateReviewCount(data.body);
            });
          },
          (error) => {
            
            this.loading = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `Error deleting order: ${error.message}`,
              key: 'review-bulk-order-dialog',
            });
            
          },
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Order deleted successfully',
              key: 'review-bulk-order-dialog',
            });
            this.ref.close(this.data);
          }
        );
      },
      reject: () => {
        this.loading = false;
      },
    });
  }

  closeDialog() {
    this.ref.close();
  }
}
