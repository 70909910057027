<div class="max-w-full">
  <div class="flex flex-row">
    <div class="shadow-2 m-2 w-full">
      <div class="flex flex-column p-4">
        <div>
          <h1>View Submit Points</h1>
        </div>
        <div>
          <span>View all webhook submit points</span>
        </div>
      </div>
      <div class="m-4">
        <button
          pButton
          type="button"
          label="View"
          [routerLink]="['/switch-webhook-admin']"
        ></button>
      </div>
    </div>
    <div class="shadow-2 m-2 w-full">
      <div class="flex flex-column p-4">
        <div>
          <h1>Create Custom Webhook</h1>
        </div>
        <div>
          <span
            >Create custom webhooks for users</span
          >
        </div>
      </div>
      <div class="m-4">
        <button
          pButton
          type="button"
          label="View"
          [routerLink]="['/switch-webhook']"
        ></button>
      </div>
    </div>
  </div>
</div>
