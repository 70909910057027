import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { RejectedBulkOrdersDialogComponent } from '../rejected-bulk-orders-dialog/rejected-bulk-orders-dialog.component';

@Component({
  selector: 'app-rejected-bulk-orders',
  templateUrl: './rejected-bulk-orders.component.html',
  styleUrls: ['./rejected-bulk-orders.component.scss']
})
export class RejectedBulkOrdersComponent implements OnInit {
  orders$ = this.bulkOrderFormService.orders$;
  isLoading: boolean = true;
  @ViewChild('dt') dt: Table | undefined;

  columns = [
    { field: 'order_type', header: 'Order Type' },
    { field: 'order_number', header: 'Order Number' },
    { field: 'purchase_order', header: 'Purchase Order' },
  ];

  constructor(
    private bulkOrderFormService: BulkOrderFormService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.bulkOrderFormService.getRejectedBulkOrders().subscribe((orders: any) => {
      this.bulkOrderFormService.updateOrders(orders.body);
      this.isLoading = false;
    });
    
    
  }

  openDialog(order: any) {
    const ref = this.dialogService.open(RejectedBulkOrdersDialogComponent, {
      header: 'Resubmit Order',
      width: '70%',
      data: order,
    });

    ref.onClose.subscribe((result: any) => {
      if (result) {
        this.bulkOrderFormService.getRejectedBulkOrders().subscribe((orders: any) => {
          this.bulkOrderFormService.updateOrders(orders.body);
        });
      }
    });
    
  }

  getNumberofColumns() {
    return this.columns.length + 1;
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

}
