<div class="flex flex-row flex-wrap mb-3 p-4">
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Refering Doctor </label>
    </div>
    <div>
      <input
        type="text"
        pInputText
        [formControl]="$any(inputFormGroup?.controls?.['refering_doctor'])"
      />
    </div>
  </div>
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Mnem </label>
    </div>
    <div>
      <input
        type="text"
        pInputText
        [formControl]="$any(inputFormGroup?.controls?.['mnem'])"
      />
    </div>
  </div>
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Copy Doctor 1 </label>
    </div>
    <div>
      <input
        type="text"
        pInputText
        [formControl]="$any(inputFormGroup?.controls?.['copy_doctor_1'])"
      />
    </div>
  </div>
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Copy Doctor 2 </label>
    </div>
    <div>
      <input
        type="text"
        pInputText
        [formControl]="$any(inputFormGroup?.controls?.['copy_doctor_2'])"
      />
    </div>
  </div>
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Copy Doctor 3 </label>
    </div>
    <div>
      <input
        type="text"
        pInputText
        [formControl]="$any(inputFormGroup?.controls?.['copy_doctor_3'])"
      />
    </div>
  </div>
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Hospital Ward </label>
    </div>
    <div>
      <input
        type="text"
        pInputText
        [formControl]="$any(inputFormGroup?.controls?.['hospital_ward'])"
      />
    </div>
  </div>
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Quantity </label>
    </div>
    <div>
      <p-inputNumber
        [useGrouping]="false"
        (ngModelChange)="onQuantityChange()" 
        [step]="2"
        [formControl]="$any(inputFormGroup?.controls?.['quantity'])"
      ></p-inputNumber>

      <div
        *ngIf="
          submitted &&
          inputFormGroup?.get('quantity')?.invalid &&
          (inputFormGroup?.get('quantity')?.touched ||
            inputFormGroup?.get('quantity')?.pristine)
        "
      >
        <small class="text-red-500">Quantity is required</small>
      </div>

      <div *ngIf="errorUneven">
        <small class="text-red-500">Quantity must be an even number</small>
      </div>
    </div>
  </div>
  <div class="flex-column mr-2">
    <div class="">
      <label for="" class="form-label"> Size </label>
    </div>
    <div>
      <p-dropdown
        [options]="sizes"
        optionLabel="viewValue"
        [disabled]="true"
        optionValue="value"
        [ngModel]="selectedType"
        placeholder="..."
        [formControl]="$any(inputFormGroup?.controls?.['size'])"
      ></p-dropdown>
      <div
        *ngIf="
          submitted &&
          inputFormGroup?.get('size')?.invalid &&
          (inputFormGroup?.get('size')?.touched ||
            inputFormGroup?.get('size')?.pristine)
        "
      >
        <span>
          <small class="text-red-500">Size is required</small>
        </span>
      </div>
    </div>
  </div>
  <div class="flex-column mr-2">
    <div *ngIf="selectedOrderType === 'A5 Biocross'">
      <div>
        <label for="" class="form-label"> Biocross </label>
      </div>
      <p-dropdown
        [options]="biocrossOptions"
        [(ngModel)]="selectedBiocross"
        [formControl]="$any(inputFormGroup?.controls?.['biocross'])"
        placeholder="Select Biocross"
        optionLabel="label"
        optionValue="value"
      >
      </p-dropdown>
      <div *ngIf="submitted && inputFormGroup?.get('biocross')?.invalid">
        <span>
          <small class="text-red-500">Biocross is required</small>
        </span>
      </div>
    </div>

    <div *ngIf="selectedOrderType === 'A4 Cashpath'">
      <div>
        <label for="" class="form-label"> Biocross </label>
      </div>
      <p-dropdown
        [options]="cashpathOptions" 
        [(ngModel)]="selectedCashpath"
        [formControl]="$any(inputFormGroup?.controls?.['biocross'])"
        placeholder="Select Cashpath"
        optionLabel="label"
        optionValue="value"
      >
      </p-dropdown>
    </div>

    <div *ngIf="selectedOrderType === 'A4 (Cyto, Downs, Gynae, Histo)'">
      <div>
        <label for="" class="form-label"> Biocross </label>
      </div>
      <p-dropdown
        [options]="cytoOptions"
        [(ngModel)]="selectedCyto"
        [formControl]="$any(inputFormGroup?.controls?.['biocross'])"
        placeholder="Select Option"
        optionLabel="label"
        optionValue="value"
      >
      </p-dropdown>
    </div>

    <div *ngIf="selectedOrderType === 'A4 Profile Biocross books'">
      <div>
        <label for="" class="form-label"> Biocross </label>
      </div>
      <p-dropdown
        [options]="profileBiocrossBooks"
        [(ngModel)]="selectedProfileBiocross"
        [formControl]="$any(inputFormGroup?.controls?.['biocross'])"
        placeholder="Select Option"
        optionLabel="label"
        optionValue="value"
      >
      </p-dropdown>
    </div>

    <!-- If no dropdown is needed, nothing will show -->
    <div *ngIf="!hasDropdown(selectedOrderType)">
      <!-- No Dropdown needed -->
    </div>
  </div>
</div>
