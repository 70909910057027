import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';
import { BulkOrderDialogComponent } from './bulk-orders-dialog/bulk-order-dialog/bulk-order-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-bulk-orders',
  templateUrl: './bulk-orders.component.html',
  styleUrls: ['./bulk-orders.component.scss'],
})
export class BulkOrdersComponent implements OnInit {
  submitted = false;
  selectedOrderType: any;
  maxQty = 160; // Maximum total quantity allowed
  totalQty = 0; // Track total quantity across all forms
  selectedType: string = '';

  form1 = new FormGroup({
    order_type: new FormControl(''),
    order_number: new FormControl('', [Validators.required]),
    purchase_order: new FormControl(''),
    total_quantity: new FormControl(0),
    orders: new FormArray([]),
  });

  order_types = [
    { value: 'A5 Gewoon', viewValue: 'A5 Gewoon', type: 'A5' },
    { value: 'A5 Biocross', viewValue: 'A5 Biocross', type: 'A5' },
    { value: 'A4 Gewoon', viewValue: 'A4 Gewoon', type: 'A4' },
    { value: 'A4 Profile Books', viewValue: 'A4 Profile Books', type: 'A4' },
    {
      value: 'A4 Profile Biocross books',
      viewValue: 'A4 Profile Biocross books',
      type: 'A4',
    },
    { value: 'A4 Cashpath', viewValue: 'A4 Cashpath', type: 'A4' },
    {
      value: 'A4 (Cyto, Downs, Gynae, Histo)',
      viewValue: 'A4 (Cyto, Downs, Gynae, Histo)',
      type: 'A4',
    },
    { value: 'A4 Allergy', viewValue: 'A4 Allergy', type: 'A4' },
  ];

  constructor(
    private fb: FormBuilder,
    private bulkOrderFormService: BulkOrderFormService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.form1 = this.fb.group({
      order_type: new FormControl('', [Validators.required]),
      order_number: new FormControl('', [Validators.required]),
      purchase_order: new FormControl('', [Validators.required]),
      total_quantity: new FormControl(),
      orders: new FormArray([]),
    });

    this.orderArray.push(this.bulkOrderFormService.getBulkOrderForm());
    this.bulkOrderFormService.setBiocrossValidation(this.form1);
  }

  onOrderTypeChange() {
    if (this.selectedOrderType) {
      this.selectedType = this.selectedOrderType.type;
    }
  }

  openBulkOrderDialog() {
    this.submitted = true;
    if (this.form1.invalid) {
      return;
    }

    this.form1.patchValue({
      total_quantity: this.totalQty,
    });

    const ref = this.dialogService.open(BulkOrderDialogComponent, {
      header: 'Bulk Order',
      width: '80%',
      data: this.form1.value,
    });

    ref.onClose.subscribe((response: any) => {
      if (!response) {
        return;
      }
      this.form1.reset();
      this.submitted = false;
      // Clear the form array
      const formArray = this.form1.get('orders') as FormArray;
      while (formArray.length) {
        formArray.removeAt(0);
      }
      // Add a new form group
      this.orderArray.push(this.bulkOrderFormService.getBulkOrderForm());
      this.selectedType = '';

      console.log(response);
    });
  }

  isFieldInvalid(index: number, fieldName: string): boolean {
    const control = (this.form1.get('orders') as FormArray)
      .at(index)
      .get(fieldName);
    console.log(control);

    return control?.invalid || false;
  }

  get orderArray() {
    return this.form1.get('orders') as FormArray;
  }

  addOrder() {
    if (this.totalQty < this.maxQty) {
      const order = this.bulkOrderFormService.getBulkOrderForm();
      this.orderArray.push(order);
      //add valitators to the biocross field
      if (
        this.selectedOrderType.value === 'A5 Biocross' ||
        this.selectedOrderType.value === 'A4 Cashpath' ||
        this.selectedOrderType.value === 'A4 (Cyto, Downs, Gynae, Histo)'
      ) {
        order.get('biocross')?.setValidators(Validators.required);
      } else {
        order.get('biocross')?.clearValidators();
      }
      order.get('biocross')?.updateValueAndValidity();
    }
  }

  deleteOrder(index: number) {
    this.orderArray.removeAt(index);
  }

  calculateTotalQty() {
    this.totalQty = this.orderArray.controls.reduce((acc, formGroup) => {
      return acc + formGroup.get('quantity')?.value || 0;
    }, 0);
  }

  onSubmit() {
    this.calculateTotalQty();
    console.log(this.form1.value);
  }
}
