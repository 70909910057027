import { Component, OnInit, ViewChild } from '@angular/core';
import { QuotesService } from 'src/app/_services/quotes.service';
import { DialogService } from 'primeng/dynamicdialog';
import { QuoteDetailComponent } from 'src/app/main-application/quote-detail/quote-detail.component';
import { UserService } from 'src/app/_services';
import { Table } from 'primeng/table';


@Component({
  selector: 'app-quotes-table',
  templateUrl: './quotes-table.component.html',
  styleUrls: ['./quotes-table.component.scss'],
})
export class QuotesTableComponent implements OnInit {
  @ViewChild('dt') dt: Table | undefined;
  isLoading: boolean = true;
  myQuotes: any[] = [];
  
  constructor(
    public quotesService: QuotesService,
    private dialogService: DialogService,
    private userService: UserService
  ) { }

  columns = [
    { field: 'quote_number', header: 'Quote Reference' },
    { field: 'quote_date', header: 'Quote Date' },
    { field: 'quote_status', header: 'Quote Status' },
    { field: 'quote_total', header: 'Total' },
    { field: 'user_id', header: 'User ID' },
  ];

  openDialog(rowData: any) {
    const ref = this.dialogService.open(QuoteDetailComponent, {
      data: {
        rowData: rowData
      },
      header: 'Quote Details',
      width: '70%',
    });
  }

  ngOnInit(): void {
    this.userService.getMe().subscribe((user) => {
      this.quotesService.getQuoteById(user.id).subscribe((response: any) => {
        this.myQuotes = response.body;
      })
        ;
      this.isLoading = true;
    }, (error: any) => {
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getSeverityFromBool(configured: string) {
		if (configured == 'ACCEPTED') {
			return "success";
		} else {
			return "danger";
		}
	}

  getNumberofColumns() {
    return this.columns.length;
  }
}
