import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  loading: boolean = false;
  btnLoad: boolean = false;
  submitted: boolean = false;
  error: string = '';
  newPasswordForm: any;

  resetObject = {
    email: '',
    password: '',
    token: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.newPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    this.route.queryParams.subscribe((params) => {
      if (params['token'] && params['token'].length > 0 && params['email']) {
        this.resetObject.token = params['token'];
        this.resetObject.email = params['email'];
      }
    });
  }

  get fn() {
    return this.newPasswordForm.controls;
  }

  onResetSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.newPasswordForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.fn.password.value !== this.fn.confirmPassword.value) {      
      this.error = 'Passwords do not match';
      this.loading = false;
      return;
    }

    this.resetObject.password = this.fn.password.value;

    this.userService
      .resetPassword(
        this.resetObject.email,
        this.resetObject.token,
        this.resetObject.password
      )
      .subscribe((res: any) => {
        // get return url from query parameters or default to home page
        this.router.navigate(['/login']);
        this.loading = false;
      },
      (error) => {
        console.error('Error:', error);
        this.loading = false; // Set loading to false in the error callback as well
        this.error = 'Token expired';
      });
  }

  clearError() {
    this.error = ''; // Clear the error message
  }

}
