<p-progressSpinner *ngIf="loadingData" styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<div *ngIf="errorLoadingData"></div>
<div class="surface-section px-4 py-4 md:px-1 lg:px-2" *ngIf="!loadingData && !errorLoadingData">
    <div class="grid">
      <form
        [formGroup]="this.roleService.roleForm"
        (ngSubmit)="updateRole(roleData.id)"
      >
        <div class="col-12 lg:col-12">
          <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12 md:col-6">
              <label for="first_name" class="font-medium text-900"
                >Role Name</label
              >
              <input
                id="name"
                type="text"
                pInputText
                formControlName="name"
                [(ngModel)]="roleData.name"
              />
              <div
              *ngIf="
                submitted && this.roleService.roleForm.get('name')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['name'] &&
                  f['name'].errors &&
                  f['name'].errors['required']
                "
                severity="error"
                text="* Please enter a role name"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="description" class="font-medium text-900"
                >Descirption</label
              >
              <input
                id="description"
                type="text"
                pInputText
                formControlName="description"
                [(ngModel)]="roleData.description"
              />
              <div
              *ngIf="
                submitted && this.roleService.roleForm.get('description')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['description'] &&
                  f['description'].errors &&
                  f['description'].errors['required']
                "
                severity="error"
                text="* Please enter a role description"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
            </div>
            <div class="col-12">
              <button
              [loading]="loading"
                pButton
                pRipple
                type="submit"
                label="Save Changes"
                class="w-auto mt-3"
              ></button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  