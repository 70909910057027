<p-toast key="bulk-order-dialog"></p-toast>
<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Bulk Orders</h2>
  </div>
</div>
<div class="max-w-screen">
  <form action="" [formGroup]="form1">
    <div class="">
      <div class="max-w-full">
        <div class="flex flex-column m-6 md:col-6">
          <div class="pb-4">
            <div>
              <label for="" class="form-label">Order Type</label>
            </div>
            <div>
              <p-dropdown
                formControlName="order_type"
                [options]="order_types"
                optionLabel="viewValue"
                styleClass="w-26rem"
                placeholder="selected intent"
                [(ngModel)]="selectedOrderType"
                (onChange)="onOrderTypeChange()"
              >
              </p-dropdown>
              <div
                *ngIf="
                  submitted &&
                  form1.get('order_type')?.invalid &&
                  (form1.get('order_type')?.touched ||
                    form1.get('order_type')?.pristine)
                "
              >
                <small class="text-red-500">Order Type is required</small>
              </div>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-2">
              <div>
                <label for="" class="form-label">Order Number</label>
              </div>
              <div>
                <input formControlName="order_number" pInputText type="text" />
                <div
                  *ngIf="
                    submitted &&
                    form1.get('order_number')?.invalid &&
                    (form1.get('order_number')?.touched ||
                      form1.get('order_number')?.pristine)
                  "
                >
                  <small class="text-red-500">Order Number is required</small>
                </div>
              </div>
            </div>
            <div class="ml-2">
              <div>
                <label for="" class="form-label">Purchase Order</label>
              </div>
              <div>
                <input
                  formControlName="purchase_order"
                  pInputText
                  type="text"
                />
                <div
                *ngIf="
                  submitted &&
                  form1.get('purchase_order')?.invalid &&
                  (form1.get('purchase_order')?.touched ||
                    form1.get('purchase_order')?.pristine)
                "
              >
                <small class="text-red-500">Purchase Order is required</small>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-row surface-card shadow-2 max-w-full p-2 align-items-center mr-6 ml-6 mt-4"
          *ngFor="
            let o of orderArray.controls;
            index as i;
            last as isLast;
            first as isFirst
          "
        >
          <div class="">
            <span>{{ i + 1 }}.</span>
            <app-bulk-orders-form
              [inputFormGroup]="$any(o)"
              [submitted]="submitted"
              [selectedOrderType]="selectedOrderType?.value"
              (quantityChange)="calculateTotalQty()"
              [totalQty]="totalQty"
              [selectedType]="selectedType"
            ></app-bulk-orders-form>
            &nbsp; &nbsp;
          </div>

          <div>
            <button
              *ngIf="!isFirst"
              pButton
              (click)="deleteOrder(i)"
              type="button"
              icon="pi pi-times"
              class="p-button-danger mr-4"
            ></button>
          </div>

          <!-- Display the button only on the last form in the array -->
          <div>
            <button
              *ngIf="isLast"
              pButton
              type="button"
              icon="pi pi-plus"
              class="p-button-success"
              (click)="addOrder()"
              [disabled]="orderArray.length >= 13"
            ></button>
          </div>
        </div>
        <!-- Display total quantity
      <div class="m-6">
        <p>Total Quantity: {{ totalQty }}</p>
        <div *ngIf="totalQty > maxQty">
          <small class="text-red-500">Total quantity exceeds the maximum allowed (160)</small>
        </div>
      </div> -->
      </div>
      <div class="ml-6 mt-2" *ngIf="totalQty > maxQty">
        <small class="text-red-500"
          >Total quantity exceeded. Maximum allowed (160)</small
        >
      </div>
      <div class="flex flex-row m-6">
        <button
          pButton
          label="Review"
          icon="pi pi-upload"
          class="w-20rem"
          (click)="openBulkOrderDialog()"
        ></button>
      </div>
    </div>
  </form>
</div>
