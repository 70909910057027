import { Component, OnInit } from '@angular/core';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';

@Component({
  selector: 'app-home-bulkorder-reviewer',
  templateUrl: './home-bulkorder-reviewer.component.html',
  styleUrls: ['./home-bulkorder-reviewer.component.scss']
})
export class HomeBulkorderReviewerComponent implements OnInit {
  bulkOrders$ = this.bulkOrderFormService.reviewCount$;
  bulkOrderCount: number = 0;

  rejectedCount$ = this.bulkOrderFormService.rejectedBulkOrderCount$;
  rejectedCount: number = 0;

  constructor(
    private bulkOrderFormService: BulkOrderFormService,
  ) { }

  ngOnInit(): void {
    this.bulkOrderFormService.getBulkOrderActionCount().subscribe((data) => {
      this.bulkOrderFormService.updateReviewCount(data.body);
      this.bulkOrders$.subscribe((count) => {
        this.bulkOrderCount = count;
      });
    });

    this.bulkOrderFormService.getRejectedBulkOrderCount().subscribe((data) => {
      this.bulkOrderFormService.updateRejectedBulkOrderCount(data.body);
      this.rejectedCount$.subscribe((count) => {
        this.rejectedCount = count;
      });
    });
  }

}
