import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormBuilder, Form } from '@angular/forms';
import { CustomerService } from 'src/app/_services/customer.service';


@Component({
  selector: 'app-update-customer-dialog',
  templateUrl: './update-customer-dialog.component.html',
  styleUrls: ['./update-customer-dialog.component.scss']
})
export class UpdateCustomerDialogComponent implements OnInit {
  customerData: any;
  loading: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.customerData = this.config.data.customer;
  }

  customerForm = this.fb.group({
    company_name: [''],
    code: [''],
  });

  onSubmit(id: number) {
    this.loading = true;
    this.customerService.updateCustomer(this.customerForm.value, id)
    this.ref.close();
    this.loading = false;
  }

}
