<div class="px-3 py-3 md:px-6 lg:px-8">
  <div class="grid">
    <div class="col-12 lg:col-12">
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="flex justify-content-between">
          <span class="font-bold text-900">Status:</span>
          <div class="card">
            <p-badge
              [value]="status.artwork_status"
              severity="info"
              class="w-24"
            ></p-badge>
          </div>
          <span class="font-bold text-900">Artwork Reference:</span>
          <div class="card">
            <p-badge
              [value]="status.artwork_reference"
              severity="info"
              class="w-24"
            ></p-badge>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row col-12 p-0">
      <div class="col-12 lg:col-6">
        <div class="surface-card min-h-full p-4 shadow-2 border-round">
          <div class="flex justify-content-between">
            <div class="flex align">
              <div class="mr-3">
                <span icon="pi pi-image" class="w-12 h-12 border-round"></span>
              </div>
              <div>
                <div class="font-bold text-900">Artwork Timeline</div>
                
                <div class="card" *ngFor="let action of actions">
                  <i class="pi pi-circle-fill mt-3"></i>
                  <span class="ml-3"
                    >{{ action.action_type }} -
                    {{ action.date_created | date }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 lg:col-6"
        *ngIf="status.artwork_status == 'SUBMITTED FOR IMPOSITION' || status.artwork_status == 'IMPOSITION COMPLETED'"
      >
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="flex justify-content-between">
            <div class="flex align">
              <div class="mr-3">
                <span icon="pi pi-image" class="w-12 h-12 border-round"></span>
              </div>
              <div>
                <div class="font-bold text-900 mb-2">Imposition Details</div>

                <div class="card">
                  <div>
                    <span class="">
                      <span class="font-bold pr-2"> Digital Press Sheet: </span>
                      {{ status.impo_digital_press_sheet }}</span
                    >
                  </div>
                  <div>
                    <span class="">
                      <span class="font-bold pr-2"> Double Cut: </span>
                      {{ status.impo_double_cut }}</span
                    >
                  </div>
                  <div>
                    <span class="">
                      <span class="font-bold pr-2"> Sides: </span>
                      {{ status.impo_sides }}</span
                    >
                  </div>
                  <div>
                    <span class="">
                      <span class="font-bold pr-2"> Type: </span>
                      {{ status.impo_type }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 lg:col-6"
        *ngIf="status.artwork_status == 'SUBMITTED WITH QUOTE'"
      >
        <div class="surface-card p-4 shadow-2 border-round">
          <div class="flex flex-column">
            <div class="font-bold text-900 mb-2">Quote Details:</div>
  
            <div class="card">
              <div>
                <span class="">
                  <span class="font-bold pr-2"> Quote Number: </span>
                  {{ quote_data.quote_number }}</span
                >
              </div>
              <div>
                <span class="">
                  <span class="font-bold pr-2"> Quote Status: </span>
                  {{ quote_data.quote_status }}</span
                >
              </div>
              <div>
                <span class="">
                  <span class="font-bold pr-2"> External Est ID: </span>
                  {{ quote_data.external_estimate_id }}</span
                >
              </div>
              <div>
                <span class="">
                  <span class="font-bold pr-2"> Quote Total: </span>
                  {{ quote_data.quote_total }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12"
      *ngIf="status.artwork_status == 'PREFLIGHT REPORT COMPLETED'"
    >
      <div class="surface-card p-4 shadow-2 border-round overflow-y-hidden">
        <div class="flex flex-column">
          <div
            class="flex justify-content-center align-content-center align-items-center"
          >
            <h2>PDF Info:</h2>
          </div>
          <div *ngIf="report_data">
            <div>
              <h3>Name: {{ report_data.documentName }}</h3>
            </div>
            <p>Total Pages: {{ report_data.generalDocInfo.documentProperties.numPages}}</p>

            <h3>Pdf Info:</h3>
              <p>
                Media Box: {{ report_data.pageBoxInfo.page[0].mediaBox.width }} x
                {{ report_data.pageBoxInfo.page[0].mediaBox.height }}
              </p>
              <p>
                Trim Box: {{ report_data.pageBoxInfo.page[0].trimBox.width}} x {{ report_data.pageBoxInfo.page[0].trimBox.height }}
              </p>
           

            <h3>Page Color Type Info:</h3>
            <p>Color Pages: {{ report_data.pageColorTypeInfo.colorPages }}</p>
            <p>Blank Pages: {{ report_data.pageColorTypeInfo.emptyPages }}</p>
            <p>
              Black & White Pages:
              {{ report_data.pageColorTypeInfo.blackAndWhitePages }}
            </p>

            <hr />
            <div
              class="flex justify-content-center align-content-center align-items-center"
            >
              <h2>Errors:</h2>
            </div>
            <div
              *ngIf="report_data.preflightReport.errorsNumber > 0; else noErrors"
            >
              
              
              <div >
                <ul *ngFor="let item of report_data.preflightReport.errors.preflightReportItem">
                  <p-messages severity="error">
                    <ng-template pTemplate>
                      <i class="pi pi-times-circle"></i>
                      <div class="ml-2">
                        {{ item.message }}
                      </div>
                    </ng-template>
                  </p-messages>
                  
                  
                </ul>
              </div>      
            </div>
            <ng-template #noErrors>
              <p-messages severity="success">
                <ng-template pTemplate>
                  <div class="ml-2">
                    <i class="pi pi-check mr-2"></i>No Errors Found
                  </div>
                </ng-template>
              </p-messages>
            </ng-template>
            <hr />
            <div
              class="flex justify-content-center align-content-center align-items-center"
            >
              <h2>Warnings:</h2>
            </div>
            <div
              *ngIf="
                report_data.preflightReport.warningsNumber > 0;
                else noWarnings
              "
            >
              <div >
                <ul *ngFor="let item of report_data.preflightReport.warnings.preflightReportItem">
                  <p-messages severity="warn">
                    <ng-template pTemplate>
                      <i class="pi pi-exclamation-triangle"></i>
                      <div class="ml-2">
                        {{ item.message }}
                      </div>
                    </ng-template>
                  </p-messages>
                  
                </ul>
              </div>
            </div>
            <ng-template #noWarnings>
              <p-messages severity="success">
                <ng-template pTemplate>
                  <div class="ml-2">
                    <i class="pi pi-check mr-2"></i>No Warnings Found
                  </div>
                </ng-template>
              </p-messages>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-column col-6"
      *ngIf="status.artwork_status == 'IMPOSITION COMPLETED'"
    >
      <span class="font-bold mb-2 mt-2">
        File: {{ original_filename }} is ready for download!
      </span>
      <button
        (click)="downloadFile(actions[0].artwork_id)"
        pButton
        icon="pi pi-download"
        class=""
        [label]="status.file_submission_name"
      ></button>
    </div>
  </div>
</div>
