<p-toast key="rejected-bulk-orders-dialog"></p-toast>
<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">
      Rejected Bulk Orders
    </h2>
  </div>
</div>
<div class="overflow-x-scroll px-4 py-2 md:px-6 lg:px-8" style="margin-top: 50px">
  <p-table
    #dt
    [value]="(orders$ | async) || []"
    [tableStyle]="{ 'min-width': '100vw' }"
    [rowHover]="true"
    [scrollable]="true"
    scrollHeight="60vh"
    styleClass="z-0"
    [paginator]="true"
    [rows]="50"
    [rowsPerPageOptions]="[50, 100, 150]"
    [globalFilterFields]="[
      'order_type',
      'order_number',
      'purchase_order',
      'refering_doctor',
      'mnem',
      'copy_doctor_1',
      'copy_doctor_2',
      'copy_doctor_3',
      'quantity',
      'size',
      'biocross'
    ]"
    
  >
  <ng-template pTemplate="caption">
    <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="filterGlobal($event)"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order>
      <tr style="cursor: pointer;" (click)="openDialog(order)">
        <td *ngFor="let col of columns">
          <p class="mb-2 font-medium text-md text-color-primary">
            {{ order.bulk_orders[col.field] }}
          </p>
        </td>
      </tr> 
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center"
        >
          <p-progressBar
            styleClass="align-self-center"
            *ngIf="isLoading"
            mode="indeterminate"
          >
          </p-progressBar>
          <div *ngIf="!isLoading">
            <p>
              There are no records to show. You might be disconnected or offline.
            </p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
