<form [formGroup]="form" (ngSubmit)="submitForm()">
  <ng-container formArrayName="fields">
    <div *ngFor="let field of allFields; index as i" class="field-container">
      <div [formGroupName]="i">
        <ng-container [ngSwitch]="field.field_name">
          <!-- Dropdown Fields -->
          <div class="mt-2" *ngSwitchCase="'dropdown'">
            <label>{{ field.field_title }}</label>
            <p-dropdown
              [options]="field.field_details"
              placeholder="Select a Field"
              styleClass="w-full"
              formControlName="dropdown"
              appendTo="body"
            ></p-dropdown>
          </div>

          <!-- Text Fields -->
          <div class="mt-2" *ngSwitchCase="'text'">
            <label>{{ field.field_title }}</label>
            <input
              pInputText
              type="text"
              class="w-full"
              formControlName="text"
            />
          </div>

          <!-- Number Fields -->
          <div class="mt-2" *ngSwitchCase="'number'">
            <label>{{ field.field_title }}</label>
            <input
              pInputText
              type="number"
              class="w-full"
              formControlName="number"
            />
          </div>

          <!-- Date Fields -->
          <div class="mt-2" *ngSwitchCase="'date'">
            <label>{{ field.field_title }}</label>
            <p-calendar
              styleClass="w-full"
              dateFormat="yy-mm-dd"
              formControlName="date"
              appendTo="body"
            ></p-calendar>
          </div>

          <!-- File Fields -->
          <div class="mt-2" *ngSwitchCase="'file'">
            <label>{{ field.field_title }}</label>
            <p-fileUpload
              mode="advanced"
              chooseLabel="Choose"
              chooseIcon="pi pi-upload"
              [showUploadButton]="false"
              accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              styleClass="w-full"
              (onSelect)="onFileSelect($event, i)"
            ></p-fileUpload>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!-- Submit Button -->
  <div class="mt-4">
    <button
      pButton
      type="submit"
      label="Submit"
      icon="pi pi-upload"
      class="primary"
      [loading]="loading"
    ></button>
  </div>
</form>
