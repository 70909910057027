<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Generate Quote</h2>
  </div>
</div>

<div
  class="flex flex-column align-items-center justify-content-center"
  *ngIf="productList.length === 0; else showProducts"
>
  <div class="mt-8">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="text-xl font-bold">
    <span>Loading...</span>
  </div>
</div>

<ng-template #showProducts>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <p-toast></p-toast>
    <div class="grid mb-7">
      <div class="col-12 lg:col-6">
        <div class="flex align-items-center text-xl font-medium text-900 mb-4">
          <label for="name">Select a Product</label>
        </div>

        <div class="font-bold text-900 mb-3">Product</div>
        <div class="mb-5">
          <!-- TODO show the first size of the selected products -->
          <p-dropdown
            [options]="productList"
            [(ngModel)]="selectedProduct"
            (onChange)="onSizeChange($event)"
            optionLabel="product_name"
            styleClass="w-full"
          ></p-dropdown>
        </div>

        <div class="font-bold text-900 mb-3">Sizes</div>
        <div class="mb-5">
          <!-- TODO show the first size of the selected products -->
          <div>
            <p-dropdown
              [options]="selectedProduct && selectedProduct.product_sizes"
              [(ngModel)]="selectedPageSize"
              (onChange)="onSizeChange($event)"
              optionLabel="size_name"
              styleClass="w-full"
            ></p-dropdown>
          </div>
        </div>

        <div class="font-bold text-900 mb-3">Paper</div>
        <div class="mb-5">
          <!-- TODO show the first size of the selected products -->
          <div *ngIf="selectedProduct.product_name === 'Posters'">
            <p-dropdown
              [options]="productParts"
              [(ngModel)]="selectedPaperCode"
              optionLabel="description"
              styleClass="w-full"
            ></p-dropdown>
          </div>
          <div
            *ngIf="
              selectedProduct.product_name === 'Business Cards' ||
              selectedProduct.product_name === 'Flyers'
            "
          >
            <p-dropdown
              [options]="selectedProduct && selectedProduct.product_parts"
              [(ngModel)]="selectedPaperCode"
              optionLabel="description"
              styleClass="w-full"
            ></p-dropdown>
          </div>
        </div>

        <div
          *ngIf="
            selectedProduct.product_name === 'Business Cards' ||
            selectedProduct.product_name === 'Flyers'
          "
        >
          <div class="font-bold text-900 mb-3">Color</div>
          <div class="mb-5">
            <!-- TODO show the first size of the selected products -->
            <p-dropdown
              [(ngModel)]="selectedColor"
              [options]="productColors"
              optionLabel="name"
              styleClass="w-full"
            ></p-dropdown>
          </div>
        </div>

        <div class="font-bold text-900 mb-3">Quantity</div>
        <div class="mb-5">
          <div>
            <input type="text" pInputText [(ngModel)]="value" class="w-full" />
            <p-slider [(ngModel)]="value" class="w-full"></p-slider>
          </div>
        </div>

        <div class="flex align-items-center flex-1 mt-3 sm:mt-0 ml-0">
          <button
            pButton
            pRipple
            [disabled]="loading"
            label="Generate Estimate"
            class="flex-1"
            [loading]="loading"
            (click)="onSubmitQuoteRequest()"
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm"
            ></span>
          </button>
        </div>

        <p-divider></p-divider>

        <div
          *ngIf="price"
          class="flex align-items-center justify-content-between mt-5"
        >
          <div>
            <span class="text-900 font-medium text-xl block">Est. Price:</span>
            <span class="text-900 font-medium text-xl block">VAT:</span>
            <span class="text-900 font-medium text-xl block mt-2">Total:</span>
          </div>

          <div>
            <span class="text-900 font-medium text-xl block">{{
              price | currency : "R"
            }}</span>
            <span class="text-900 font-medium text-xl block">{{
              vat | currency : "R"
            }}</span>
            <hr />
            <span class="text-900 font-medium text-xl block">{{
              totalPrice | currency : "R"
            }}</span>
          </div>
        </div>
        <div *ngIf="price && acceptedQuote" class="relative mb-7">
          <button
            pButton
            pRipple
            [disabled]="loading"
            icon="pi pi-shopping-cart"
            label="Add to Cart"
            class="absolute right-0 flex-1 mt-5"
            [loading]="loading"
            (click)="onSubmitQuoteSave()"
          ></button>
        </div>
        <div *ngIf="price && choice" class="flex flex-row mt-2 pt-2">
          <div class="col-6">
            <button
              pButton
              pRipple
              icon="pi pi-check"
              [disabled]="loading"
              label="Accept Quote"
              class="w-full bg-white-alpha-10 hover:bg-green-400 hover:border-green-400 font-semibold"
              [loading]="loading"
              (click)="onQuoteAccept()"
              style="border-color: #10da10"
            >
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm"
              ></span>
            </button>
          </div>

          <div class="col-6">
            <button
              pButton
              pRipple
              [disabled]="loading"
              icon="pi pi-times"
              label="Reject Quote"
              class="w-full bg-white-alpha-10 hover:bg-red-600 hover:border-red-600 font-semibold"
              [loading]="loading"
              (click)="onQuoteDecline()"
              style="border-color: #ff0000"
            >
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm"
              ></span>
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedProduct.product_name == 'Flyers'"
        class="col-12 lg:col-6 py-3 lg:pl-6"
      >
        <div class="flex">
          <div class="pl-3 w-full">
            <img
              attr.src="{{ flyersImages[selectedImageIndex2] }}"
              class="w-full border-round"
            />
          </div>
        </div>
        <div class="flex pl-3 w-2 justify-content-between">
          <img
            *ngFor="let image of flyersImages; let i = index"
            src="{{ image }}"
            class="w-full cursor-pointer border-2 border-round border-transparent transition-colors transition-duration-150"
            [ngClass]="{ 'border-primary': selectedImageIndex2 === i }"
            (click)="selectedImageIndex2 = i"
          />
        </div>
      </div>
      <div
        *ngIf="selectedProduct.product_name == 'Business Cards'"
        class="col-12 lg:col-6 py-3 lg:pl-6"
      >
        <div class="flex">
          <div class="pl-3 w-full">
            <img
              attr.src="{{ businessCardImages[selectedImageIndex2] }}"
              class="w-full border-round"
            />
          </div>
        </div>
        <div class="flex pl-3 w-2 justify-content-between">
          <img
            *ngFor="let image of businessCardImages; let i = index"
            src="{{ image }}"
            class="w-full cursor-pointer border-2 border-round border-transparent transition-colors transition-duration-150"
            [ngClass]="{ 'border-primary': selectedImageIndex2 === i }"
            (click)="selectedImageIndex2 = i"
          />
        </div>
      </div>
      <div
        *ngIf="selectedProduct.product_name == 'Posters'"
        class="col-12 lg:col-6 py-3 lg:pl-6"
      >
        <div class="flex">
          <div class="pl-3 w-full">
            <img
              attr.src="{{ postersImages[selectedImageIndex2] }}"
              class="w-full border-round"
            />
          </div>
        </div>
        <div class="flex pl-3 w-2 justify-content-between">
          <img
            *ngFor="let image of postersImages; let i = index"
            src="{{ image }}"
            class="w-full cursor-pointer border-2 border-round border-transparent transition-colors transition-duration-150"
            [ngClass]="{ 'border-primary': selectedImageIndex2 === i }"
            (click)="selectedImageIndex2 = i"
          />
        </div>
      </div>
      <div class="col-12" *ngIf="price && acceptedQuote">
        <div
          *hasRoles="rolesForArtworkUpload.rolesWithAccess; for: currentUser"
        >
          <app-add-artwork
            [quote_id]="saleQuoteId"
            [disabled]="disabled"
            [external_estimate_id]="
              quoteObject.external_quote.external_estimate_id
            "
          ></app-add-artwork>
        </div>
      </div>
    </div>
    <p-tabView>
      <p-tabPanel header="Details">
        <div
          *ngIf="selectedProduct.product_name == 'Posters'"
          class="col-12 lg:col-6 py-3 lg:pl-6"
        >
          <div class="text-900 font-medium text-3xl mb-4 mt-2">
            Product Details
          </div>
          <p class="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
            Poster digitally printed to the highest printing quality standards with vibrant colours
          </p>

          <div class="grid">
            <div class="col-12 lg:col-4">
              <span class="text-900 block font-medium mb-3">Highlights</span>
              <ul class="py-0 pl-3 m-0 text-700 mb-3">
                <li class="mb-2">Digitally printed.</li>
                <li class="mb-2">Smaller print quantity.</li>
                <li class="mb-2">Fast turnaround time.</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedProduct.product_name == 'Flyers'"
          class="col-12 lg:col-6 py-3 lg:pl-6"
        >
          <div class="text-900 font-medium text-3xl mb-4 mt-2">
            Product Details
          </div>
          <p class="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
            Flyers digitally printed to the highest printing quality standards with vibrant colours
          </p>

          <div class="grid">
            <div class="col-12 lg:col-4">
              <span class="text-900 block font-medium mb-3">Highlights</span>
              <ul class="py-0 pl-3 m-0 text-700 mb-3">
                <li class="mb-2">Digitally printed.</li>
                <li class="mb-2">Smaller print quantity from 1-500.</li>
                <li class="mb-2">Fast turnaround time.</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedProduct.product_name == 'Business Cards'"
          class="col-12 lg:col-6 py-3 lg:pl-6"
        >
          <div class="text-900 font-medium text-3xl mb-4 mt-2">
            Product Details
          </div>
          <p class="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
            Business cards printed digitally to the highest printing quality standards with vibrant colours
          </p>

          <div class="grid">
            <div class="col-12 lg:col-4">
              <span class="text-900 block font-medium mb-3">Highlights</span>
              <ul class="py-0 pl-3 m-0 text-700 mb-3">
                <li class="mb-2">Digitally printed.</li>
                <li class="mb-2">Smaller print quantity from 1-1000.</li>
                <li class="mb-2">Fast turnaround time.</li>
              </ul>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <!-- 
    <p-tabPanel header="Shipping">
      <div class="text-900 font-medium text-3xl mb-4 mt-2">
        Shipping Placeholder
      </div>
      <p class="line-height-3 text-700 p-0 mx-0 mt-0 mb-4">
        Mattis aliquam faucibus purus in massa tempor nec feugiat nisl. Justo
        donec enim diam vulputate ut pharetra. Tempus egestas sed sed risus.
        Feugiat sed lectus vestibulum mattis. Tristique nulla aliquet enim
        tortor at auctor urna nunc. Habitant morbi tristique senectus et.
        Facilisi nullam vehicula ipsum.
      </p>

      <div class="grid">
        <div class="col-12 md:col-6">
          <span class="text-900 block font-medium mb-3">Shipping Costs</span>
          <ul class="py-0 pl-3 m-0 text-700 mb-3">
            <li class="mb-2">Japan - JPY 2,500.</li>
            <li class="mb-2">Europe – EUR 10</li>
            <li class="mb-2">Switzerland – CHF 10</li>
            <li class="mb-2">Canada – CAD 25</li>
            <li class="mb-2">USA – USD 20</li>
            <li class="mb-2">Australia – AUD 30</li>
            <li class="mb-2">United Kingdom – GBP 10</li>
          </ul>
        </div>
        <div class="col-12 md:col-6">
          <span class="text-900 block font-medium mb-3">Return Policy</span>
          <p class="line-height-3 text-700 p-0 m-0">
            Pharetra et ultrices neque ornare aenean euismod elementum nisi.
            Diam phasellus vestibulum lorem sed. Mattis molestie a iaculis at.
          </p>
        </div>
      </div>
    </p-tabPanel> -->
    </p-tabView>
  </div>
</ng-template>
