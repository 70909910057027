<form [formGroup]="this.productService.productForm" (ngSubmit)="onSubmit()">
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="surface-card p-4 border-round p-fluid">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 md:col-6">
          <label for="product_name" class="font-medium text-900"
            >Product Name</label
          >
          <input
            id="productName"
            type="text"
            pInputText
            formControlName="product_name"
          />
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="description" class="font-medium text-900"
            >Product Description</label
          >
          <input
            id="description"
            type="text"
            pInputText
            formControlName="description"
          />
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="external_product_id" class="font-medium text-900"
            >External Product ID</label
          >
          <input
            id="externalProductId"
            type="text"
            pInputText
            formControlName="external_product_id"
          />
        </div>

        <div class="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
      </div>
      <button
        pButton
        pRipple
        label="Add Product"
        icon="pi pi-plus"
        class="w-auto"
      ></button>
    </div>
  </div>
</form>
