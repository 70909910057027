import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { UserService } from 'src/app/_services';
import { DialogService } from 'primeng/dynamicdialog';
import { SwitchWebhookDialogComponent } from '../switch-webhook-dialog/switch-webhook-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-switch-webhook-admin',
  templateUrl: './switch-webhook-admin.component.html',
  styleUrls: ['./switch-webhook-admin.component.scss'],
})
export class SwitchWebhookAdminComponent implements OnInit {
  webhooks: any[] = [];
  user: any;
  loading: boolean = false;
  userWebhooks: any[] = [];

  constructor(
    private fb: FormBuilder,
    private switchWebhookService: SwitchWebhookService,
    private userService: UserService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.currentUser$.subscribe((user: any) => {
      this.user = user;
    });
    let filtered_webhooks;
    setTimeout(() => {
      this.switchWebhookService.getUserWebhooks().subscribe((users: any) => {
        this.switchWebhookService.getWebhookByAdminId(this.user?.id).subscribe(
          (data: any) => {
            this.userWebhooks = users.body.map((user: any) => {
              return {
                ...user,
                webhooks: data.body.filter(
                  (webhook: any) => webhook.user_id === user.id
                ),
              };
            });

            console.log(this.userWebhooks);
          },
          (error) => {
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      });
    }, 2000);
  }

  openDialog(webhook: any) {
    const ref = this.dialogService.open(SwitchWebhookDialogComponent, {
      header: 'Submit Webhook',
      width: '40%',
      data: webhook,
    });
  }

  deleteWebhook(webhook: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${webhook.webhook_name}?`,
      accept: () => {
        this.switchWebhookService
          .deleteSwitchWebhookData(webhook.id)
          .subscribe(() => {
            setTimeout(() => {
              this.ngOnInit();
              // refresh the page
              window.location.reload()

              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Webhook Deleted Successfully',
                key: 'delete_webhook',
              });
            }, 2000);
          });
      },
    });
  }
}
