import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { IRole } from '../_models/role';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private roleSubject = new BehaviorSubject<any[]>([]);
  public roles$ = this.roleSubject.asObservable();


  roleForm: FormGroup<{
    name: FormControl<string | null>;
    description: FormControl<string | null>;
    role_id: FormControl<string | null>;
  }>;

  constructor(private http: HttpClient) {
    this.roleForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      role_id: new FormControl(''),
    });
   }

  updateRoles(roles: any[]) {
    this.roleSubject.next(roles);
  }

  getRole() {
    return this.http.get<IRole[]>(`${environment.apiUrl}/role`);
  }

  postRole(role: any) {
    return this.http.post<any>(`${environment.apiUrl}/role/add-role`, role)
  }

  getRoleById(role_id: number){
    return this.http.get(`${environment.apiUrl}/role/${role_id}`);
  }

  updateRole(role_Id: number, roleData: any){
    return this.http.put(`${environment.apiUrl}/role/${role_Id}`, roleData);
  }

  deleteRole(role_id: number){
    return this.http.delete(`${environment.apiUrl}/role/${role_id}`);
  }
}
