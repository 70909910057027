﻿import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../_services/user.service';

import { AuthenticationService } from '../_services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authenticationService.userValue;

    if (user) {
      // check if route is restricted by role
      this.userService.getCurrentLoggedInUser();

      this.userService.currentUser$.subscribe((currentUser: any) => {
        if (
          route.data['role'].indexOf(currentUser && currentUser.role?.name) ===
          -1
        ) {
          // role not authorised for this route so redirect to appropriate page
          if (currentUser.role?.name === 'basic-bulkorders') {
            this.router.navigate(['/bulk-orders']);
          } 
          else if (currentUser.role?.name === 'customer-switch') {
            this.router.navigate(['/switch-webhook']);
          }
          return false;
        } else {
          return true;
        }
      });

      return true;
    }

    // authorised so return true

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
