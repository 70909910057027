<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">My Quotes</h2>
  </div>
</div>
<div class="surface-section px-4 py-2 md:px-6 lg:px-8" style="margin-top: 50px">
  <p-table
    #dt
    [value]="myQuotes"
    [tableStyle]="{ 'min-width': '80rem' }"
    [rowHover]="true"
    [scrollable]="true"
    scrollHeight="50vh"
    styleClass="z-0"
    [globalFilterFields]="[
      'quote_number',
      'quote_total',
      'quote_date',
      'quote_status'
    ]"
    [paginator]="true"
    [rows]="50"
    [rowsPerPageOptions]="[50, 100]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="filterGlobal($event)"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          <span class="font-semibold text-md text-color-secondary">{{
            col.header
          }}</span>
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>

        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-quote>
      <tr style="cursor: pointer" (click)="openDialog(quote)">
        <td *ngFor="let col of columns">
          <div class="flex align-items-center gap-3">
            <ng-container *ngIf="col.field !== 'quote_status' && col.field !== 'quote_date'">
              <div>
                <p class="mt-0 mb-2 font-medium text-md text-color-primary">
                  {{ quote[col.field] }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="col.field === 'quote_date'">
              <div>
                <p class="mt-0 mb-2 font-medium text-md text-color-primary">
                  {{ quote[col.field] | date: 'yyyy-MM-dd HH:mm:ss' }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="col.field === 'quote_status'">
              <div *ngIf="quote[col.field] != null">
                <p-tag
                  styleClass="p-3 border-round-3xl"
                  severity="{{ getSeverityFromBool(quote[col.field]) }}"
                  value="{{ quote[col.field] }}"
                ></p-tag>
              </div>
            </ng-container>
          </div>
        </td>
        <td>
          <button
            type="button"
            pButton
            icon="pi pi-ellipsis-v"
            class="p-button-text p-button-secondary"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center"
        >
          <p-progressBar
            styleClass="align-self-center"
            *ngIf="isLoading"
            mode="indeterminate"
          >
          </p-progressBar>
          <div *ngIf="!isLoading">
            <p>
              There are no records to show. You might be disconnected or
              offline.
            </p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
