import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/_services';
import { RegisterUserComponent } from './register-user/register-user.component';
import { DialogService } from 'primeng/dynamicdialog';
import { UserDetailsComponent } from 'src/app/main-application/settings/user-details/user-details.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';
import { UpdateRoleComponent } from './update-role/update-role.component';
import { RoleDetailsComponent } from './role-details/role-details.component';
import { CreateRoleComponent } from './create-role/create-role.component';
import { RoleService } from 'src/app/_services/role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CustomerService } from 'src/app/_services/customer.service';
import { Table } from 'primeng/table';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class SettingsComponent implements OnInit {
  selectedMember: any;
  selectedRole: any;
  activeTab: number = 0;
  activeTab2: number = 1;
  activeTab3: number = 0;
  value1: boolean = false;
  members: any;
  members$ = this.userService.members$;
  ref: any;
  selectedRow: any;
  roles$ = this.roleService.roles$;
  roles: any;
  currentUser: any;
  loading: boolean = false;
  @ViewChild('dt') dt: Table | undefined;
  @ViewChild('dt1') dt1: Table | undefined;
  isLoadingUsers: boolean = true;
  isLoadingRoles: boolean = true;

  onContextMenuSelect($event: any) {
    throw new Error('Method not implemented.');
  }

  contextMenuUsers: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-pencil', command: () => {
      this.updateUser(this.selectedMember)
     } },
    { label: 'Delete', icon: 'pi pi-times', command: () => { 
      this.confirmUserContextMenu()
    } },
  ];

  contextMenuRole: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-pencil', command: () => {
      this.updateRole(this.selectedRole)
     } },
    { label: 'Delete', icon: 'pi pi-times', command: () => { 
      this.confirmRoleContextMenu()
    } },
  ];

  items: MenuItem[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.updateUser(this.selectedRow)
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            this.confirmUser()
          },
        },
      ],
    },
  ];

  roleItems: MenuItem[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.updateRole(this.selectedRow)
          }
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            this.confirmRole()
          },
        },
      ],
    },
  ];

  constructor(
    private dialogService: DialogService,
    public userService: UserService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private roleService: RoleService,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location,
    public customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.userService.getAll().subscribe((users) => {
      this.userService.updateMembers(users);
      this.isLoadingUsers = false;

    },(error: any) => {
       this.isLoadingUsers = false;
    }, () => {
      this.isLoadingUsers = false;
    }
    );

    this.roleService.getRole().subscribe((roles) => {
      this.roleService.updateRoles(roles);
      this.isLoadingRoles = false;
    },(error: any) => {
      this.isLoadingRoles = false;
   }, () => {
     this.isLoadingRoles = false;
   });

    this.userService.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });

  }

  showUserRegisterDialog() {
    this.ref = this.dialogService.open(RegisterUserComponent, {
      data: {
        formdata: null,
      },
      header: 'Register a User',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((res: any) => {
      if (!res) return;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'User Created Successfully',
      });
    });
  }

  showCreateRoleDialog() {
    this.ref = this.dialogService.open(CreateRoleComponent, {
      data: {
        formdata: null,
      },
      header: 'Register a User',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((res: any) => {
      if (!res) return;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Role Created Successfully',
      });
    });
  }

  showUserDetailsDialog(rowData: any, event: Event) {

    // Check if the target of the click event was the ellipsis button
    if ((event.target as HTMLElement).classList.contains('pi-ellipsis-v')) {
      // Clicked on the ellipsis button, don't open the dialog
      return
    } else {
      // Clicked on the row, open the dialog
      const ref = this.dialogService.open(UserDetailsComponent, {
        data: {
          user: rowData,
        },
        header: 'User Details',
        width: '70%',
      })
    }
  }

  showRoleDetailDialog(rowData: any, event: Event) {
    if ((event.target as HTMLElement).classList.contains('pi-ellipsis-v')) {
      // Clicked on the ellipsis button, don't open the dialog
      return
    }

    const ref = this.dialogService.open(RoleDetailsComponent, {
      data: {
        role: rowData
      },
      header: 'Role Details',
      width: '70%'
    })

  }

  async updateUser(rowData: any) {
    const ref = await this.dialogService.open(UpdateUserComponent, {
      data: {
        user: rowData,
      },
      header: 'Client Details',
      width: '70%',
    }).onClose.toPromise();

    this.ref.onClose.subscribe((res: any) => {
      if (!res) return;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'User Updated Successfully',
      });
    });

  }

  async updateRole(rowData: any) {
    const ref = await this.dialogService.open(UpdateRoleComponent, {
      data: {
        role: rowData,
      },
      header: 'Client Details',
      width: '70%',
    }).onClose.toPromise();

    this.ref.onClose.subscribe((res: any) => {
      if (!res) return;
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Role Updated Successfully',
      });
    });
  }

  storeRowDetail(details: any) {
    this.selectedRow = details;
  }

  confirmUserContextMenu() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.userService.deleteUser(this.selectedMember.id).subscribe((res) => {
          this.userService.getAll().subscribe(
            (users) => {
              this.userService.updateMembers(users);
            },
            (error: any) => {
              // Display an error message
            }
          );
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Record deleted'
          });
        });
      },

      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
        }
      }
    });
  }

  confirmUser() {

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.userService.deleteUser(this.selectedRow.id).subscribe((res) => {
          this.userService.getAll().subscribe(
            (users) => {
              this.userService.updateMembers(users);
            },
            (error: any) => {
              // Display an error message
            }
          );
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Record deleted'
          });
        });
      },

      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
        }
      }
    });
  }

  confirmRoleContextMenu() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this role?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.roleService.deleteRole(this.selectedRole.id).subscribe((res) => {
          this.roleService.getRole().subscribe((roles) => {
            this.roleService.updateRoles(roles)
            })
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Record deleted'
          });
        });
      },

      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
        }
      }
    });
  }

  confirmRole() {

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this role?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.roleService.deleteRole(this.selectedRow.id).subscribe((res) => {
          this.roleService.getRole().subscribe((roles) => {
            this.roleService.updateRoles(roles)
            })
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Record deleted'
          });
        });
      },

      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
        }
      }
    });
  }

  updateProfile(id: number) {
    this.loading = true;
    this.userService.updateUserForm.value.role_id = this.currentUser.role_id;
    const userFormValue = this.userService.updateUserForm.value;    
    // Check if all required fields are present and not empty
    if (!userFormValue.username ||
        !userFormValue.email ||
        !userFormValue.first_name ||
        !userFormValue.last_name ||
        userFormValue.enabled === undefined) {
      // Display an error message or prevent the update request
      this.loading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all required fields',
      });
      return;
    }
    

    // If all required fields are filled, proceed with the update request
    this.userService.updateUser(id, userFormValue).subscribe((res: any) => {
      this.userService.getAll().subscribe(
        (users) => {
          this.userService.updateMembers(users);

        },
        (error: any) => {
          // Display an error message
        },
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'User Updated Successfully',
          });
        }
      );
      this.loading = false;
    })
  
  }
  
  userColumns = [
    { field: 'username', header: 'Username' },
    { field: 'email', header: 'Email' },
    { field: 'company_name', header: 'Customer Company Name' },

  ];

  roleColumns = [
    { field: 'name', header: 'Role Name' },
    { field: 'description', header: 'Description' },
  ];

  
  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  filterGlobal1(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt1?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.userColumns.length + 1;
  }
}
