import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { QuotesService } from 'src/app/_services/quotes.service';

@Component({
  selector: 'app-quote-detail',
  templateUrl: './quote-detail.component.html',
  styleUrls: ['./quote-detail.component.scss']
})
export class QuoteDetailComponent implements OnInit {

  quote: any; // Store the selected quote data

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public quotesService: QuotesService,
  ) { }

  ngOnInit(): void {
    const rowData = this.config.data.rowData;

    // Subscribe to the quotes$ Subject to get the quotes data
    this.quotesService.quotes$.subscribe((quotes: any[]) => {
      // Find the quote with a specific ID (e.g., quote_id)
      this.quote = quotes.find((q) => q.id === rowData.id);
    });

    // Call the service to fetch quotes
    this.quotesService.getQuotes();
  }
}


