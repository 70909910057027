import { Injectable } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BulkOrderFormService {
  private ordersSubject = new BehaviorSubject<any[]>([]);
  public orders$ = this.ordersSubject.asObservable();

  private ReviewCountSubject = new BehaviorSubject<number>(0);
  public reviewCount$ = this.ReviewCountSubject.asObservable();

  private rejectedBulkOrderCountSubject = new BehaviorSubject<number>(0);
  public rejectedBulkOrderCount$ = this.rejectedBulkOrderCountSubject.asObservable();

  private bulkOrderUsersSubject = new BehaviorSubject<any[]>([]);
  public bulkOrderUsers$ = this.bulkOrderUsersSubject.asObservable();

  constructor(private fb: FormBuilder, private api: ApiService) {}


  updateReviewCount(count: number){
    this.ReviewCountSubject.next(count);
  }

  updateRejectedBulkOrderCount(count: number){
    this.rejectedBulkOrderCountSubject.next(count);
  }

  updateOrders(orders: any[]) {
    this.ordersSubject.next(orders);
  }

  updateBulkOrderUsers(users: any[]) {
    this.bulkOrderUsersSubject.next(users);
  }

  createBulkOrder(orders: any) {
    return this.api.postAPI('bulk-orders/create-bulk-order', orders);
  }

  getBulkOrderUsers() {
    return this.api.getAPI('bulk-orders/bulk-order-users');
  }

  getBulkOrders() {
    return this.api.getAPI('bulk-orders/get-all-bulk-orders');
  }

  getBulkOrderSubmitted() {
    return this.api.getAPI('bulk-orders/get-bulk-order-actions');
  }

  getBulkOrderActions(id: number) {
    return this.api.getAPI(`bulk-orders/actions/${id}`);
  }

  reviewBulkOrder(orderId: number, data: any) {
    return this.api.updateAPI(`bulk-orders/final-review-bulk-order`, orderId, data);
  }

  rejectBulkOrder(orderId: number, data: any) {
    return this.api.updateAPI(`bulk-orders/reject-bulk-order`, orderId, data);
  }

  getBulkOrderActionCount(){
    return this.api.getAPI('bulk-orders/bulk-order-action-count');
  }

  getRejectedBulkOrderCount(){
    return this.api.getAPI('bulk-orders/get-rejected-order-count');
  }

  getRejectedBulkOrders(){
    return this.api.getAPI('bulk-orders/rejected-bulk-orders');
  }

  getBulkOrderByID(orderId: number){
    return this.api.getAPI(`bulk-orders/get-bulk-order/${orderId}`);
  }

  updateBulkOrder(orderId: number, data: any){
    return this.api.updateAPI(`bulk-orders/update-bulk-order`, orderId, data);
  }

  deleteBulkOrder(orderId: number){
    return this.api.deleteAPI(`bulk-orders/delete-bulk-order`, orderId);
  }

  getBulkOrderForm() {
    return this.fb.group({
      refering_doctor: [''],
      mnem: [''],
      copy_doctor_1: [''],
      copy_doctor_2: [''],
      copy_doctor_3: [''],
      hospital_ward: [''],
      quantity: ['', [Validators.required, Validators.min(1)]],
      size: ['', [Validators.required]],
      biocross: [''],
    });
    // Listen for changes in the order type
  }

  // Method to dynamically update the Biocross field's validation based on order_type
  setBiocrossValidation(form: FormGroup) {
    const orderTypeControl = form.get('order_type');
    const ordersArray = form.get('orders') as FormArray; // Get the form array

    // Listen for changes in the order type
    orderTypeControl?.valueChanges.subscribe((orderType: any) => {
      // Loop through each form group in the array
      ordersArray.controls.forEach((group: AbstractControl) => {
        const biocrossControl = (group as FormGroup).get('biocross');

        // Apply or clear validators based on order type
        if (
          orderType?.value === 'A5 Biocross' ||
          orderType?.value === 'A4 Cashpath' ||
          orderType?.value === 'A4 (Cyto, Downs, Gynae, Histo)' ||
          orderType?.value === 'A4 Profile Biocross books'
        ) {
          biocrossControl?.setValidators(Validators.required); // Add required validator
        } else {
          biocrossControl?.clearValidators(); // Remove required validator
        }

        // Update validity status for each biocross control
        biocrossControl?.updateValueAndValidity();

       
      });
    });
  }
}
