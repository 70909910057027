<p-contextMenu #cm [model]="items"></p-contextMenu>

<div class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card">
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Customers</h2>
  </div>

</div>
<div class="my-4 px-4 py-2 md:px-6 lg:px-8">
  <p-table
    #dt
    [value]="customers"
    scrollHeight="60vh"
    [rowHover]="true"
    [scrollable]="true"
    [globalFilterFields]="['company_name', 'code', 'id']"
    width="100%"
    styleClass="z-0"
    [paginator]="true"
    [rows]="100"
    [rowsPerPageOptions]="[100, 200, 500]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [contextMenu]="cm"
    [(contextMenuSelection)]="selectedCustomer"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="filterGlobal($event)"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          <span class="font-semibold text-md text-color-secondary ">{{
            col.header
          }}</span>
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer>
      <tr style="cursor: pointer" [pContextMenuRow]="customer">
        <td *ngFor="let col of columns">
          <div class="flex align-items-center gap-3">
            <div>
              <p class="mt- mb-2 font-medium text-md text-color-primary">
                {{ customer[col.field] }}
              </p>
            </div>
          </div>
        </td>
        <td>
          <button
            type="button"
            pButton
            icon="pi pi-ellipsis-v"
            class="p-button-text p-button-secondary"
            (click)="
              menu.toggle($event);
              storeRowDetail(customer);
              $event.stopPropagation()
            "
          ></button>
          <p-menu #menu [model]="items" [popup]="true" appendTo="body"></p-menu>
          <p-menu #menu appendTo="body" [popup]="true"></p-menu>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center"
        >
          <p-progressBar
            styleClass="align-self-center"
            *ngIf="isLoading"
            mode="indeterminate"
          >
          </p-progressBar>
          <div *ngIf="!isLoading">
            <p>
              There are no records to show. You might be disconnected or offline.
            </p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
