<p-toast key="delete_webhook"></p-toast>

<div *ngIf="userWebhooks.length == 0; else showEmptyPage">
  <!-- Show an empty message -->
  <div class="flex flex-row min-w-full max-w-full">
    <div
      class="grid justify-content-center align-content-center align-items-center m-4 surface-card shadow-2 w-full"
    >
      <div class="col-12">
        <i class="pi pi-info-circle text-base text-gray-800"></i>
        <span class="font-semibold">
          No webhooks have been made from this account</span
        >
      </div>
    </div>
  </div>
</div>

<ng-template #showEmptyPage>
  <div class="flex max-w-full">
    <div
      class="grid justify-content-center align-content-center align-items-center ml-4 mr-4 surface-card w-full"
    >
      <div *ngFor="let userWebhook of userWebhooks; index as i" class="col-12">
        <div
          class="flex justify-content-center align-content-center align-items-center shadow-2 mt-4"
        >
          <h2>{{ userWebhook.user_details.username }}'s Webhooks</h2>
        </div>
        <div class="flex flex-row flex-wrap m-4">
          <div
            class="w-full shadow-2 mt-4 mr-2"
            *ngFor="let webhook of userWebhook.webhooks"
          >
            <div class="flex flex-column align-content-center w-full ml-2">
              <div class="ml-2">
                <h2>{{ webhook.webhook_name }}</h2>
              </div>
              <div class="m-2">
                <span innerHTML="{{ webhook.webhook_url }}"> </span>
              </div>
              <div class="m-2">
                <h4 innerHTML="{{ webhook.description }}"></h4>
              </div>
            </div>
            <div class="flex flex-row mt-2 mb-2 ml-2">
              <div class="m-2">
                <button
                  pButton
                  type="submit"
                  label="Upload"
                  icon="pi pi-upload"
                  class="primary"
                  (click)="openDialog(webhook)"
                ></button>
              </div>
              <div class="m-2">
                <button
                  pButton
                  type="submit"
                  label="Delete"
                  icon="pi pi-trash"
                  class="p-button-danger"
                  (click)="deleteWebhook(webhook)"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<p-confirmDialog header="Delete"></p-confirmDialog>
