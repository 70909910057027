import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.scss']
})
export class UpdateProductComponent implements OnInit {
  productData: any;
  loadingData: boolean = true;
  products: any[] = [];
  errorLoadingData: boolean = false;

  constructor(
    public productsService: ProductsService,
    private dialogService: DialogService,
    public config: DynamicDialogConfig,
    private router: Router,
    public location: Location,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    const product = this.config.data.product;

    this.productsService.getAllProducts().subscribe((products) => {
      this.loadingData = false;
      this.productData = products.find((p: any) => p.id === product.id);
    }
    );
  }

  updateProduct(id: number) {
    const productFormValue = this.productsService.productForm.value;
    
    this.productsService.updateProduct(id, productFormValue);
    this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
      this.router.navigate([decodeURI(this.location.path())]);
      this.ref.close();
    });
  }
}

