<div *ngIf="userService.currentUser$ | async as currentUser">
  <div
    class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
  >
    <div class="flex align-items-center justify-content-center">
      <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Home</h2>
    </div>
  </div>
  <div class="m-6 max-w-full" *ngIf="currentUser.role?.name == 'admin-switch'">
    <app-home-switch-admin></app-home-switch-admin>
  </div>
  <div class="m-6 max-w-full" *ngIf="currentUser.role?.name == 'review-bulkorders'">
    <app-home-bulkorder-reviewer></app-home-bulkorder-reviewer>
  </div>
  <div class="grid surface-card px-4 py-8 md:px-6 lg:px-8 m-4">
    <div
      *ngIf="
        currentUser.role?.name == 'admin' ||
        currentUser.role?.name == 'customer-zpl'
      "
      class="col-12 lg:col-6 flex flex-wrap relative overflow-hidden"
      style="
        border-radius: 1rem;
        background: radial-gradient(
            100% 1126.49% at 100% 0%,
            rgba(82, 82, 82, 0.068) 0%,
            rgba(0, 0, 0, 0) 100%
          ),
          #ffffff;
      "
    >
      <div class="px-6 py-8">
        <div class="text-black text-medium text-5xl mt-3 mb-5">
          <span class="font-bold">Get a Quote Within Seconds</span>
        </div>
        <div class="flex flex-row">
          <div>
            <button
              pRipple
              class="appearance-none"
              class="p-3 bg-orange-200 hover:bg-orange-300 cursor-pointer transition-colors font-medium gray-900 border-none p-component transition-colors transition-duration-150"
              style="border-radius: 35px"
              [routerLink]="['/generatequote']"
            >
              Generate Quote
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        currentUser.role?.name == 'admin' ||
        currentUser.role?.name == 'customer-pifsa'
      "
      class="col-12 lg:col-6 mt-3 flex flex-wrap relative overflow-hidden"
      style="
        border-radius: 1rem;
        background: radial-gradient(
            100% 1126.49% at 100% 0%,
            rgba(82, 82, 82, 0.068) 0%,
            rgba(0, 0, 0, 0) 100%
          ),
          #ffffff;
      "
    >
      <div class="px-6 py-8">
        <div class="text-black text-medium text-5xl mt-3 mb-5">
          <span class="font-bold">Upload Artwork Preflight & Print</span>
        </div>
        <div class="flex flex-row">
          <div>
            <button
              pRipple
              class="appearance-none"
              class="p-3 pl-3 bg-orange-200 hover:bg-orange-300 cursor-pointer transition-colors font-medium gray-900 border-none p-component transition-colors transition-duration-150"
              style="border-radius: 35px"
              [routerLink]="['/artwork']"
            >
              Submit Artwork
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="m-6">
    <p-tabView>
      <p-tabPanel
        *ngIf="
          currentUser.role?.name == 'admin' ||
          currentUser.role?.name == 'customer-zpl'
        "
        header="Products"
      >
        <div class="px-2 py-4 md:px-2 lg:px-2">
          <div
            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-5"
          >
            <span class="text-900 text-3xl font-medium">PRINTING</span>
          </div>

          <div class="grid">
            <!-- template product -->
            <div *ngFor="let arr of array" class="col-12 lg:col-3 mr-3">
              <p-skeleton
                *ngIf="products.length <= 0"
                size="20rem"
                styleClass="col-12 md:col-6 lg:col-3"
              ></p-skeleton>
            </div>
            <div class="col-12">
              <div class="grid">
                <div
                  class="col-12 lg:col-3 shadow-6 border-round-md mr-3 mb-4 cursor-pointer lg:max-w-20rem"
                  *ngFor="let product of products; index as product_idx"
                >
                  <div
                    class="p-2"
                    (mouseenter)="hoveredItem = product_idx"
                    (mouseleave)="hoveredItem = null"
                    [routerLink]="['/generatequote']"
                    (click)="clickedProduct = product; createQuote(product)"
                  >
                    <div class="relative mb-3">
                      <div
                        class="fadein bg-black-alpha-50 absolute left-0 top-0 w-full h-full"
                        *ngIf="hoveredItem === product_idx"
                      ></div>
                      <div>
                        <img [src]="imageArray[product_idx]" class="w-full" />
                      </div>
                    </div>
                    <div
                      class="surface-section pt-3 z-1 relative transition-all transition-duration-300"
                      [ngClass]="{ '-mt-8 pb-8': hoveredItem === product_idx }"
                    >
                      <div
                        class="text-center text-700 font-medium text-xl mb-3"
                      >
                        {{ product.product_name }}
                      </div>
                      <div class="mb-3 text-center">
                        <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i class="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i class="pi pi-star-fill text-300"></i>
                      </div>
                      <div class="text-center text-900 text-xl mb-3">
                        {{ product.description }}
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel
        header="Admin Dashboard"
        *ngIf="currentUser.role?.name == 'admin'"
      >
        <p-chart
          type="pie"
          [data]="basicData"
          [options]="basicOptions"
          width="400"
        ></p-chart>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
