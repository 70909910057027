import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { DialogService } from 'primeng/dynamicdialog';
import {
  MenuItem,
  ConfirmationService,
  MessageService,
  ConfirmEventType,
} from 'primeng/api';
import { UpdateProductComponent } from '../update-product/update-product.component';
import { CreateProductComponent } from '../create-product/create-product.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  selectedRow: any;
  ref: any;
  isTrue: boolean = false;
  selectedProduct: any;
  @ViewChild('dt') dt: Table | undefined;
  isLoading: boolean = true;

  constructor(
    public productsService: ProductsService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.productsService.getAllProducts().subscribe((products) => {
      this.productsService.products = products;
      this.productsService.products$.next(this.productsService.products);
      this.isLoading = false;
    });
  }

  items: MenuItem[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.updateProduct(this.selectedRow);
          },
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            this.deleteProduct();
          },
        },
      ],
    },
  ];

  columns = [
    { field: 'external_product_id', header: 'Product ID' },
    { field: 'product_name', header: 'Product Name' },
    { field: 'description', header: 'Product Description' },
  ];

  storeRowDetail(row: any) {
    this.selectedRow = row;
  }

  updateProduct(product: any) {
    const ref = this.dialogService.open(UpdateProductComponent, {
      header: 'Update Product',
      width: '70%',
      data: {
        product: product,
      },
    });
  }

  addProduct() {
    this.ref = this.dialogService.open(CreateProductComponent, {
      header: 'Add Product',
      width: '70%',
    });
  }

  deleteProduct() {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.productsService
          .deleteProduct(this.selectedRow.id)
          .subscribe((res) => {
          });
        location.reload();
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Record deleted',
        });
      },
      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              severity: 'error',
              summary: 'Rejected',
              detail: 'You have rejected',
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }

  showProductDetails($event: any, product: any) {    
    this.selectedProduct = product;
    
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.columns.length;
  }

}
