<div class="flex card-container m-5">
  <div class="flex-grow-1 flex align-items-center justify-content-center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-2">
        <!-- <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3"> -->
        <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        <!-- <span class="text-600 font-medium line-height-3"
          >Don't have an account?</span
        >
        <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
          >Create today!</a
        > -->
      </div>

      <div>
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
          <label for="username" class="block text-900 font-medium mb-2"
            >Username</label
          >
          <input
            type="text"
            formControlName="username"
            class="form-control"
            pInputText
            class="w-full mb-3"
          />
          
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <p-message
              *ngIf="f.username.errors.required"
              severity="error"
              text="Incorrect username"
              styleClass="w-full"
            ></p-message>
          </div>

          <label for="password" class="block text-900 font-medium mb-2"
            >Password</label
          >
          <input
            type="password"
            formControlName="password"
            class="form-control"
            pInputText
            class="w-full mb-3"
          />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <p-message
              *ngIf="f.password.errors.required"
              severity="error"
              text="Incorrect password"
              styleClass="w-full"
            ></p-message>
          </div>

          <div
            class="flex align-items-center justify-content-between mb-6"
          ></div>

          <button
            pButton
            pRipple
            [disabled]="loading"
            label="Sign In"
            class="w-full"
            [loading]="loading">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
          </button>
          <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
            <p-message
              severity="error"
              text="Incorrect username or password"
              styleClass="w-full"
            ></p-message>
          </div>
        </form>
      </div>
      <div class="flex align-items-center justify-content-between my-2">
        <div class="flex align-items-center">
          <!-- <p-checkbox
            id="rememberme"
            [binary]="true"
            styleClass="mr-2"
          ></p-checkbox>
          <label for="rememberme1">Remember me</label> -->
        </div>
        <a
          class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
          [routerLink]="['/forgot-password']"
          >Forgot password?</a
        >
      </div>
    </div>
  </div>
</div>
