import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/_services/cart.service';
import { UserService } from 'src/app/_services';
import { QuotesService } from 'src/app/_services/quotes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  cartItems: any[] = [];
  selectedCartItem: any;
  subTotal: number = 0;
  vat: number = 0.0;
  cartTotal: number = 0;
  currentUser: any;
  checkedOut: boolean = false;
  loading: boolean = false;

  constructor(
    private cartService: CartService,
    public userService: UserService,
    private quotesService: QuotesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userService.getMe().subscribe((user) => {
    });
 

    const savedProduct = localStorage.getItem('sendToCart');
    if (savedProduct) {
      this.cartItems = JSON.parse(savedProduct);
      this.subTotal = this.cartItems.reduce(
        (acc, item) => acc + item.price,
        0
      );
      this.subTotal = parseFloat(this.subTotal.toFixed(2));
      this.vat = parseFloat((this.subTotal * 0.15).toFixed(2));
      this.cartTotal = parseFloat((this.subTotal + this.vat).toFixed(2));
    }
    this.cartService.updateCartItems(this.cartItems);
  }

  removeItem(index: number) {
    this.cartItems.splice(index, 1);
    localStorage.setItem('sendToCart', JSON.stringify(this.cartItems));
    this.cartService.updateCartItems(this.cartItems);
  }

  selectCartItem(item: any) {
    this.selectedCartItem = item;
  }

  checkOut(cartItems: any[]) {
    this.loading = true;
    this.userService.getMe().subscribe((user) => {
      this.currentUser = user;
    
      this.quotesService
        .sendEmail(this.currentUser.email, cartItems)
        .subscribe((response) => {});
      
        this.cartItems = [];
        localStorage.setItem('sendToCart', JSON.stringify(this.cartItems));
        this.cartService.updateCartItems(this.cartItems);
        this.checkedOut = true;
    });
    
    
    // redirect to thankyou.html
  }
}
