import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/_models/user';
import { AuthenticationService, UserService } from 'src/app/_services';
import { ProductsService } from 'src/app/_services/products.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  user: any;
  basicData: any;
  basicOptions: any;
  loading = 0;
  array: any[] = ['data', 'data', 'data'];
  imageArray: any[] = [
    'assets/images/flyer_mockup_1.jpg',
    'assets/images/Business_Card_1.jpg',
    'assets/images/Poster_Mockup_1.jpg',
  ]

  constructor(
    public userService: UserService,
    public authenticationService: AuthenticationService,
    public productsService: ProductsService
  ) {}

  hoveredItem: any;
  products: any[] = [];
  products$: Subject<any[]> = new Subject<any[]>();
  clickedProduct: any;

  ngOnInit(): void {
    this.userService.getCurrentLoggedInUser();

    this.userService.getMe().subscribe((user) => {
      this.user = user;
    });

    this.productsService.getAllProducts().subscribe((products) => {
      console.log(products);
      
      this.loading++;
      this.products = products;
      this.products$.next(products);

      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue(
        '--text-color-secondary'
      );
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      this.basicData = {
        labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        datasets: [
          {
            label: 'Sales',
            data: [540, 325, 702, 620],
            backgroundColor: [
              'rgba(255, 159, 64, 0.5)',
              'rgba(75, 192, 192, 0.5)',
              'rgba(54, 162, 235, 0.5)',
              'rgba(153, 102, 255, 0.5)',
            ],
            borderColor: [
              'rgb(255, 159, 64)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
            ],
            borderWidth: 1,
          },
        ],
      };

      this.basicOptions = {
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
      };

      this.loading--;
      //   console.log(products);
    });
  }
  createQuote(product: any): void {
    //on click we need to redirect to the quote page with the product and then the user can fill out the form
    this.productsService.setClickedProduct(this.clickedProduct);
  }
}
