<p-toast key="bulk-order-dialog"></p-toast>
<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">
      Review Bulk Orders
    </h2>
  </div>
</div>
<div class="overflow-x-scroll p-4">
  <p-table
    [value]="(orders$ | async) || []"
    [tableStyle]="{ 'min-width': '60rem' }"
    [rowHover]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order>
      <tr style="cursor: pointer;" (click)="openDialog(order, true)">
        <td *ngFor="let col of columns">
          <p class="mb-2 font-medium text-md text-color-primary">
            {{ order.bulk_orders[col.field] }}
          </p>
        </td>
      </tr> 
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center"
        >
          <p-progressBar
            styleClass="align-self-center"
            *ngIf="isLoading"
            mode="indeterminate"
          >
          </p-progressBar>
          <div *ngIf="!isLoading">
            <p>
              There are no records to show. You might be disconnected or offline.
            </p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
