<div *ngIf="loading; else showTemplate">
  <p-progressSpinner></p-progressSpinner>
</div>

<ng-template #showTemplate>
  <div *ngIf="!webhooks; else showEmptyPage">
    <!-- Show an empty message -->
    <div class="flex flex-row min-w-full max-w-full">
      <div
        class="grid justify-content-center align-content-center align-items-center m-4 surface-card shadow-2 w-full"
      >
        <div class="col-12">
          <i class="pi pi-info-circle text-base text-gray-800"></i>
          <span class="font-semibold">
            No webhooks have been made for this account</span
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showEmptyPage>
  <div class="flex flex-row max-w-full">
    <div
      class="grid justify-content-center align-content-center align-items-center m-4 surface-card shadow-2 w-full"
    >
      <div
        *ngFor="let webhook of webhooks; let i = index"
        class="col-4 border-1 border-orange-600 m-4 w-4"
      >
        <div class="">
          <div class="m-4">
            <h2>{{ webhook.webhook_name }}</h2>
          </div>
          <div class="m-4">
            <span class="font-bold">{{ webhook.description }}</span>
          </div>
          <div class="ml-4 mr-4 mb-2 mt-8">
            <button
              pButton
              type="submit"
              label="Upload"
              icon="pi pi-upload"
              class="primary w-full"
              (click)="openDialog(webhook)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
