import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { UserService } from 'src/app/_services';
import { DialogService } from 'primeng/dynamicdialog';
import { SwitchWebhookDialogComponent } from '../switch-webhook-dialog/switch-webhook-dialog.component';
@Component({
  selector: 'app-switch-webhook-client',
  templateUrl: './switch-webhook-client.component.html',
  styleUrls: ['./switch-webhook-client.component.scss'],
})
export class SwitchWebhookClientComponent implements OnInit {
  webhooks: any[] = [];
  user: any;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private switchWebhookService: SwitchWebhookService,
    private userService: UserService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.currentUser;

    this.switchWebhookService.getWebhooksByUserId(this.user.id).subscribe(
      (data: any) => {
        this.loading = true;
        this.webhooks = data.body;
      },
      (error) => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  openDialog(webhook: any) {
    const ref = this.dialogService.open(SwitchWebhookDialogComponent, {
      header: 'Submit Webhook',
      width: '40%',
      data: webhook,
    });
  }
}
