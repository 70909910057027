<div
  class="grid ml-8 mr-8 surface-section"
  *ngIf="cartItems.length > 0; else showItems"
>
  <div class="col-12">
    <div
      class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
    >
      <div class="flex align-items-center justify-content-center">
        <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Your Cart</h2>
      </div>
    </div>
  </div>

  <div class="col-8">
    <ul *ngFor="let cartItem of cartItems as cart_idx" class="list-none p-0 m-0">
      <li
        class="flex flex-column md:flex-row py-6 border-top-1 border-bottom-1 surface-border md:align-items-center"
      >
        <div class="flex-auto md:pl-5">
          <div
            class="flex flex-wrap align-items-start sm:align-items-center sm:flex-row sm:justify-content-between surface-border"
          >
            <div class="w-full sm:w-6 flex flex-row align-items-center">
              <img
                [src]="cartItem.selectedImage"
                alt="product image"
                class="w-6 rounded-lg"
              />
              <div class="flex flex-column m-3">
                <span class="text-900 text-xl font-medium mb-3 font-bold">{{
                  cartItem.product_name
                }}</span>
                <span class="text-600 mb-3">{{ cartItem.description }}</span>
                <span class="text-600 mb-3 font-bold"
                  >Quantity: {{ cartItem.quantity }}</span
                >
                <button
                  pButton
                  pRipple
                  icon="pi pi-trash"
                  class="pr-0 mr-0 bg-white-alpha-10 text-red-600 font-medium border-transparent border-none outline-none shadow-none hover:bg-gray-800 hover:text-red-400"
                  (click)="removeItem(cartItem)"
                ></button>
              </div>
            </div>
            <div
              class="flex align-items-start justify-content-between mt-3 sm:mt-0"
            >
              <div class="flex flex-column sm:align-items-end">
                <span class="text-900 text-4xl font-medium mb-3 font-bold">
                  R{{ cartItem.price }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-4">
    <div class="flex">
      <ul class="list-none py-0 pr-0 pl-0 md:pl-5 mt-6 mx-0 mb-0 flex-auto">
        <li class="flex justify-content-between mb-4">
          <span class="text-xl text-900">Subtotal</span>
          <span class="text-xl text-900">R{{ subTotal }}</span>
        </li>
        <li class="flex justify-content-between mb-4">
          <span class="text-xl text-900">VAT</span>
          <span class="text-xl text-900">R{{ vat }}</span>
        </li>
        <li
          class="flex justify-content-between border-top-1 surface-border mb-4 pt-4"
        >
          <span class="text-xl text-900 font-bold text-3xl">Total</span>
          <span class="text-xl text-900 font-bold text-3xl"
            >R{{ cartTotal }}</span
          >
        </li>
        <li class="flex justify-content-start">
          <button
            pButton
            pRipple
            [loading]="loading"
            [disabled]="loading"
            (click)="checkOut(cartItems)"
            label="Check Out"
            class="w-full bg-gray-800 text-white font-medium px-4 border-transparent border-none outline-none shadow-none hover: bg-gray-700 hover:text-white"
          ></button>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #showItems>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8 min-h-screen" *ngIf="checkedOut; else noCheckout" style="background-image: url('/assets/images/ThankYou.jpg'); background-size:cover; background-repeat: no-repeat;">
    <div class="flex flex-column justify-content-start">
      <div class="border-round w-5 h-20rem"  >
        <div class="border-round bg-white font-bold text-8xl text-900 pl-2">Thank you!</div>
        <div class="border-round bg-white mt-2 mb-4 font-bold text-xl text-900 p-2">
          Your Order is Being Processed.
        </div>
        <div class="">

          <button
          class="bg-gray-800 text-white font-medium px-4 border-transparent border-none outline-none shadow-none hover: bg-gray-700 hover:text-white"
          type="button"
          pButton
          pRipple
          label="Go Home"
          icon="pi pi-home"
          [routerLink]="['/']"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #noCheckout>
    <div
      *ngIf="!checkedOut"
      class="mt-6 mb-5 font-bold text-6xl text-900 text-center"
      >
      There are no items in your cart
      <div class="text-center">
        <button
          class="bg-gray-800 text-white font-medium px-4 border-transparent border-none outline-none shadow-none hover: bg-gray-700 hover:text-white"
          type="button"
          pButton
          pRipple
          label="Go Home"
          icon="pi pi-home"
          [routerLink]="['/']"
        ></button>
      </div>
    </div>
    <p class="text-700 text-3xl mt-0 mb-6 text-center"></p>

</ng-template>
</ng-template>
