<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Products</h2>
  </div>
</div>
<div class="grid m-4" style="max-width: 100vw">
  <div class="col-4">
    <div class="surface-card shadow-3 border-round p-4 min-h-full">
      <div class="grid gap-4">
        <div class="col-12">
          <div class="text-xl font-medium text-900 mb-4">
            <div *ngIf="!selectedProduct">
              <label for="name">Select a Product</label>
            </div>
            <div class="mb-2" *ngIf="selectedProduct">
              <span class="font-semibold text-md text-color-primary">{{
                selectedProduct.product_name
              }}</span>
              <div class="flex flex-row">
                <div class="flex flex-column">
                  <div class="border-top-1 border-color-secondary mt-2">
                    <div class="mt-4">
                      <span class="font-semibold text-md">Product Sizes: </span>
                    </div>
                  </div>
                  <div
                    *ngFor="let size of selectedProduct.product_sizes"
                  >
                    <div class="mt-3">
                      {{ size.name }}
                    </div>
                  </div>
                  <div *ngFor="let size of selectedProduct.product_sizes">
                    <div class="mt-3">
                      {{ size.width_mm }} x {{ size.height_mm }}mm
                    </div>
                  </div>
                </div>
                <hr />
                <div class="flex flex-column">
                  <div class="border-top-1 border-color-secondary mt-2">
                    <div class="mt-4">
                      <span class="font-semibold text-md"
                        >Product Parts:
                      </span>
                    </div>
                  </div>
                  <div *ngFor="let part of selectedProduct.product_parts">
                    <div class="mt-3">
                      {{ part.default_paper_code }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8">
    <div class="surface-card shadow-3 border-round p-4">
      <div class="surface-section px-4 py-2 md:px-6 lg:px-8">
        <p-table
          #dt
          [value]="(productsService.products$ | async) || []"
          scrollHeight="40vh"
          [globalFilterFields]="[
            'product_name',
            'description',
            'external_product_id'
          ]"
          [rowHover]="true"
          [scrollable]="true"
          width="100%"
          styleClass="z-0"
          [paginator]="true"
          [rows]="100"
        >
          <ng-template pTemplate="caption">
            <div class="flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal($event)"
                  placeholder="Search keyword"
                />
              </span>
              <div>
                <button
                  pButton
                  class="ml-5"
                  label="Add Product"
                  icon="pi pi-plus"
                  (click)="addProduct()"
                ></button>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                <span class="font-semibold text-md text-color-secondary">{{
                  col.header
                }}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr
              (click)="showProductDetails($event, product)"
              style="cursor: pointer"
            >
              <td *ngFor="let col of columns">
                <div class="flex align-items-center gap-3">
                  <div>
                    <p class="mt- mb-2 font-medium text-md text-color-primary">
                      {{ product[col.field] }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  pButton
                  icon="pi pi-ellipsis-v"
                  class="p-button-text p-button-secondary"
                  (click)="
                    menu.toggle($event);
                    $event.stopPropagation();
                    storeRowDetail(product)
                  "
                ></button>
                <p-menu
                  #menu
                  appendTo="body"
                  [popup]="true"
                  [model]="items"
                ></p-menu>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td
                [attr.colspan]="this.getNumberofColumns()"
                class="align-content-center"
              >
                <p-progressBar
                  styleClass="align-self-center"
                  *ngIf="isLoading"
                  mode="indeterminate"
                >
                </p-progressBar>
                <div *ngIf="!isLoading">
                  <p>
                    There are no records to show. You might be disconnected or
                    offline.
                  </p>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <p-confirmDialog header="Delete"></p-confirmDialog>
    </div>
  </div>
</div>
