import { Component, OnInit, ViewChild } from '@angular/core';
import { QuotesService } from 'src/app/_services/quotes.service';
import { DialogService } from 'primeng/dynamicdialog';
import { QuoteDetailComponent } from 'src/app/main-application/quote-detail/quote-detail.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-quote-admin-table',
  templateUrl: './quote-admin-table.component.html',
  styleUrls: ['./quote-admin-table.component.scss']
})
export class QuoteAdminTableComponent implements OnInit {
  @ViewChild('dt') dt: Table | undefined;
  isLoading: boolean = true;
  
  columns = [
    { field: 'quote_number', header: 'Quote Reference' },
    { field: 'user_id', header: 'User ID' },
    { field: 'quote_status', header: 'Quote Status' },
    { field: 'quote_total', header: 'Total' },
  ];

  openDialog(rowData: any) {
    const ref = this.dialogService.open(QuoteDetailComponent, {
      data: {
        rowData: rowData
      },
      header: 'Quote Details',
      width: '70%',
    });
  }
  constructor(    
    public quotesService: QuotesService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.quotesService.getQuotes().subscribe(() => {
      this.isLoading = false
    }, (error: any) => {
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    });
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.columns.length;
  }

}
