<div class="surface-section px-4 py-4 md:px-1 lg:px-2">
  <div class="grid">
    <form [formGroup]="customerForm" (ngSubmit)="onSubmit(customerData.id)">
      <div class="col-12 lg:col-12">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-12 md:col-6">
            <label for="company_name" class="font-medium text-900"
              >Company Name</label
            >
            <input
              id="company_name"
              type="text"
              pInputText
              formControlName="company_name"
              [(ngModel)]="customerData.company_name"
            />
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="code" class="font-medium text-900">Code</label>
            <input
              id="code"
              type="text"
              pInputText
              formControlName="code"
              [(ngModel)]="customerData.code"
            />
          </div>
          <div class="col-12">
            <button
              [loading]="loading"
              pButton
              pRipple
              type="submit"
              label="Save Changes"
              class="w-auto mt-3"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
