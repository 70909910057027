<div class="flex card-container m-5">
  <div class="flex-grow-1 flex align-items-center justify-content-center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <form (ngSubmit)="onResetSubmit()" [formGroup]="newPasswordForm">
        <label for="password" class="block text-900 font-medium mb-2"
          > Password</label
        >
        <input
          type="password"
          formControlName="password"
          class="form-control"
          pInputText
          class="w-full mb-3"
          (input)="clearError()"
        />
        <div *ngIf="submitted && fn.password.errors" class="invalid-feedback">
          <p-message
            *ngIf="fn.password.errors.required"
            severity="error"
            text="Password is required"
            styleClass="w-full"
          ></p-message>
        </div>

        <label for="confirmPassword" class="block text-900 font-medium mb-2"
          >Confirm Password</label
        >
        <input
          type="password"
          formControlName="confirmPassword"
          class="form-control"
          pInputText
          class="w-full mb-3"
          (input)="clearError()"
        />
        <div
          *ngIf="submitted && fn.confirmPassword.errors"
          class="invalid-feedback"
        >
          <p-message
            *ngIf="fn.confirmPassword.errors.required"
            severity="error"
            text="Password is required"
            styleClass="w-full"
          ></p-message>
        </div>
        <button
          pButton
          pRipple
          [disabled]="loading"
          label="Set Password"
          class="w-full"
          type="submit"
          [loading]="loading"
          
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
        </button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">
          <p-message
            severity="error"
            [text]="error"
            styleClass="w-full"
          ></p-message>
        </div>
      </form>
    </div>
  </div>
</div>
