<p-toast></p-toast>
<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">
      Switch Webhook Submit
    </h2>
  </div>
</div>
<div *ngIf="userRole == 'customer-switch'; else showSwitchForm">
  <app-switch-webhook-client></app-switch-webhook-client>
</div>
<ng-template #showSwitchForm>
  <div
    *ngIf="userRole != 'customer-switch'"
    class="flex justify-content-center align-content-center align-items-center max-w-screen m-4"
  >
    <form action="" [formGroup]="switch_form" (ngSubmit)="submitWebhook()">
      <div
        class="flex justify-content-center align-content-center align-items-center surface-card w-30rem shadow-2 p-2"
      >
        <div class="flex flex-column flex-wrap w-full p-2">
          <div>
            <h3>Assign User</h3>
            <p-dropdown
              [options]="switch_users"
              [(ngModel)]="selectedUser"
              optionLabel="username"
              formControlName="user_id"
              placeholder="Select a User"
              (onChange)="dropdownChange($event)"
              appendTo="body"
              styleClass="w-full"
            >
            </p-dropdown>
            <div
              *ngIf="
                submitted &&
                switch_form.get('user_id')?.invalid &&
                (switch_form.get('user_id')?.touched ||
                  switch_form.get('user_id')?.pristine)
              "
            >
              <small class="text-red-500">A user is required</small>
            </div>
          </div>
          <div>
            <h3 for="">Endpoint</h3>
            <div class="flex flex-row">
              <div
                *ngIf="webhook"
                class="justify-content-center align-content-center"
              >
                <span class="text-700 pr-2">{{ webhook }}</span>
              </div>
              <div>
                <input
                  id="webhook_url"
                  formControlName="webhook_url"
                  pInputText
                  type="text"
                  [readOnly]="!webhook"
                  placeholder="Webhook URL"
                />
                <div
                  *ngIf="
                    submitted &&
                    switch_form.get('webhook_url')?.invalid &&
                    (switch_form.get('webhook_url')?.touched ||
                      switch_form.get('webhook_url')?.pristine)
                  "
                >
                  <small class="text-red-500">Webhook is required</small>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3>Submit Point Name</h3>
            <input
              id="webhook_name"
              formControlName="webhook_name"
              pInputText
              type="text"
              placeholder="Submit Point Name"
            />
            <div
              *ngIf="
                submitted &&
                switch_form.get('webhook_name')?.invalid &&
                (switch_form.get('webhook_name')?.touched ||
                  switch_form.get('webhook_name')?.pristine)
              "
            >
              <small class="text-red-500">Webhook name is required</small>
            </div>
          </div>
          <div>
            <h3>Description</h3>
            <input
              id="description"
              formControlName="description"
              pInputText
              type="text"
              placeholder="Description"
            />
            <div
              *ngIf="
                submitted &&
                switch_form.get('description')?.invalid &&
                (switch_form.get('description')?.touched ||
                  switch_form.get('description')?.pristine)
              "
            >
              <small class="text-red-500">description is required</small>
            </div>
          </div>
          <div formArrayName="fields">
            <h3>Add Custom Fields</h3>
            <div
              *ngFor="
                let fields of fieldArray.controls;
                index as i;
                last as isLast;
                first as isFirst
              "
              [formGroupName]="i"
              class="flex flex-column mb-4"
            >
              <div class="flex flex-row">
                <div class="flex-column">
                  <div>
                    <h3>Field Name:</h3>
                    <input
                      pInputText
                      formControlName="field_title"
                      class="mb-4"
                      placeholder="Field Name"
                    />
                  </div>
                  <div>
                    <div class="flex flex-row">
                      <div>
                        <p-dropdown
                          [options]="switch_fields"
                          optionLabel="field_name"
                          optionValue="field_name"
                          formControlName="field_name"
                          placeholder="Select a Field"
                          class="w-full"
                          appendTo="body"
                          (onChange)="onDropdownChange($event, i)"
                        ></p-dropdown>
                      </div>
                      <div class="flex flex-row">
                        <div>
                          <button
                            *ngIf="isLast"
                            pButton
                            label=""
                            icon="pi pi-plus"
                            class="ml-2 p-button-success"
                            (click)="addInputField()"
                          ></button>
                        </div>
                        <div>
                          <button
                            *ngIf="!isFirst"
                            pButton
                            label=""
                            icon="pi pi-minus"
                            class="ml-2 p-button-danger"
                            (click)="removeInputField(i)"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div formArrayName="field_data">
                <div
                  class="flex flex-row mt-4"
                  *ngFor="
                    let data of inputFieldData(i).controls;
                    index as j;
                    last as isLast;
                    first as isFirst
                  "
                >
                  <div>
                    <input
                      pInputText
                      [formControlName]="j"
                      type="text"
                      placeholder="dropdown value"
                      class="ml-2"
                    />
                  </div>
                  <div>
                    <button
                      *ngIf="isLast"
                      pButton
                      label=""
                      icon="pi pi-plus"
                      class="ml-2 p-button-success"
                      (click)="addInputFieldData(i)"
                    ></button>
                  </div>
                  <div>
                    <button
                      *ngIf="!isFirst"
                      pButton
                      label=""
                      icon="pi pi-minus"
                      class="ml-2 p-button-danger"
                      (click)="removeInputFieldData(i, j)"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              pButton
              type="submit"
              label="Add Submit Point"
              icon="pi pi-plus"
              class="mt-4 mb-4 w-full"
              [loading]="loading"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
