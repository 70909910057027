import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  subject = new Subject<any>();
  private cartItemsSubject = new BehaviorSubject<any[]>([]);
  cartItems$ = this.cartItemsSubject.asObservable();

  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) { }

  sendMsg(product: any) {
    this.subject.next(product);
  }

  getMsg() {
    return this.subject.asObservable();
  }

  updateCartItems(items: any[]) {
    this.cartItemsSubject.next(items);
  }
}
