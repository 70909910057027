import { 
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef, 
} from '@angular/core';
import { Role } from '../_models/role';

@Directive({
  selector: '[hasRoles]'
})
export class HasRoleDirective implements OnChanges {

  private visible: boolean = false;
  private roles: Role[] = [];
  private user: any;

  @Input() set hasRoles(roles: Role[]) {
    this.roles = roles || [];
    this.updateView();
  }

  @Input() set hasRolesFor(user: any) {
    this.user = user;
    this.updateView();
  }
  
  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateView();
  }

  private updateView(): void {
    if (!this.user || !this.user.role || !this.user.role.name) {
      this.viewContainer.clear();
      this.visible = false;
      return;
    }
  
    const userRole = this.user.role.name;
  
    if (this.roles.includes(userRole)) {
      if (!this.visible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.visible = true;
      }
    } else {
      if (this.visible) {
        this.viewContainer.clear();
        this.visible = false;
      }
    }
  }
}