import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
})
export class RegisterUserComponent implements OnInit {
  roles: any[] = [];
  customers: any[] = [];
  selectedCustomer: any;
  submitted: boolean = false;
  loading: boolean = false;
  selectedRole: any;
  webhook: any;
  user: any;
  userRole: any;
  disabled: boolean = false;

  constructor(
    public userService: UserService,
    private customerService: CustomerService,
    public location: Location,
    public ref: DynamicDialogRef,
    private messageService: MessageService,
    private SwitchWebhookService: SwitchWebhookService
  ) {}

  ngOnInit(): void {


    this.userService.userForm.reset();

    this.userService.getRoles().subscribe((roles: any) => {
      this.roles = roles;
      this.user = this.userService.currentUser$.subscribe((user: any) => {
        this.userRole = user?.role?.name;
        this.user = user;
        if (this.user.role.name === 'admin-switch') {
          this.disabled = true;
          this.selectedRole = this.roles.find(
            (role) => role.id === 14
          )?.id;
          
          
        }
        
      });
    });

    

    this.customerService.getAllCustomers().subscribe((customers) => {
      this.customers = customers;
    });
  }

  get f() {
    return this.userService.userForm.controls;
  }

  onChange(event: any) {
    console.log(event);
    console.log(this.selectedRole);
    
  }

  onSubmitUserForm() {
    console.log(this.userService.userForm.value.webhook);

    this.loading = true;
    this.submitted = true;
    if (this.userService.userForm.invalid) {
      this.loading = false;
      return;
    }

    if (this.userService.userForm.valid) {
      this.userService.postUser(this.userService.userForm.value).subscribe(
        (res) => {
          if (this.userService.userForm.value.webhook) {
            let webhookObject = {
              user_id: res.id,
              base_url: this.userService.userForm.value.webhook,
            };

            this.SwitchWebhookService.createBaseUrl(webhookObject).subscribe(
              (res) => {}
            );
          }

          this.userService.getAll().subscribe(
            (users) => {
              this.userService.updateMembers(users);
            },
            (error: any) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'User Creation Failed',
                key: 'register',
              });
            }
          );
          this.loading = false;
          this.userService.userForm.reset();
          this.ref.close(res);
        },
        (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'User Creation Failed',
            key: 'register',
          });
        }
      );
    }
  }
}
