import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ReviewDialogComponent } from '../../review-dialog/review-dialog.component';

@Component({
  selector: 'app-view-bulk-orders',
  templateUrl: './view-bulk-orders.component.html',
  styleUrls: ['./view-bulk-orders.component.scss']
})
export class ViewBulkOrdersComponent implements OnInit {
  bulkOrders: any[] = [];
  @ViewChild('dt') dt: Table | undefined;
  isLoading: boolean = true;
  
  columns = [
    { field: 'order_type', header: 'Order Type' },
    { field: 'purchase_order', header: 'Purchase Order' },
    { field: 'order_number', header: 'Order Number' },
  ];

  constructor(
    private bulkOrderFormService: BulkOrderFormService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getBulkOrders();
    
  }

  getBulkOrders() {
    this.bulkOrderFormService.getBulkOrders().subscribe(
      (response: any) => {
        this.bulkOrders = response.body;
        console.log(this.bulkOrders);
        
      },
      (error) => {
        console.log(error);
      }
    );
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.columns.length;
  }

  openDialog(order: any, isReview: boolean) {
    const ref = this.dialogService.open(ReviewDialogComponent, {
      data: {
        order,
        isReview,
      },
      header: 'View Bulk Order',
      width: '70%',
      contentStyle: { 'max-height': '350px', overflow: 'auto' },
    });
  }

}
