import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/_models/role';
import { RoleService } from 'src/app/_services/role.service';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent implements OnInit {
  submitted: boolean = false;
  roleData: any; // A variable to store user data
  members$: Subject<any> = new Subject<any>();
  loadingData: boolean = true;
  errorLoadingData: boolean = false;
  loading: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public roleService: RoleService,
    public location: Location,
    private router: Router,
  ) { }

  ngOnInit() {
    const role = this.config.data.role;


    this.roleService.getRole().subscribe(
      (roles) => {
        this.loadingData = false;
        this.roleData = roles.find((r) => r.id === role.id)
      },
      (error) => {
        this.loadingData = false;
        this.errorLoadingData = true;
      });
  }

  roles: any[] = [];

  get f() {
    return this.roleService.roleForm.controls;
  
  }

  updateRole(id: number) {
    this.loading = true;
    this.submitted = true;
    const roleFormValue = this.roleService.roleForm.value;
  
    // Check if all required fields are present and not empty
    if (!roleFormValue.name ||
        !roleFormValue.description) {
      // Display an error message or prevent the update request
      this.loading = false;
      return;
    }
    
    // If all required fields are filled, proceed with the update request
    this.roleService.updateRole(id, roleFormValue).subscribe((response) => {
      this.ref.close(response);
      this.loading = false;
      
      this.roleService.getRole().subscribe((roles) => {
        this.roleService.updateRoles(roles)
        })
        });
    
  }


}
