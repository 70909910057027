import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/_services';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-switch-webhook-dialog',
  templateUrl: './switch-webhook-dialog.component.html',
  styleUrls: ['./switch-webhook-dialog.component.scss'],
})
export class SwitchWebhookDialogComponent implements OnInit {
  data: any;
  dropdownFields: any[] = [];
  textFields: any[] = [];
  numberFields: any[] = [];
  dateFields: any[] = [];
  emailFields: any[] = [];
  fileFields: any[] = [];
  loading: boolean = false;
  selectedFile: any;
  uploadedFile: number = 0;
  form: FormGroup;
  userId: any;
  allFields: any[] = [];

  constructor(
    public ref: DynamicDialogRef,
    private switchWebhookService: SwitchWebhookService,
    public config: DynamicDialogConfig,
    private userService: UserService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {
    this.form = this.fb.group({
      fields: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.data = this.config.data;

    this.userService.currentUser$.subscribe((user) => {
      this.userId = user.id;
    });
    this.switchWebhookService
      .getWebhookFields(this.data.id)
      .subscribe((data: any) => {
        

        this.allFields = data.body;

        this.populateFormFields();
      });
  }

  populateFormFields() {
    const formGroups = this.allFields.map((field: any) => {
      return this.fb.group({
        [field.field_name]: [''], // Initialize form control for the field type
      });
    });

    this.form = this.fb.group({
      fields: this.fb.array(formGroups),
    });
  }

  get fields(): FormArray {
    return this.form.get('fields') as FormArray;
  }

  onFileSelect(event: any, index: number) {
    const file = event.files[0]; // Get the first selected file
    // Make sure the index is valid and the fields form array is initialized
    if (index >= 0 && index < this.fields.length) {
      this.fields.at(index).get('files')?.setValue(file); // Use optional chaining to avoid errors
    }
  }

  // Form submission handler
  submitForm() {
    this.loading = true;
    const formValue = this.form.value;
    const consolidatedData: { [key: string]: any } = {};

    // Prepare data for submission if form is valid
    if (this.form.invalid) {
      this.loading = false;
      return;
    }

    formValue.fields.forEach((field: any, index: number) => {
      for (const key in field) {
        if (field.hasOwnProperty(key) && field[key] !== '') {
          const uniqueKey = `${key}_${index + 1}`; // Create a unique key like 'text_1', 'text_2', etc.
          consolidatedData[uniqueKey] = field[key];
        }
      }
    });

    // Remove the `files` key if no file fields are present or contain data
    let hasFiles = false;
    this.fields.controls.forEach((field) => {
      const fileControl = field.get('files');
      if (fileControl && fileControl.value) {
        hasFiles = true;
      }
    });

    if (!hasFiles) {
      // Remove 'files' if it exists and is empty
      for (const key in consolidatedData) {
        if (key.startsWith('files') && !consolidatedData[key]) {
          delete consolidatedData[key];
        }
      }
    }

    const webhookId = this.data.id;

    // Prepare submission data
    const submissionData = {
      user_id: this.userId,
      webhook_id: webhookId,
      webhook_url: this.data.webhook_url,
      submission_data: [consolidatedData],
    };

    

    // Convert form data to FormData for file handling
    const formData = new FormData();
    formData.append('user_id', this.userId);
    formData.append('webhook_id', webhookId);
    formData.append('submission_data', JSON.stringify(submissionData));

    // Add files to FormData
    this.fields.controls.forEach((field, index) => {
      const fileControl = field.get('files');
      if (fileControl && fileControl.value) {
        formData.append(`files`, fileControl.value);
      }
    });

    

    // Call service to submit form
    this.switchWebhookService
      .postWebhookSubmission(this.userId, webhookId, formData)
      .subscribe({
        next: (response) => {
          
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Webhook submission successful',
          });
          this.loading = false;
          this.ref.close(); // Close dialog on success
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Webhook submission failed',
          });
          this.loading = false;
          console.error('Submission failed:', error);
        },
      });
  }
}
