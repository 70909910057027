import { Component, OnInit } from '@angular/core';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';

@Component({
  selector: 'app-review-bulk-orders',
  templateUrl: './review-bulk-orders.component.html',
  styleUrls: ['./review-bulk-orders.component.scss'],
})
export class ReviewBulkOrdersComponent implements OnInit {
  orders$ = this.bulkOrderFormService.orders$;
  isLoading: boolean = true;

  columns = [
    { field: 'order_type', header: 'Order Type' },
    { field: 'order_number', header: 'Order Number' },
    { field: 'purchase_order', header: 'Purchase Order' },
  ];

  constructor(
    private bulkOrderFormService: BulkOrderFormService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.bulkOrderFormService.getBulkOrderActions().subscribe((res: any) => {
      this.bulkOrderFormService.updateOrders(res.body);
      this.isLoading = false;
    });
  }

  openDialog(order: any, isReview: boolean) {
    const ref = this.dialogService.open(ReviewDialogComponent, {
      data: {
        order,
        isReview,
      },
      header: 'Review Bulk Order',
      width: '70%',
      contentStyle: { 'max-height': '350px', overflow: 'auto' },
    });
  }

  getNumberofColumns() {
    return this.columns.length + 1;
  }
}
