import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  Form,
} from '@angular/forms';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { UserService } from 'src/app/_services';
import { RoleService } from 'src/app/_services/role.service';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-switch-webhook',
  templateUrl: './switch-webhook.component.html',
  styleUrls: ['./switch-webhook.component.scss'],
})
export class SwitchWebhookComponent implements OnInit {
  switch_users: any[] = [];
  userRole: any;
  selectedUser: any;
  loading: boolean = false;
  webhook: any;
  submitted: boolean = false;
  input_data: any;
  switch_form!: FormGroup;
  user_id: any;
  switch_fields: any[] = [];
  field_data: any[] = [];

  constructor(
    private fb: FormBuilder,
    private switchWebhookService: SwitchWebhookService,
    private userService: UserService,
    private roleService: RoleService,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.userService.currentUser$.subscribe((user) => {
      this.userRole = user?.role?.name;
      this.user_id = user.id;
    });

    setTimeout(() => {
      this.switchWebhookService.getAdminSwitchCustomers(this.user_id).subscribe(
        (data: any) => {
          for (let users of data.body) {
            this.userService
              .getUserById(users.user_id)
              .subscribe((res: any) => {
                this.switch_users.push(res);
              });
          }
        },
        (error) => {}
      );
    }, 2000);

    // this.switchWebhookService.getUserWebhooks().subscribe((data: any) => {
    //   this.switch_users = data.body;
    // });

    this.switchWebhookService.getFieldTypes().subscribe((data: any) => {
      this.switch_fields = data.body;
    });

    this.switch_form = this.fb.group({
      user_id: new FormControl('', Validators.required),
      webhook_url: new FormControl('', Validators.required),
      webhook_name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      fields: this.fb.array([]),
    });

    this.addInputField();
  }

  get fieldArray() {
    return this.switch_form.get('fields') as FormArray;
  }

  // Add a parent group with nested children form array
  newInputField(): FormGroup {
    return this.fb.group({
      field_name: ['', Validators.required],
      field_title: ['', Validators.required],
      field_data: this.fb.array([]), // Ensure this is a FormArray
    });
  }

  addInputField() {
    this.fieldArray.push(this.newInputField());
  }

  removeInputField(index: number) {
    this.fieldArray.removeAt(index);
  }

  inputFieldData(index: number): FormArray {
    return this.fieldArray.at(index).get('field_data') as FormArray;
  }

  newInputFieldData(): FormControl {
    return this.fb.control('', Validators.required);
  }

  addInputFieldData(index: number) {
    this.inputFieldData(index).push(this.newInputFieldData());
  }

  removeInputFieldData(fieldIndex: number, dataIndex: number) {
    this.inputFieldData(fieldIndex).removeAt(dataIndex);
  }

  onDropdownChange(e: any, index: number) {
    if (e.value === 'dropdown') {
      this.addInputFieldData(index);
    } else {
      this.inputFieldData(index).clear();
    }
  }

  dropdownChange(e: any) {
    this.switchWebhookService
      .getBaseUrlById(this.selectedUser.id)
      .subscribe((data: any) => {
        this.webhook = data.body.base_url;
      });
  }

  submitWebhook() {
    this.submitted = true;

    this.loading = true;
    if (this.switch_form.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all fields',
      });
      this.loading = false;
      return;
    }
    this.switch_form.value.user_id = this.selectedUser.id;
    // include the webhook inside the webhook form value
    this.switch_form.value.webhook_url =
      this.webhook + this.switch_form.value.webhook_url;

    this.switchWebhookService
      .createSwitchWebhook(this.switch_form.value)
      .subscribe(
        (data) => {},
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Webhook creation failed',
          });
          this.loading = false;
        },
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Webhook successfully created',
          });
          this.loading = false;
          this.switch_form.reset();
          this.submitted = false;
          this.webhook = '';
          // remove field_data from form
          this.fieldArray.clear();
          this.addInputField();

          //remove all input filed data
        }
      );
  }
}
