import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-bulk-order-dialog',
  templateUrl: './bulk-order-dialog.component.html',
  styleUrls: ['./bulk-order-dialog.component.scss'],
})
export class BulkOrderDialogComponent implements OnInit {
  data: any;
  loading: boolean = false;
  row_count: number = 0;
  user: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private bulkOrderFormService: BulkOrderFormService,
    private messageService: MessageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;
    this.data.order_type = this.data.order_type.value;
    console.log(this.data);
    this.userService.getMe().subscribe((response: any) => {
      this.user = response;
      this.data['username'] = this.user.username;
    });

    this.row_count = this.data.orders.length;
    
  }

  columns = [
    { field: 'refering_doctor', header: 'Refering Doctor' },
    { field: 'mnem', header: 'Mnem' },
    { field: 'copy_doctor_1', header: 'Copy Doctor 1' },
    { field: 'copy_doctor_2', header: 'Copy Doctor 2' },
    { field: 'copy_doctor_3', header: 'Copy Doctor 3' },
    { field: 'hospital_ward', header: 'Hospital Ward' },
    { field: 'quantity', header: 'Quantity' },
    { field: 'size', header: 'Size' },
    { field: 'biocross', header: 'Biocross' },
  ];

  submitOrder() {
    this.loading = true;
    
    console.log(this.data);
    
    
    this.bulkOrderFormService.createBulkOrder(this.data).subscribe(
      (response: any) => {
        this.bulkOrderFormService.getBulkOrderActionCount().subscribe((data) => {
          this.bulkOrderFormService.updateReviewCount(data.body);
        });
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `Error submitting order: ${error.message}`,
          key: 'bulk-order-dialog',
        });
      },
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Order submitted successfully',
          key: 'bulk-order-dialog',
        });
        this.ref.close(this.data);
      }
    );
  }

  closeDialog() {
    this.ref.close();
  }
}
