import { Component, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProductsService } from 'src/app/_services/products.service';
import { QuotesService } from 'src/app/_services/quotes.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CartService } from 'src/app/_services/cart.service';
import { UserService } from 'src/app/_services';
import { Role } from 'src/app/_models/role';

interface Product {
  product_name: string;
}

interface Colors {
  name: string;
}

@Component({
  selector: 'app-generate-quote',
  templateUrl: './generate-quote.component.html',
  styleUrls: ['./generate-quote.component.scss'],
})
export class GenerateQuoteComponent implements OnInit {
  public get userService(): UserService {
    return this._userService;
  }
  public set userService(value: UserService) {
    this._userService = value;
  }
  value: number = 0;
  businessCardImages: string[] = [
    'assets/images/Business_Card_1.jpg',
    'assets/images/Business_Card_2.jpg',
  ];
  flyersImages: string[] = [
    'assets/images/flyer_mockup_1.jpg',
    'assets/images/flyer_mockup_2.jpg',
  ];
  postersImages: string[] = [
    'assets/images/Poster_Mockup_1.jpg',
    'assets/images/Poster_Mockup_2.jpg',
  ];
  images2: string[] = [
    'assets/images/example-image.png',
    'assets/images/example-image.png',
  ];
  selectedImageIndex2: number = 0;
  selectedProduct: any = {
    product_name: '',
    product_parts: [],
    product_sizes: [],
  };
  productList: Product[] = [];
  selectedPageSize: any;
  selectedPaperCode: any;
  loading: boolean = false;
  price: number = 0.0;
  savedQuote: any;
  quoteObject: any = {};
  productColors: Colors[] = [];
  selectedColor: any;
  productParts: any;
  pageSizes: any;
  currentUser: any;
  acceptedQuote: boolean = false;
  choice: boolean = true;
  vat: number = 0.0;
  totalPrice: number = 0.0;
  roles: typeof Role = Role;
  saleQuoteId: any;
  disabled: boolean = false;
  selectedImage: string | null = null;

  constructor(
    public quotesService: QuotesService,
    public productsService: ProductsService,
    public messageService: MessageService,
    public router: Router,
    public location: Location,
    public cartService: CartService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.getMe().subscribe((user) => {
      this.currentUser = user;
    });

    this.getProductsOptionList();

    this.productColors = [
      { name: 'Full Color single side' },
      { name: 'Full Color both sides' },
    ];
  }

  filterProductParts() {
    if (
      this.pageSizes === 'A0' ||
      this.pageSizes === 'A1' ||
      this.pageSizes === 'A2' ||
      this.pageSizes === 'A4'
    ) {
      this.productParts = this.selectedProduct.product_parts.filter(
        (part: any) => part.external_product_part_id === 230
      );
    } else if (this.pageSizes === 'A3') {
      this.productParts = this.selectedProduct.product_parts.filter(
        (part: any) => part.external_product_part_id === 232
      );
    }
  }

  onSizeChange(event: any) {
    this.pageSizes = this.selectedPageSize.code;
    this.filterProductParts();
  }

  rolesForArtworkUpload = {
    rolesWithAccess: [Role.Admin, Role.Customer_PIFSA],
  };

  getProductsOptionList() {
    return this.productsService
      .getAllProducts()
      .subscribe((products: any[]) => {
        this.productList = products.flatMap((product) => ({
          id: product.id,
          description: product.description,
          external_product_id: product.external_product_id,
          product_name: product.product_name,
          product_parts: product.product_parts.map((part: any) => ({
            id: part.id,
            name: part.name,
            external_product_part_id: part.external_product_part_id,
            default_paper_code: part.default_paper_code,
            description: part.description,
            front_process: part.front_process,
            back_process: part.back_process,
          })),
          product_sizes: product.product_sizes.map((size: any) => ({
            code: size.code,
            description: size.description,
            height_mm: size.height_mm,
            id: size.id,
            size_name: size.name,
            width_mm: size.width_mm,
          })),
        }));
        this.productsService.clickedProduct$.subscribe((product) => {
          this.productList.forEach((element) => {
            if (element.product_name === product.product_name) {
              this.selectedProduct = element;
              if (this.selectedProduct.product_name === 'Posters') {
                this.productParts = this.selectedProduct.product_parts.filter(
                  (part: any) => part.external_product_part_id === 230
                );
              }
            }
          });
        });
      });
  }

  onQuoteAccept() {
    this.disabled = true;

    const otherData = {
      price: this.price,
      vat: this.vat,
      total_price: this.totalPrice,
      currentUser: this.currentUser,
      product_id: this.selectedProduct.id,
    };

    const data = {
      quote: this.quoteObject.quote,
      external_quote: this.quoteObject.external_quote,
      metadata: otherData,
    };

    this.quotesService
      .sendQuoteEmail(this.currentUser.email, [data])
      .subscribe((response: any) => {});
    this.loading = true;
    this.quoteObject.external_quote.quote_status = 'ACCEPTED';
    this.quotesService.addQuote(this.quoteObject.external_quote).subscribe(
      (response: any) => {
        this.saleQuoteId = response.body.sales_quote_id;
      },
      (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save quote',
        });
      },
      () => {
        this.loading = false;
        this.acceptedQuote = true;
        this.choice = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Quote saved successfully',
        });
      }
    );
  }

  onQuoteDecline() {
    this.loading = true;
    this.quoteObject.external_quote.quote_status = 'DECLINED';
    this.quotesService.addQuote(this.quoteObject.external_quote).subscribe(
      (response: any) => {},
      (error) => {
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.acceptedQuote = false;
        this.price = 0.0;
      }
    );
  }

  // TODO make this work with the new dropdown menu system

  onSubmitQuoteRequest() {
    this.acceptedQuote = false;
    this.choice = true;
    this.price = 0.0;
    this.loading = true;

    const name = 'product_sizes';
    const parts = 'product_parts';
    const { [name]: removedProperty, ...productForQuote } =
      this.selectedProduct;
    const { [parts]: removedProperty2, ...productForQuote2 } = productForQuote;

    if (this.selectedProduct.product_name == 'Posters') {
      this.selectedColor.name = 'Full Color single side';
    }
    if (this.selectedColor.name == 'Full Color single side') {
      this.selectedPaperCode.front_process = '4';
      this.selectedPaperCode.back_process = '0';
    } else if (this.selectedColor.name == 'Full Color both sides') {
      this.selectedPaperCode.front_process = '4';
      this.selectedPaperCode.back_process = '4';
    } else if (this.selectedProduct.product_name == 'Posters') {
      this.selectedPaperCode.front_process = '4';
      this.selectedPaperCode.back_process = '0';
    }

    if (this.value == 0) {
      this.loading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select a quantity',
      });
      return;
    }

    var quote = {
      product: productForQuote2,
      quantity: this.value,
      page_size: this.selectedPageSize,
      product_parts: this.selectedPaperCode,
      customer_code: this.currentUser.customer.code,
    };

    this.quotesService.createNewQuote(quote).subscribe((response: any) => {
      if (response.body.external_quote.quote_status == 'FAILED') {
        console.log(response.body.external_quote);
        
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed retrieving quote',
        });
      } else {
        this.loading = false;
        this.price = response.body.external_quote.quote_total;
        this.vat = this.price * 0.15;
        this.totalPrice = this.price + this.vat;
        //round of the total price to 2 decimal places
        this.totalPrice =
          Math.round((this.totalPrice + Number.EPSILON) * 100) / 100;
        response.body.external_quote.quote_total = this.totalPrice;
        this.quoteObject = {
          external_quote: response.body.external_quote,
          quote: response.body.quote,
        };
        this.quoteObject.external_quote['selected_product_id'] =
          this.selectedProduct.id;
      }
    });
  }

  onSubmitQuoteSave() {
    if (this.selectedProduct.product_name === 'Posters') {
      this.selectedImage = this.postersImages[0];
    } else if (this.selectedProduct.product_name === 'Flyers') {
      this.selectedImage = this.flyersImages[0];
    } else if (this.selectedProduct.product_name === 'Business Cards') {
      this.selectedImage = this.businessCardImages[0];
    }

    let cart: {
      product_name: any;
      description: any;
      price: any;
      quantity: any;
      selectedImage: any;
    } = {
      product_name: this.selectedProduct.product_name,
      description: this.selectedProduct.description,
      price: this.price,
      quantity: this.value,
      selectedImage: this.selectedImage,
    };

    const savedProduct = localStorage.getItem('sendToCart');
    if (savedProduct != null) {
      const cartItems = JSON.parse(savedProduct);
      cartItems.push(cart);
      localStorage.setItem('sendToCart', JSON.stringify(cartItems));
      this.cartService.updateCartItems(cartItems);
    } else {
      const cartItems = [];
      cartItems.push(cart);
      localStorage.setItem('sendToCart', JSON.stringify(cartItems));
      this.cartService.updateCartItems(cartItems);
    }

    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Item added to your cart!',
    });

    this.price = 0.0;
    this.loading = false;
  }
}
