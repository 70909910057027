<p-toast key="fileUpload">

</p-toast>
<div class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card">
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Upload Artwork</h2>
  </div>
</div>
<div class="px-4 py-8 md:px-6 lg:px-8">
  <p-toast></p-toast>
  <div class="grid mb-7">
    <div class="col-6 lg:col-6">

      <div class="font-bold text-900 mb-3">Upload File </div>
      <div class="mb-5">
        
        <!-- TODO show the first size of the selected products -->
        <p-fileUpload
          mode="advanced"
          chooseLabel="Choose"
          [showUploadButton]="false"
          accept="image/*"
          [auto]="false"
          accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          [customUpload]="true"
          (onSelect)="onFileSelect($event)"
          [maxFileSize]="200000000"
        >
          <ng-template let-file pTemplate="file">
            <div *ngIf="uploadedFile > 0">
              {{ file.name }} -
              {{ file.size / 1024 / 1024 | number : "1.2-2" }}MB
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
      <form [formGroup]="fileForm">
        <div  class="font-bold text-900 mb-3">Intent</div>
        <div class="mb-5">
          <!-- TODO show the first size of the selected products -->
          <p-dropdown
            [options]="IntentOptions"
            [(ngModel)]="selectedIntent"
            styleClass="w-full"
            placeholder="Select Intent"
            (onChange)="onIntentChange()"
            formControlName="intent"
          ></p-dropdown>
        </div>

        <div *ngIf="selectedIntent == 'quote' && !quote_id"> 
          <div class="font-bold text-900 mb-3">Quotes</div>
          <div class="mb-5">
            <!-- TODO show the first size of the selected products -->
            <p-dropdown
              inputId="quote"
              [options]="quotes"
              optionLabel="quote_number"
              optionValue="id"
              [showClear]="true"
              placeholder="Select a Quote"
              [(ngModel)]="selectedQuote"
              formControlName="quote_id"
              [virtualScroll]="true"
              [virtualScrollItemSize]="38"
              [filter]="true"
              filterBy="quote_number"
              styleClass="w-full"
              (onChange)="onQuoteSelect()"
            >
            </p-dropdown>
          </div>
        </div>

        <div *ngIf="selectedIntent == 'imposition'">
          <div class="font-bold text-900 mb-3">Imposition Type</div>
          <div class="mb-5">
            <!-- TODO show the first size of the selected products -->
            <p-dropdown
              [options]="ImpoTypeOptions"
              [(ngModel)]="selectedImpoType"
              styleClass="w-full"
              formControlName="impo_type"
            ></p-dropdown>
          </div>
        </div>

        <div *ngIf="selectedIntent == 'imposition'">
          <div class="font-bold text-900 mb-3">Digital Press Sheet</div>
          <div class="mb-5">
            <p-dropdown
              [options]="DigitalPressSheet"
              [(ngModel)]="selectedDigitalPress"
              styleClass="w-full"
              formControlName="impo_digital_press_sheet"
            ></p-dropdown>
          </div>
        </div>

        <div *ngIf="selectedIntent == 'imposition'">
          <div class="font-bold text-900 mb-3">Sides</div>
          <div class="mb-5">
            <p-dropdown
              [options]="Sides"
              [(ngModel)]="selectedSides"
              styleClass="w-full"
              formControlName="impo_sides"
            ></p-dropdown>
          </div>
        </div>

        <div *ngIf="selectedIntent == 'imposition'">
          <div class="font-bold text-900 mb-3">Double Cut</div>
          <div class="mb-5">
            <p-dropdown
              [options]="DoubleCut"
              [(ngModel)]="selectedDoubleCut"
              styleClass="w-full"
              formControlName="impo_double_cut"
            ></p-dropdown>
          </div>
        </div>
      </form>

      <div class="flex align-items-center flex-1 mt-3 sm:mt-0 ml-0">
        <button
          pButton
          pRipple
          [disabled]="loading"
          label="Upload"
          class="flex-1"
          icon="pi pi-upload"
          [loading]="loading"
          (click)="onSubmitArtwork()"
        ></button>
      </div>
    </div>
    
  </div>

 
</div>
