import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { RegisterUserComponent } from '../register-user/register-user.component';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { UpdateUserComponent } from '../update-user/update-user.component';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-admin-switch',
  templateUrl: './admin-switch.component.html',
  styleUrls: ['./admin-switch.component.scss'],
})
export class AdminSwitchComponent implements OnInit {
  selectedMember: any;
  selectedRole: any;
  value1: boolean = false;
  members: any;
  members$ = this.switchWebhookService.currentSwitchUsers;
  ref: any;
  selectedRow: any;
  roles: any;
  currentUser = this.userService.currentUser$.value;
  loading: boolean = false;
  @ViewChild('dt') dt: Table | undefined;
  isLoadingUsers: boolean = true;
  userId: any;
  users: any[] = [];

  constructor(
    private switchWebhookService: SwitchWebhookService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.currentUser$.subscribe((user) => {
      this.userId = user.id;
      
    });

    // this.switchWebhookService.getSwitchUsers().subscribe((res: any) => {
      
    
      
    //   this.switchWebhookService.changeSwitchUsers(res.body);
    //   this.isLoadingUsers = false;
    // });

    setTimeout(() => {
      this.switchWebhookService
        .getAdminSwitchCustomers(this.userId)
        .subscribe((res: any) => {
          for(let user of res.body){
            this.userService.getUserById(user.user_id).subscribe((res: any) => {
              
              this.users.push(res)
            });
          }
      
      this.switchWebhookService.changeSwitchUsers(this.users);
      this.isLoadingUsers = false;
      
        });
    }, 2000);
  }

  contextMenuUsers: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        this.updateUser(this.selectedMember);
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-times',
      command: () => {
        this.confirmUserContextMenu();
      },
    },
  ];

  items: MenuItem[] = [
    {
      label: 'Options',
      items: [
        {
          label: 'Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.updateUser(this.selectedRow);
          },
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            this.confirmUser();
          },
        },
      ],
    },
  ];

  showUserRegisterDialog() {
    this.ref = this.dialogService.open(RegisterUserComponent, {
      data: {
        formdata: null,
      },
      header: 'Register a User',
      width: '70%',
      height: '70%',
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((res: any) => {
      if (!res) return;
      this.switchWebhookService.getAdminSwitchCustomers(this.userId).subscribe(
        (users) => {
          
          if(users.body.length === 0){
            this.users = [];
            this.switchWebhookService.changeSwitchUsers(this.users);
          }else{
            for(let user of users.body){
              this.userService.getUserById(user.user_id).subscribe((res: any) => {
                // remove all entries in the users array
                this.users = [];
                this.users.push(res)
                
                
                this.switchWebhookService.changeSwitchUsers(this.users);
              });

            }
          }
          
        },
        (error: any) => {
          // Display an error message
        }
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'User Created Successfully',
      });
    });
  }

  showUserDetailsDialog(rowData: any, event: Event) {
    // Check if the target of the click event was the ellipsis button
    if ((event.target as HTMLElement).classList.contains('pi-ellipsis-v')) {
      // Clicked on the ellipsis button, don't open the dialog
      return;
    } else {
      // Clicked on the row, open the dialog
      const ref = this.dialogService.open(UserDetailsComponent, {
        data: {
          user: rowData,
        },
        header: 'User Details',
        width: '70%',
      });
    }
  }

  async updateUser(rowData: any) {
    const ref = await this.dialogService
      .open(UpdateUserComponent, {
        data: {
          user: rowData,
        },
        header: 'Client Details',
        width: '70%',
      })
      .onClose.toPromise();

    this.ref.onClose.subscribe((res: any) => {
      if (!res) return;
      this.switchWebhookService.getAdminSwitchCustomers(this.userId).subscribe(
        (users) => {
          
          if(users.body.length === 0){
            this.users = [];
            this.switchWebhookService.changeSwitchUsers(this.users);
          }else{
            for(let user of users.body){
              this.userService.getUserById(user.user_id).subscribe((res: any) => {
                // remove all entries in the users array
                this.users = [];
                this.users.push(res)
                
                
                this.switchWebhookService.changeSwitchUsers(this.users);
              });

            }
          }
          
        },
        (error: any) => {
          // Display an error message
        }
      );
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'User Updated Successfully',
      });
    });
  }

  storeRowDetail(details: any) {
    this.selectedRow = details;
  }

  confirmUserContextMenu() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.userService.deleteUser(this.selectedMember.id).subscribe((res) => {
          this.switchWebhookService.getAdminSwitchCustomers(this.userId).subscribe(
            (users) => {
              for(let user of users){
                this.userService.getUserById(user.user_id).subscribe((res: any) => {
                  this.users.push(res)
                });

              }
              this.switchWebhookService.changeSwitchUsers(this.users);
            },
            (error: any) => {
              // Display an error message
            }
          );
        });
      },

      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              severity: 'error',
              summary: 'Rejected',
              detail: 'You have rejected',
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }

  confirmUser() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this user?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',

      accept: () => {
        this.userService.deleteUser(this.selectedRow.id).subscribe((res) => {
          this.switchWebhookService.getAdminSwitchCustomers(this.userId).subscribe(
            (users) => {
              
              if(users.body.length === 0){
                this.users = [];
                this.switchWebhookService.changeSwitchUsers(this.users);
              }else{
                for(let user of users.body){
                  this.userService.getUserById(user.user_id).subscribe((res: any) => {
                    // remove all entries in the users array
                    this.users = [];
                    this.users.push(res)
                    
                    
                    this.switchWebhookService.changeSwitchUsers(this.users);
                  });
  
                }
              }
              
            },
            (error: any) => {
              // Display an error message
            }
          );
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Record deleted',
            key: 'register',
          });
        });
      },

      reject: (type: ConfirmEventType) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              severity: 'error',
              summary: 'Rejected',
              detail: 'You have rejected',
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }

  userColumns = [
    { field: 'username', header: 'Username' },
    { field: 'email', header: 'Email' },
  ];

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.userColumns.length + 1;
  }
}
