<div class="max-w-full">
  <div class="flex flex-column lg:flex-row">
    <div class="shadow-2 m-2 w-full">
      <div class="flex flex-column p-4 ">
        <div>
          <h1>View Orders</h1>
        </div>
        <div>
          <span>View all orders and their details</span>
        </div>
      </div>
      <div class="m-4">
        <button
          pButton
          type="button"
          label="View"
          [routerLink]="['/view-bulk-orders']"
        ></button>
      </div>
    </div>
    <div class="shadow-2 m-2 w-full">
      <div class="flex flex-column p-4 ">
        <div>
          <h1>View Rejected Orders</h1>
        </div>
        <div>
          <span>View all rejected orders</span>
          <div *ngIf="rejectedCount > 0; else showRejectedItems" class="flex flex-row">
            <div class="mt-4">
              <span>Rejected Orders:</span>
            </div>
            <div
              class="m-3 font-bold bg-red-600 text-white w-2rem h-2rem border-circle flex align-items-center justify-content-center"
            >
              <span> {{ rejectedCount }}</span>
            </div>
          </div>
          <ng-template #showRejectedItems>
            <div class="mt-4">
                <span>There are no rejected orders</span>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="m-4">
        <button
          pButton
          type="button"
          label="View"
          [routerLink]="['/rejected-bulk-orders']"
        ></button>
      </div>
    </div>
    <div class="shadow-2 m-2 w-full">
      <div class="flex flex-column p-4">
        <div>
          <h1>Orders For Review</h1>
        </div>
        <div>
          <span>Current orders which need reviewing</span>
          <div *ngIf="bulkOrderCount > 0; else showItems" class="flex flex-row">
            <div class="mt-4">
              <span>Orders need reviewing:</span>
            </div>
            <div
              class="m-3 font-bold bg-red-600 text-white w-2rem h-2rem border-circle flex align-items-center justify-content-center"
            >
              <span> {{ bulkOrderCount }}</span>
            </div>
          </div>
          <ng-template #showItems>
            <div class="mt-4">
                <span>There are no orders to review</span>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="m-4">
        <button
          pButton
          type="button"
          label="View"
          [routerLink]="['/review-bulk-orders']"
        ></button>
      </div>
    </div>
  </div>
</div>
