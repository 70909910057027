export enum Role {
  Admin = "admin",
  Customer_ZPL = "customer-zpl",
  Basic_ZPL = "basic-zpl",
  Customer_PIFSA = "customer-pifsa",
  Basic_BulkOrders = "basic-bulkorders",
  Customer_Switch = "customer-switch",
  Admin_Switch = "admin-switch",
  Review_BulkOrders = "review-bulkorders",
}

export interface IRole {
  id: number;
  name: Role;
  description: string;
}