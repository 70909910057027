import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private filesSubject = new BehaviorSubject<any[]>([]);
  files$ = this.filesSubject.asObservable();
  file_submission_name: any;

  constructor(private api: ApiService) {}

  uploadFile(formData: FormData) {
    return this.api.postAPI(`files/upload-file`, formData);
  }

  getFiles() {
    return this.api.getAPI('files/get-files');
  }

  downloadSubmissionsFile(artwork_id: number) {
    

    this.api
      .getFileAPI(`submissions/download-file-submission`, `${artwork_id}`)
      .subscribe((response: any) => {
        let contentDispositionHeader = response.headers.get(
          'Content-Disposition'
        );
        let fileName = '';

        if (contentDispositionHeader) {
          let matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches.legnth > 1) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        this.file_submission_name = fileName;

        const blob: Blob = response.body as Blob;
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      });
  }

  downloadFile(file_id: string) {
    this.api
      .getFileAPI('files/download-file', `${file_id}`)
      .subscribe((response: any) => {
        const contentDispositionHeader = response.headers.get(
          'Content-Disposition'
        );
        const contentType = response.headers.get('Content-Type');
        let fileName = 'downloaded_file';

        if (contentDispositionHeader) {
          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches.length > 1) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        // If the fileName has no extension, infer it from the Content-Type
        if (!fileName.includes('.') && contentType) {
          const extension = contentType.split('/')[1]; // e.g., "application/pdf" -> "pdf"
          fileName += `.${extension}`;
        }

        const blob: Blob = response.body as Blob;
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href); // Clean up the object URL
      });
  }

  get_user_files(user_id: string) {
    return this.api.getAPI(`files/get-files/${user_id}`);
  }

  get_operation_status(file_id: any) {
    return this.api.getAPI(`files/get-operation-files/${file_id}`);
  }

  post_file_data(data: any) {
    return this.api.postAPI('files/file-data', data);
  }

  get_file_submissions(artwork_id: number) {
    return this.api.getAPI(`submissions/get-file-submissions/${artwork_id}`);
  }

  download_report_file(unique_file_reference: any) {
    this.api
      .getFileAPI(
        `submissions/download-report-file`,
        `${unique_file_reference}`
      )
      .subscribe((response: any) => {
        let contentDispositionHeader = response.headers.get(
          'Content-Disposition'
        );
        let fileName = '';

        if (contentDispositionHeader) {
          let matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches.legnth > 1) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        const blob: Blob = response.body as Blob;
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
      });
  }
}
