import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RoleService } from 'src/app/_services/role.service';
import { Role } from 'src/app/_models/role';
import { UserService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {
  submitted: boolean = false;
  roles: any[] = [];
  selectedRole: Role | undefined;
  color: string | undefined;
  users: any[] = [];
  loading: boolean = false;


  constructor(
    public roleService: RoleService,
    private router: Router,
    public location: Location,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.roleService.roleForm.reset();
    // this.roles = this.getRoleValues();
  }

  // getRoleValues() {
  //   const enumType = Role;
  //   return Object.keys(enumType)
  //     .filter((key: any) => !isNaN(Number(key)))
  //     .map((key: any) => {
  //       return { role_id: key, role_name: enumType[key] };
  //     });
  // }
  get f() {
    return this.roleService.roleForm.controls;
  
  }

  onSubmitRoleForm() {
    this.submitted = true;
    this.loading = true;

    if (this.roleService.roleForm.invalid) {
      this.loading = false;
      return
    }
    
    
    this.roleService.postRole(this.roleService.roleForm.value).subscribe((response) => {
      this.loading = false;
      this.ref.close(response);
      this.roleService.getRole().subscribe((roles) => {
        this.roleService.updateRoles(roles)
        })
      
    });
    

    

  }

}
