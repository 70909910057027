<p-toast key="register"></p-toast>
<p-confirmDialog header="Delete"></p-confirmDialog>
<p-contextMenu #cmu [model]="contextMenuUsers"></p-contextMenu>
<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">Settings</h2>
  </div>
</div>
<div class="m-4">
  <section class="px-4 lg:px-6">
    <div class="surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Switch Users</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">
        You can easily manage your team on this page
      </p>
    </div>
    <section
      class="flex flex-wrap gap-3 py-6 justify-content-between shadow-3 surface-card"
    >
      <div class="flex-shrink-0 w-15rem p-4">
        <h3 class="mb-4 mt-0 text-900 font-medium text-xl">Users</h3>
        <p class="mb-4 mt-0 text-700 font-normal text-base">
          Manage your users in this project
        </p>
        <button
          pButton
          pRipple
          label="Register a User"
          class="w-auto"
          (click)="showUserRegisterDialog()"
        ></button>
      </div>
      <div class="overflow-x-scroll p-4">
        <p-table
          #dt
          [value]="(members$ | async) || []"
          [tableStyle]="{ 'min-width': '60rem' }"
          [rowHover]="true"
          [scrollable]="true"
          scrollHeight="50vh"
          [(contextMenuSelection)]="selectedMember"
          [contextMenu]="cmu"
          [paginator]="true"
          [rows]="100"
          [rowsPerPageOptions]="[100, 200, 500]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="[
            'first_name',
            'last_name',
            'email',
            'company_name'
          ]"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal($event)"
                  placeholder="Search keyword"
                />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of userColumns" [pSortableColumn]="col.field">
                <span class="font-semibold text-md text-color-secondary">{{
                  col.header
                }}</span>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-member>
            <tr
              style="cursor: pointer"
              (click)="showUserDetailsDialog(member, $event)"
              [pContextMenuRow]="member"
            >
              <td *ngFor="let col of userColumns">
                <div class="flex align-items-center gap-3">
                  <div>
                    <p class="mt- mb-2 font-medium text-md text-color-primary">
                      {{ member[col.field] }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  pButton
                  icon="pi pi-ellipsis-v"
                  class="p-button-text p-button-secondary"
                  (click)="
                    menu.toggle($event);
                    storeRowDetail(member);
                    $event.stopPropagation()
                  "
                ></button>
                <p-menu
                  #menu
                  appendTo="body"
                  [popup]="true"
                  [model]="items"
                ></p-menu>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td
                [attr.colspan]="this.getNumberofColumns()"
                class="align-content-center"
              >
                <p-progressBar
                  styleClass="align-self-center"
                  *ngIf="isLoadingUsers"
                  mode="indeterminate"
                >
                </p-progressBar>
                <div *ngIf="!isLoadingUsers">
                  <p>
                    There are no records to show. You might be disconnected or
                    offline.
                  </p>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </section>
  </section>
</div>
