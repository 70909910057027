import { Component, Input, OnInit } from '@angular/core';
import { FilesService } from 'src/app/_services/files.service';
import { UserService } from 'src/app/_services';
import { MenuItem } from 'primeng/api';
import { FormGroup, FormBuilder, Form, FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { QuotesService } from 'src/app/_services/quotes.service';

@Component({
  selector: 'app-add-artwork',
  templateUrl: './add-artwork.component.html',
  styleUrls: ['./add-artwork.component.scss'],
})
export class AddArtworkComponent implements OnInit {
  @Input() quote_id: any;
  @Input() disabled: boolean = false;
  @Input() external_estimate_id: any;
  document_type: any;
  user: any;
  user_id: any;
  otherFiles: any[] = [];
  uploadedFile: any = null;
  active: number = 0;
  IntentOptions: any[];
  ImpoTypeOptions: any[];
  DigitalPressSheet: any[];
  Sides: any[];
  DoubleCut: any[];
  selectedIntent: any;
  selectedImpoType: any;
  selectedDigitalPress: any;
  selectedSides: any;
  selectedDoubleCut: any;
  selectedQuote: any;
  selectedExternalID: any;
  loading: boolean = false;
  images2: string[] = [
    'assets/images/example-image.png',
    'assets/images/example-image.png',
    'assets/images/example-image.png',
    'assets/images/example-image.png',
  ];
  selectedImageIndex2: number = 0;
  items: MenuItem[] = [];
  selectedFile: File | null = null;
  quotes: any[] = [];
  formData: FormData = new FormData();
  fileForm: FormGroup<{
    intent: FormControl<string | null>;
    quote_id: FormControl<string | null>;
    impo_type: FormControl<string | null>;
    impo_sides: FormControl<string | null>;
    impo_digital_press_sheet: FormControl<string | null>;
    impo_double_cut: FormControl<string | null>;
    external_estimate_id: FormControl<string | null>;
  }>;

  constructor(
    public filesService: FilesService,
    private userService: UserService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private quotesService: QuotesService
  ) {
    this.fileForm = this.fb.group({
      intent: new FormControl(''),
      quote_id: new FormControl(''),
      impo_type: new FormControl(''),
      impo_sides: new FormControl(''),
      impo_digital_press_sheet: new FormControl(''),
      impo_double_cut: new FormControl(''),
      external_estimate_id: new FormControl(''),
    });

    this.IntentOptions = ['quote', 'preflight', 'imposition'];


    this.ImpoTypeOptions = [
      'Step & Repeat',
      'Number-UP',
      'Perfect Bound',
      'Saddle Stitch',
      'Cut & Stack',
    ];

    this.DigitalPressSheet = ['A4', 'A3', '225x320mm', '450x320mm'];

    this.Sides = ['Simplex', 'Duplex'];

    this.DoubleCut = ['2mm', '5mm'];
  }

  ngOnInit(): void {
    
    
    console.log(this.external_estimate_id);

    this.userService.getMe().subscribe((user) => {
      console.log('user', user);
      
      if (user.role?.name === 'customer-pifsa') {
        this.IntentOptions = ['preflight', 'imposition'];
      }

      this.quotesService.getQuoteById(user.id).subscribe((response: any) => {
        this.quotes = response.body;
        console.log('quotes', this.quotes);
        
      })
    });

    
    
    if (this.quote_id) {
      this.IntentOptions = ['quote'];
      this.selectedIntent = 'quote';
    }

    // this.quotesService.getQuotes().subscribe((quotes: any) => {   
    //   this.quotes = quotes.body;
    // });

    this.items = [
      { label: 'Uploaded' },
      { label: 'Processing' },
      { label: 'Finished' },
    ];
  }

  // uploadFile(event$: any) {
  //   let file = event$.files[0];
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   this.filesService.uploadFile(formData, this.document_type);
  //   this.uploadedFile = 0;
  // }

  onFileSelect(event$: any) {
    this.selectedFile = event$.files[0];
    this.uploadedFile = 1;
  }

  handleFileData(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
    };
    reader.readAsDataURL(file);
  }

  onUpload(event$: any) {
    // get rid of the files in the files section after uploading

    this.uploadedFile = 0;
  }

  onIntentChange() {
    this.selectedIntent = this.selectedIntent;
    this.fileForm.value.impo_digital_press_sheet = '';
    this.fileForm.value.impo_double_cut = '';
    this.fileForm.value.impo_sides = '';
    this.fileForm.value.impo_type = '';
    this.fileForm.value.quote_id = '';
  }

  onQuoteSelect() {
    this.quotesService.getSalesQuoteByID(this.selectedQuote).subscribe((quote: any) => {
      this.selectedExternalID = quote.body.external_estimate_id;
      console.log('selectedExternalID', this.selectedExternalID);
      this.fileForm.get('external_estimate_id')?.setValue(this.selectedExternalID);
      console.log(this.fileForm.value);
      
    });
  }
  
  onSubmitArtwork() {
    console.log('test 2');
    
    this.loading = true;
    if (this.fileForm.invalid) {
      this.loading = false;
      return;
    }
    if(this.quote_id){      
      
      this.fileForm.get('quote_id')?.setValue(this.quote_id);
      this.fileForm.get('intent')?.setValue('quote');
      this.fileForm.get('external_estimate_id')?.setValue(this.external_estimate_id);
    }
    this.formData.append('file_data', JSON.stringify(this.fileForm.value));
    this.formData.append('file', this.selectedFile as Blob);    

    this.filesService.uploadFile(this.formData).subscribe(
      (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'File uploaded successfully',
          key: 'fileUpload',
        });
        this.loading = false;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error uploading file',
          key: 'fileUpload',
        });
        this.loading = false;
      }
    );

    console.log('test');
    
  }


}
