import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { CustomerService } from 'src/app/_services/customer.service';
import { MenuItem } from 'primeng/api';
import { UpdateCustomerDialogComponent } from './update-customer/update-customer-dialog/update-customer-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss']
})
export class CustomersTableComponent implements OnInit {
  customers: any[] = [];
  selectedCustomer: any;
  @ViewChild('dt') dt: Table | undefined;
  isLoading: boolean = true;

  constructor(
    public customerService: CustomerService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.customerService.getAllCustomers().subscribe((response: any) => {
      this.customers = response;
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    }
  );
  }

  items: MenuItem[] = [
    { label: 'Edit', icon: 'pi pi-pencil', command: () => this.updateCustomer(this.selectedCustomer) },
  ]; 

  columns = [
    { field: 'company_name', header: 'Company Name' },
    { field: 'code', header: 'Code' },
    { field: 'id', header: 'ID' },
  ];

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  storeRowDetail(rowData: any) {
    this.selectedCustomer = rowData;
  }

  async updateCustomer(rowData: any) {
    const ref = await this.dialogService.open(UpdateCustomerDialogComponent, {
      data: {
        customer: rowData,
      },
      header: 'Client Details',
      width: '70%',
    }).onClose.toPromise();

  }

  getNumberofColumns() {
    return this.columns.length;
  }
}
