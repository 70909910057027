<p-progressSpinner *ngIf="loadingData" styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
<div class="surface-section px-4 py-4 md:px-1 lg:px-2" *ngIf="!loadingData && !errorLoadingData">
    <div class="grid">
      <form
        [formGroup]="this.productsService.productForm"
        (ngSubmit)="updateProduct(productData.id)"
      >
        <div class="col-12 lg:col-12">
          <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12 md:col-6">
              <label for="product_name" class="font-medium text-900"
                >Product Name</label
              >
              <input
                id="product_name"
                type="text"
                pInputText
                formControlName="product_name"
                [(ngModel)]="productData.product_name"
              />
              <div
                class="px-1 py-1"
                *ngIf="
                  this.productsService.productForm.get('product_name')?.invalid &&
                  (this.productsService.productForm.get('product_name')?.touched ||
                    this.productsService.productForm.get('product_name')?.dirty)
                "
              >
                <small id="first_name-help" class="p-error block"
                  ><strong>Product Name</strong> is required</small
                >
              </div>
            </div>
            <div class="field mb-4 col-12 md:col-6">
              <label for="description" class="font-medium text-900"
                >Description</label
              >
              <input
                id="description"
                type="text"
                pInputText
                formControlName="description"
                [(ngModel)]="productData.description"
              />
              <div
                class="px-1 py-1"
                *ngIf="
                  this.productsService.productForm.get('description')?.invalid &&
                  (this.productsService.productForm.get('description')?.touched ||
                    this.productsService.productForm.get('description')?.dirty)
                "
              >
                <small id="last_name-help" class="p-error block"
                  ><strong>Description</strong> is required</small
                >
              </div>
            </div>
            <div class="field mb-4 col-12 md:col-6">
                <label for="external_product_id" class="font-medium text-900"
                  >External ID</label
                >
                <input
                  id="external_product_id"
                  type="text"
                  pInputText
                  formControlName="external_product_id"
                  [(ngModel)]="productData.external_product_id"
                />
                <div
                  class="px-1 py-1"
                  *ngIf="
                    this.productsService.productForm.get('description')?.invalid &&
                    (this.productsService.productForm.get('description')?.touched ||
                      this.productsService.productForm.get('description')?.dirty)
                  "
                >
                  <small id="last_name-help" class="p-error block"
                    ><strong>External ID</strong> is required</small
                  >
                </div>
              </div>
            <div class="col-12">
              <button
                pButton
                pRipple
                type="submit"
                label="Save Changes"
                class="w-auto mt-3"
              ></button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  