import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  map,
  switchMap,
} from 'rxjs';
import { FilesService } from 'src/app/_services/files.service';
import { UserService } from 'src/app/_services';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ArtworkDetailsComponent } from '../artwork-details/artwork-details.component';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-view-artwork',
  templateUrl: './view-artwork.component.html',
  styleUrls: ['./view-artwork.component.scss']
})
export class ViewArtworkComponent implements OnInit {
  files: any[] = [];
  files$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  user_id: any;
  ref: any;
  @ViewChild('dt') dt: Table | undefined;
  isLoading: boolean = true;

  constructor(
    public filesService: FilesService,
    private userService: UserService,
    public dialogService: DialogService,
  ) { }

  columns = [
    { field: 'original_filename', header: 'File Name' },
    { field: 'file_type', header: 'File Type' },
    { field: 'file_size', header: 'File Size' },
    { field: 'date_created', header: 'Created At' },
    { field: 'date_updated', header: 'Updated At' },
  ];
  

  ngOnInit(): void {
    this.userService.currentUser$
      .pipe(
        switchMap((user) => {
          if (user) {
            this.user_id = user.id;
            return this.filesService.get_user_files(this.user_id);
          } else {
            // Handle case where user is not available
            return new Observable();
          }
        })
      )
      .subscribe(
        (response: any) => {
          response.body.map((file: any) => {
            this.files.push(file);
            this.isLoading = false;
          });
        },
        (error) => {
          console.error('Error retrieving files:', error);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );

  }

  downloadFile(file_id: string, fileName: string) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
    this.filesService.downloadFile(file_id, 'pdf');
  }

  showArtworkDetails(artworkDetails: any) {
    this.ref = this.dialogService.open(ArtworkDetailsComponent, {
      data: {
        formdata: artworkDetails,
      },
      header: 'View Artwork Details',
      width: '80%',
      height: '80%',
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((res: any) => {
    });
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.columns.length + 1;
  }
}
