import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RoleService } from 'src/app/_services/role.service';

@Component({
  selector: 'app-role-details',
  templateUrl: './role-details.component.html',
  styleUrls: ['./role-details.component.scss']
})
export class RoleDetailsComponent implements OnInit {
  roles: any;
  loadingData: boolean = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public roleService: RoleService
  ) { }

  ngOnInit(): void {
    const role = this.config.data.role

    this.roleService.getRole().subscribe(
      (user_roles) => {
        this.loadingData = false;
        this.roles = user_roles.find((r) => r.id === role.id)
        
      })
  }

}
