import {
  Component,
  ElementRef,
  Input,
  NgModule,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../_services/authentication.service';
import { CartService } from '../_services/cart.service';
import { Role } from '../_models/role';
import { UserService } from '../_services/user.service';
import { StyleClass } from 'primeng/styleclass';
import { BulkOrderFormService } from '../_services/bulk-order-form.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  mainitems: MenuItem[];
  profileitems: MenuItem[];
  menu: any;
  isMenuVisible: boolean = false;
  @Input() user: any = this.authenticationService.userValue;
  cartItems: any[] = [];
  cartLength: any;
  roles: typeof Role = Role;
  user$ = this.authenticationService.user;
  @Input() menuItems: any[] = [];
  filteredMenuItems: any[] = [];
  menuItem: any;
  currentUser: any;
  hasSubChild: boolean = false;
  currentYear: number = new Date().getFullYear();
  hasAccess: boolean = false;
  userRole: any;
  bulkOrderCount: any;
  bulkOrderCount$ = this.bulkOrderFormService.reviewCount$;

  showFiller = false;
  isVisible: boolean = false;
  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    public authenticationService: AuthenticationService,
    private cartService: CartService,
    public userService: UserService,
    private bulkOrderFormService: BulkOrderFormService
  ) {
    this.user = this.authenticationService.userValue;

    if (this.user) {
      this.mainitems = [
        { label: 'Home', routerLink: '' },
        { label: 'Get Quote', routerLink: 'getquote' },
      ];
    } else {
      this.mainitems = [
        // { label: 'Home', routerLink: '/' },
        // { label: 'Things To Do', routerLink: 'thingstodo' },
        // { label: 'Table', routerLink: 'table' },
        { label: 'Login', routerLink: 'login' },
      ];
    }

    this.profileitems = [
      {
        label: 'Logout',
        command: () => {
          this.authenticationService.logout();
        },
      },
    ];
  }

  ngOnInit(): void {
    this.cartService.cartItems$.subscribe((items) => {
      this.cartItems = items;
      this.cartLength = this.cartItems.length;
    });

    if (this.user) {
      this.userService.getCurrentLoggedInUser();
    }

    setTimeout(() => {
      this.bulkOrderFormService.getBulkOrderActionCount().subscribe((data) => {
        this.bulkOrderFormService.updateReviewCount(data.body);
        this.bulkOrderCount$.subscribe((count) => {
          this.bulkOrderCount = count;
        });
      });
    }, 1000);

    if (
      Role.Admin ||
      Role.Customer_ZPL ||
      Role.Basic_ZPL ||
      Role.Customer_PIFSA
    ) {
      this.hasAccess = true;
    } else {
      this.hasAccess = false;
    }

    this.userService.currentUser$.subscribe((user) => {
      this.userRole = user?.role?.name;
    });

    this.menuItems = [
      {
        label: 'Home',
        routerLink: '/',
        icon: 'pi pi-home',
        rolesWithAccess: [
          Role.Admin,
          Role.Customer_ZPL,
          Role.Basic_ZPL,
          Role.Customer_PIFSA,
        ],
      },
      {
        label: 'Switch Settings',
        routerLink: 'switch-admin',
        icon: 'pi pi-cog',
        rolesWithAccess: [Role.Admin_Switch],
      },
      // {
      //   label: 'Bulk Order Settings',
      //   routerLink: 'admin-bulkorders',
      //   icon: 'pi pi-cog',
      //   rolesWithAccess: [Role.Review_BulkOrders]
      // },
      {
        label: 'Actions',
        icon: 'pi pi-th-large',
        rolesWithAccess: [
          Role.Admin,
          Role.Customer_ZPL,
          Role.Customer_PIFSA,
          Role.Review_BulkOrders,
          Role.Admin_BulkOrders,
        ],
        childMenuItem: [
          {
            label: 'Generate',
            icon: 'pi pi-plus-circle',
            rolesWithAccess: [
              Role.Admin,
              Role.Customer_ZPL,
              Role.Customer_PIFSA,
              Role.Admin_BulkOrders,
            ],
            childSubItem: [
              {
                label: 'Quote',
                routerLink: 'generatequote',
                icon: 'pi pi-plus-circle',
                rolesWithAccess: [Role.Admin, Role.Customer_ZPL],
              },
              {
                label: 'Artwork',
                routerLink: 'artwork',
                icon: 'pi pi-plus-circle',
                rolesWithAccess: [Role.Admin, Role.Customer_PIFSA],
              },
              {
                label: 'Bulk Orders',
                routerLink: 'bulk-orders',
                icon: 'pi pi-plus-circle',
                rolesWithAccess: [Role.Admin, Role.Admin_BulkOrders],
              },
            ],
          },
          {
            label: 'View',
            icon: 'pi pi-eye',
            rolesWithAccess: [
              Role.Admin,
              Role.Customer_ZPL,
              Role.Customer_PIFSA,
              Role.Review_BulkOrders,
              Role.Admin_BulkOrders,
            ],
            childSubItem: [
              {
                label: 'My Quotes',
                routerLink: 'viewmyquotes',
                icon: 'pi pi-shopping-cart',
                rolesWithAccess: [Role.Admin, Role.Customer_ZPL],
              },
              {
                label: 'My Artworks',
                routerLink: 'view-artwork',
                icon: 'pi pi-shopping-cart',
                rolesWithAccess: [Role.Admin, Role.Customer_PIFSA],
              },
              {
                label: 'View Bulk Orders',
                routerLink: 'view-bulk-orders',
                icon: 'pi pi-users',
                rolesWithAccess: [Role.Admin, Role.Review_BulkOrders, Role.Admin_BulkOrders],
              },
              {
                label: 'View Rejected Orders',
                routerLink: 'rejected-bulk-orders',
                icon: 'pi pi-eye',
                rolesWithAccess: [Role.Admin, Role.Review_BulkOrders, Role.Admin_BulkOrders],
              },
              {
                label: 'Review Bulk Orders',
                routerLink: 'review-bulk-orders',
                icon: 'pi pi-eye',
                rolesWithAccess: [Role.Admin, Role.Review_BulkOrders, Role.Admin_BulkOrders],
              },
            ],
          },
        ],
      },

      {
        label: 'Admin',
        icon: 'pi pi-chart-line',
        rolesWithAccess: [Role.Admin, Role.Admin_Switch],
        childMenuItem: [
          {
            label: 'Views',
            icon: 'pi pi-eye',
            rolesWithAccess: [Role.Admin, Role.Admin_Switch],
            childSubItem: [
              {
                label: 'View Estimates',
                routerLink: 'quote-admin-table',
                icon: 'pi pi-chart-bar',
                rolesWithAccess: [Role.Admin],
              },
              {
                label: 'View Products',
                routerLink: 'product',
                icon: 'pi pi-cog',
                rolesWithAccess: [Role.Admin],
              },
              {
                label: 'View Customers',
                routerLink: 'customer-table',
                icon: 'pi pi-users',
                rolesWithAccess: [Role.Admin],
              },

              {
                label: 'View Webhooks',
                routerLink: 'switch-webhook-admin',
                icon: 'pi pi-users',
                rolesWithAccess: [Role.Admin, Role.Admin_Switch],
              },
            ],
          },
          {
            label: 'Actions',
            icon: 'pi pi-th-large',
            rolesWithAccess: [Role.Admin, Role.Admin_Switch],
            childSubItem: [
              {
                label: 'Create Switch Webhook',
                routerLink: 'switch-webhook',
                icon: 'pi pi-plus-circle',
                rolesWithAccess: [Role.Admin, Role.Admin_Switch],
              },
            ],
          },
        ],
      },
      {
        label: 'Settings',
        routerLink: 'settings',
        icon: 'pi pi-cog',
        rolesWithAccess: [
          Role.Admin,
          Role.Customer_ZPL,
          Role.Basic_ZPL,
          Role.Customer_PIFSA,
        ],
      },
    ];
  }

  toggleMenu(menuItem: any) {
    // Set the flag to true to show the menu
    if (menuItem.childMenuItem) {
      this.isVisible = !this.isVisible;
    }
  }

  isBurgerMenuVisible: boolean = true;

  toggleBurgerMenu() {
    this.isBurgerMenuVisible = !this.isBurgerMenuVisible;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(1)
    );
}
