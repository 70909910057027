import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-rejected-bulk-orders-dialog',
  templateUrl: './rejected-bulk-orders-dialog.component.html',
  styleUrls: ['./rejected-bulk-orders-dialog.component.scss'],
})
export class RejectedBulkOrdersDialogComponent implements OnInit {
  data: any;
  submitted = false;
  selectedOrderType: any;
  maxQty = 160; // Maximum total quantity allowed
  totalQty = 0; // Track total quantity across all forms
  selectedType: any;
  loading = false;
  row_count: number = 0;

  form1 = new FormGroup({
    order_type: new FormControl(''),
    order_number: new FormControl('', [Validators.required]),
    purchase_order: new FormControl('', [Validators.required]),
    total_quantity: new FormControl(0),
    username: new FormControl(''),
    orders: new FormArray([]),
  });

  order_types = [
    { value: 'A5 Gewoon', viewValue: 'A5 Gewoon', type: 'A5' },
    { value: 'A5 Biocross', viewValue: 'A5 Biocross', type: 'A5' },
    { value: 'A4 Gewoon', viewValue: 'A4 Gewoon', type: 'A4' },
    { value: 'A4 Profile Books', viewValue: 'A4 Profile Books', type: 'A4' },
    {
      value: 'A4 Profile Biocross books',
      viewValue: 'A4 Profile Biocross books',
      type: 'A4',
    },
    { value: 'A4 Cashpath', viewValue: 'A4 Cashpath', type: 'A4' },
    {
      value: 'A4 (Cyto, Downs, Gynae, Histo)',
      viewValue: 'A4 (Cyto, Downs, Gynae, Histo)',
      type: 'A4',
    },
    { value: 'A4 Allergy', viewValue: 'A4 Allergy', type: 'A4' },
  ];

  constructor(
    public config: DynamicDialogConfig,
    private bulkOrderFormService: BulkOrderFormService,
    public ref: DynamicDialogRef,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;

    this.row_count = this.data.bulk_orders.row_count;

    this.selectedOrderType = this.order_types.find(
      (order) => order.value === this.data.bulk_orders.order_type
    )?.value;

    this.selectedType = this.order_types.find(
      (order) => order.value === this.data.bulk_orders.order_type
    )?.type;

    this.form1.patchValue({
      order_type: this.data.bulk_orders.order_type,
      order_number: this.data.bulk_orders.order_number,
      purchase_order: this.data.bulk_orders.purchase_order,
      total_quantity: this.data.bulk_orders.total_quantity,
      username: this.data.bulk_orders.username,
    });

    for (let i = 0; i < this.data.bulk_orders.orders.length; i++) {
      // patch the form array with the orders
      const order = this.bulkOrderFormService.getBulkOrderForm();
      order.patchValue(this.data.bulk_orders.orders[i]);
      this.orderArray.push(order);
    }
  }

  onOrderTypeChange() {
    if (this.selectedOrderType) {
      this.selectedType = this.selectedOrderType.type;
    }
  }

  isFieldInvalid(index: number, fieldName: string): boolean {
    const control = (this.form1.get('orders') as FormArray)
      .at(index)
      .get(fieldName);

    return control?.invalid || false;
  }

  get orderArray() {
    return this.form1.get('orders') as FormArray;
  }

  addOrder() {
    if (this.totalQty < this.maxQty) {
      const order = this.bulkOrderFormService.getBulkOrderForm();

      this.orderArray.push(order);
      //add valitators to the biocross field
      if (
        this.selectedOrderType === 'A5 Biocross' ||
        this.selectedOrderType === 'A4 Cashpath' ||
        this.selectedOrderType === 'A4 (Cyto, Downs, Gynae, Histo)'
      ) {
        order.get('biocross')?.setValidators(Validators.required);
      } else {
        order.get('biocross')?.clearValidators();
      }
      order.get('biocross')?.updateValueAndValidity();
    }
  }

  deleteOrder(index: number) {
    this.orderArray.removeAt(index);
  }

  calculateTotalQty() {
    this.totalQty = this.orderArray.controls.reduce((acc, formGroup) => {
      return acc + formGroup.get('quantity')?.value || 0;
    }, 0);
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    if (this.form1.invalid || this.totalQty > this.maxQty) {
      this.loading = false;
      return;
    }

    if (this.totalQty > 0) {
      this.form1.patchValue({
        total_quantity: this.totalQty,
      });
    }

    this.bulkOrderFormService
      .updateBulkOrder(this.data.id, this.form1.value)
      .subscribe(
        (response: any) => {
          this.bulkOrderFormService
            .getRejectedBulkOrders()
            .subscribe((orders: any) => {
              this.bulkOrderFormService.updateOrders(orders.body);
            });

          this.bulkOrderFormService
            .getBulkOrderActionCount()
            .subscribe((data) => {
              this.bulkOrderFormService.updateReviewCount(data.body);
            });
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Order updated successfully',
            key: 'rejected-bulk-orders-dialog',
          });
          this.loading = false;
          this.ref.close();
        },
        (error) => {
          this.loading = false;
        }
      );
  }
}
