import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';

@Component({
  selector: 'app-admin-bulkorders',
  templateUrl: './admin-bulkorders.component.html',
  styleUrls: ['./admin-bulkorders.component.scss']
})
export class AdminBulkordersComponent implements OnInit {
  selectedMember: any;
  selectedRole: any;
  value1: boolean = false;
  members: any;
  members$ = this.bulkOrderService.bulkOrderUsers$;
  ref: any;
  selectedRow: any;
  roles: any;
  currentUser: any;
  loading: boolean = false;
  @ViewChild('dt') dt: Table | undefined;
  isLoadingUsers: boolean = true;

  constructor(
    private bulkOrderService: BulkOrderFormService
  ) { }

  ngOnInit(): void {
  }

  contextMenuUsers: MenuItem[] = [
    // { label: 'Edit', icon: 'pi pi-pencil', command: () => {
    //   this.updateUser(this.selectedMember)
    //  } },
    // { label: 'Delete', icon: 'pi pi-times', command: () => { 
    //   this.confirmUserContextMenu()
    // } },
  ];

  items: MenuItem[] = [
    // {
    //   label: 'Options',
    //   items: [
    //     {
    //       label: 'Edit',
    //       icon: 'pi pi-pencil',
    //       command: () => {
    //         this.updateUser(this.selectedRow)
    //       }
    //     },
    //     {
    //       label: 'Delete',
    //       icon: 'pi pi-times',
    //       command: () => {
    //         this.confirmUser()
    //       },
    //     },
    //   ],
    // },
  ];

  showUserRegisterDialog() {
    // this.ref = this.dialogService.open(RegisterUserComponent, {
    //   data: {
    //     formdata: null,
    //   },
    //   header: 'Register a User',
    //   width: '70%',
    //   height: '70%',
    //   closeOnEscape: true,
    // });

    // this.ref.onClose.subscribe((res: any) => {
    //   if (!res) return;
    //   this.messageService.add({
    //     severity: 'success',
    //     summary: 'Success',
    //     detail: 'User Created Successfully',
    //   });
    // });
  }

  showUserDetailsDialog(rowData: any, event: Event) {

    // // Check if the target of the click event was the ellipsis button
    // if ((event.target as HTMLElement).classList.contains('pi-ellipsis-v')) {
    //   // Clicked on the ellipsis button, don't open the dialog
    //   return
    // } else {
    //   // Clicked on the row, open the dialog
    //   const ref = this.dialogService.open(UserDetailsComponent, {
    //     data: {
    //       user: rowData,
    //     },
    //     header: 'User Details',
    //     width: '70%',
    //   })
    // }
  }

  async updateUser(rowData: any) {
    // const ref = await this.dialogService.open(UpdateUserComponent, {
    //   data: {
    //     user: rowData,
    //   },
    //   header: 'Client Details',
    //   width: '70%',
    // }).onClose.toPromise();

    // this.ref.onClose.subscribe((res: any) => {
    //   if (!res) return;
    //   this.messageService.add({
    //     severity: 'success',
    //     summary: 'Success',
    //     detail: 'User Updated Successfully',
    //   });
    // });

  }

  storeRowDetail(details: any) {
    this.selectedRow = details;
  }

  confirmUserContextMenu() {
    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete this user?',
    //   header: 'Delete Confirmation',
    //   icon: 'pi pi-info-circle',

    //   accept: () => {
    //     this.userService.deleteUser(this.selectedMember.id).subscribe((res) => {
    //       this.userService.getAll().subscribe(
    //         (users) => {
    //           this.userService.updateMembers(users);
    //         },
    //         (error: any) => {
    //           // Display an error message
    //         }
    //       );
    //       this.messageService.add({
    //         severity: 'info',
    //         summary: 'Confirmed',
    //         detail: 'Record deleted'
    //       });
    //     });
    //   },

    //   reject: (type: ConfirmEventType) => {
    //     switch (type) {
    //       case ConfirmEventType.REJECT:
    //         this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
    //         break;
    //       case ConfirmEventType.CANCEL:
    //         this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
    //         break;
    //     }
    //   }
    // });
  }

  confirmUser() {

    // this.confirmationService.confirm({
    //   message: 'Are you sure you want to delete this user?',
    //   header: 'Delete Confirmation',
    //   icon: 'pi pi-info-circle',

    //   accept: () => {
    //     this.userService.deleteUser(this.selectedRow.id).subscribe((res) => {
    //       this.userService.getAll().subscribe(
    //         (users) => {
    //           this.userService.updateMembers(users);
    //         },
    //         (error: any) => {
    //           // Display an error message
    //         }
    //       );
    //       this.messageService.add({
    //         severity: 'info',
    //         summary: 'Confirmed',
    //         detail: 'Record deleted'
    //       });
    //     });
    //   },

    //   reject: (type: ConfirmEventType) => {
    //     switch (type) {
    //       case ConfirmEventType.REJECT:
    //         this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
    //         break;
    //       case ConfirmEventType.CANCEL:
    //         this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
    //         break;
    //     }
    //   }
    // });
  }

  userColumns = [
    { field: 'username', header: 'Username' },
    { field: 'email', header: 'Email' },
    { field: 'company_name', header: 'Customer Company Name' },

  ];

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.dt?.filterGlobal(inputElement.value, 'contains');
  }

  getNumberofColumns() {
    return this.userColumns.length + 1;
  }

}
