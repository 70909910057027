import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
})
export class CreateProductComponent implements OnInit {
  constructor(
    public productService: ProductsService,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    const productFormValue = this.productService.productForm.value;
    this.productService
      .createNewProduct(productFormValue)
      .subscribe((response) => {      });
    this.ref.close();
  }
}
