import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  loadingData: boolean = true;
  users: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public userService: UserService,
  ) { }

  ngOnInit(): void {
    const user = this.config.data.user;

    this.userService.getAll().subscribe(
      (users) => {
        this.loadingData = false;
        this.users = users.find((u) => u.id === user.id)
      },
  )}
}
