<div class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card">
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">All Quotes</h2>
  </div>
</div>
  <div class=" px-4 py-2 md:px-6 lg:px-8" style="margin-top: 50px;">
    <p-table
      #dt
      [value]="(quotesService.quotes$ | async) || []"
      [tableStyle]="{ 'min-width': '100vw' }"
      [rowHover]="true"
      [scrollable]="true"
      scrollHeight="60vh"
      styleClass="z-0"
      [paginator]="true" 
      [rows]="50"
      [rowsPerPageOptions]="[50, 100, 150]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="['quote_number', 'customer_name', 'quote_date', 'quote_status']"
      >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="filterGlobal($event)" placeholder="Search keyword" />
            </span>
        </div>
    </ng-template> 
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            <span class="font-semibold text-md text-color-secondary">{{
              col.header
            }}</span>
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
  
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-quote > 
        <tr style="cursor: pointer;" (click)="openDialog(quote)">
          <td *ngFor="let col of columns">
            <div class="flex align-items-center gap-3" >
              <div>
                <p class="mt-0 mb-2 font-medium text-md text-color-primary">
                  {{ quote[col.field] }}
                </p>
              </div>
            </div>
          </td>
          <td>
            <button
              type="button"
              pButton
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-secondary"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td
            [attr.colspan]="this.getNumberofColumns()"
            class="align-content-center"
          >
            <p-progressBar
              styleClass="align-self-center"
              *ngIf="isLoading"
              mode="indeterminate"
            >
            </p-progressBar>
            <div *ngIf="!isLoading">
              <p>
                There are no records to show. You might be disconnected or offline.
              </p>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  