<div class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card">
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">My Artworks</h2>
  </div>
</div>
  <div class="surface-section px-4 py-2 md:px-6 lg:px-8" style="margin-top: 50px;">
    <p-table
    #dt
    [value]="files"
    [tableStyle]="{ 'min-width': '50rem' }"
    scrollHeight="60vh"
    selectionMode="single"
    styleClass="z-0"
    [paginator]="true"
    [rows]="100"
    [rowsPerPageOptions]="[100, 200, 500]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['original_filename']"
  >
  <ng-template pTemplate="caption">
    <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="filterGlobal($event)"
          placeholder="Search keyword"
        />
      </span>
    </div>
  </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
          <span class="font-semibold text-md text-color-secondary">{{
            col.header
          }}</span>
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th>Download File</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-file>
      <tr style="cursor: pointer" (click)="showArtworkDetails(file)">
        <td *ngFor="let col of columns">
          <div class="flex align-items-center gap-3">
            <ng-container *ngIf="col.field !== 'date_created' && col.field !== 'date_updated'">
              <div>
                <p class="mt-0 mb-2 font-medium text-md text-color-primary">
                  {{ file[col.field] }}
                </p>
              </div>
            </ng-container>
            <ng-container *ngIf="col.field === 'date_created' || col.field === 'date_updated'">
              <div>
                <p class="mt-0 mb-2 font-medium text-md text-color-primary">
                  {{ file[col.field] | date: 'yyyy-MM-dd HH:mm:ss' }}
                </p>
              </div>
            </ng-container>
            
          </div>
        </td>
        <td>
          <button
            (click)="
              downloadFile(file.unique_file_reference, file.original_filename); $event.stopPropagation()
            "
            pButton
            icon="pi pi-download"
            class="p-button p-button-link"
            [label]="file.unique_file_reference"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td
          [attr.colspan]="this.getNumberofColumns()"
          class="align-content-center"
        >
          <p-progressBar
            styleClass="align-self-center"
            *ngIf="isLoading"
            mode="indeterminate"
          >
          </p-progressBar>
          <div *ngIf="!isLoading">
            <p>
              There are no records to show. You might be disconnected or offline.
            </p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  </div>
  