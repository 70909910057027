import { Component, OnInit } from '@angular/core';
import { Role } from 'src/app/_models/role';
import { UserService } from 'src/app/_services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CustomerService } from 'src/app/_services/customer.service';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  userData: any; // A variable to store user data
  members$: Subject<any> = new Subject<any>();
  loadingData: boolean = true;
  errorLoadingData: boolean = false;
  customers: any[] = [];
  selectedCustomer: any;
  roles: any[] = [];
  loading: boolean = false;
  submitted: boolean = false;
  user: any;
  selectedRole: any;
  disabled: boolean = false;

  constructor(
    public config: DynamicDialogConfig,
    public userService: UserService,
    private router: Router,
    public location: Location,
    public ref: DynamicDialogRef,
    private customerService: CustomerService,
    private switchWebhookService: SwitchWebhookService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.user = this.config.data.user;

    this.userService.getRoles().subscribe((roles: any) => {
      this.roles = roles;
      if (this.user.role_id === 14) {
        this.disabled = true;
        this.selectedRole = this.roles.find((role) => role.id === 14)?.id;

        this.switchWebhookService.getWebhooksByUserId(this.user.id).subscribe(
          (webhooks) => {
            this.switchWebhookService.changeSwitchWebhook(webhooks);
          },
          (error) => {
            // Display an error message
          }
        );
      }
    });

    this.userService.getAll().subscribe(
      (users) => {
        this.loadingData = false;
        this.userData = users.find((u) => u.id === this.user.id);
      },
      (error) => {
        this.loadingData = false;
        this.errorLoadingData = true;
      }
    );

    this.customerService.getAllCustomers().subscribe((customers) => {
      this.customers = customers;
    });

    this.customerService
      .getCustomerById(this.userData.customer.id)
      .subscribe((customer) => {
        this.selectedCustomer = customer;
      });
  }

  get f() {
    return this.userService.updateUserForm.controls;
  }

  updateUser(id: number) {
    this.loading = true;
    this.submitted = true;
    const userFormValue = this.userService.updateUserForm.value;
    // Check if all required fields are present and not empty
    if (this.userService.updateUserForm.invalid) {
      this.loading = false;

      // Display an error message or prevent the update request
      return;
    }

    // If all required fields are filled, proceed with the update request
    this.userService.updateUser(id, userFormValue).subscribe((res: any) => {
      this.loading = false;
      this.ref.close(res);
      this.userService.getAll().subscribe(
        (users) => {
          this.userService.updateMembers(users);
          this.switchWebhookService.changeSwitchUsers(users);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'User updated',
            key: 'register',
          });
        },
        (error: any) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'User update failed',
            key: 'register',
          });
        }
      );
    });
  }
}
