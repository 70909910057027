import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
