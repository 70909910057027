import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SwitchWebhookService {
  private switchWebhook = new BehaviorSubject<any>(null);
  currentSwitchWebhook = this.switchWebhook.asObservable();

  private switchUsers = new BehaviorSubject<any>(null);
  currentSwitchUsers = this.switchUsers.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  changeSwitchUsers(data: any) {
    this.switchUsers.next(data);
  }
  

  changeSwitchWebhook(data: any) {
    this.switchWebhook.next(data);
  }

  getSwitchWebhook() {
    return this.switchWebhook.value;
  }

  getSwitchWebhookData() {
    return this.apiService.getAPI('webhooks');
  }

  createBaseUrl(data: any) {
    return this.apiService.postAPI('webhooks/base-url', data);
  }

  createSwitchWebhook(data: any) {
    return this.apiService.postAPI('webhooks/create-webhook', data);
  }

  getSwitchWebhookDataById(id: string) {
    return this.apiService.getAPI(`webhooks/${id}`);
  }

  getBaseUrlById(id: string) {
    return this.apiService.getAPI(`webhooks/${id}/base-urls`);
  }

  getUserWebhooks() {
    return this.apiService.getAPI('webhooks/user-webhooks');
  }

  updateSwitchWebhookData(id: any, data: any) {
    return this.apiService.updateAPI(`webhooks/${id}`, id, data);
  }

  deleteSwitchWebhookData(id: any) {
    return this.apiService.deleteAPI(`webhooks`, id);
  }

  webhookSubmissions(id: any) {
    return this.apiService.getAPI(`webhooks/${id}/submissions`);
  }

  postWebhookSubmission(user_id: any, webhook_id: any, data: any) {
    return this.apiService.postAPI(`webhooks/${webhook_id}/${user_id}/submissions`, data);
  }

  getWebhooksByUserId(id: any) {
    return this.apiService.getAPI(`webhooks/${id}/user-webhooks`);
  }

  getWebhookFields(id: any) {
    return this.apiService.getAPI(`webhooks/${id}/fields`);
  }

  getFieldTypes() {
    return this.apiService.getAPI('webhooks/field-types');
  }

  getWebhookByAdminId(id: any) {
    return this.apiService.getAPI(`webhooks/admin/${id}`);
  }

  getSwitchUsers(){
    return this.apiService.getAPI('webhooks/switch-users')
  }
  
}
