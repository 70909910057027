import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Product } from '../_models/product';

@Injectable({
  providedIn: 'root',
})

export class ProductsService {
  productsQuery$: any;
  products$ = new Subject<any>();
  products: any;

  private clickedProductSource = new BehaviorSubject<any>(null);
  clickedProduct$ = this.clickedProductSource.asObservable();



  productForm: FormGroup;

  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) {
    this.productForm = new FormGroup({
      product_name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      external_product_id: new FormControl('', Validators.required),
      default_paper_code: new FormControl('', Validators.required),
      product_sizes: new FormGroup({
        name: new FormControl('', Validators.required),
      }),
    });
  }

  setClickedProduct(product: any) {
    this.clickedProductSource.next(product);
  }

  getAllProducts() {
    return this.api.getAPI('products').pipe(map((response) => response.body));
  }

  getProducts() {
    this.productsQuery$ = this.api.getAPI('product/get-products').subscribe((response: any) => {
      this.products = response.body;
      this.products$.next(this.products);
    });
    return this.productsQuery$;
  }

  createNewProduct(product: Product) {
    return this.api.postAPI('product/create-product', product);
  }

  deleteProduct(product_id: number) {
    return this.api.deleteAPI('product/delete-product', product_id);
  }

  // Need to work on this more, not working yet
  updateProduct(product_id: any, productData: any) {
    const url = `${environment.apiUrl}/product/update-product/${product_id}`;
    return this.http.put(url, productData).subscribe((response) => {
    });
  }
}
