import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { BulkOrderFormService } from 'src/app/_services/bulk-order-form.service';

@Component({
  selector: 'app-bulk-orders-form',
  templateUrl: './bulk-orders-form.component.html',
  styleUrls: ['./bulk-orders-form.component.scss'],
})
export class BulkOrdersFormComponent implements OnInit {
  @Input() submitted = false;
  @Input() selectedOrderType: string = '';
  @Input() totalQty!: number;
  @Input() selectedType: any;
  maxQty: number = 160;
  @Output() quantityChange = new EventEmitter<number>();
  value1!: number;
  errorUneven = false;
  @Input() data: any;

  // Selected values for the dropdowns
  selectedBiocross: any;
  selectedCashpath: any;
  selectedCyto: any;
  selectedProfileBiocross: any;
  @Input() inputFormGroup: FormGroup | undefined; // Explicitly state that it’s a FormGroup

  constructor(private bulkOrderFormService: BulkOrderFormService) {
    this.selectedBiocross = '';
    this.selectedCashpath = '';
    this.selectedCyto = '';
    this.selectedProfileBiocross = '';
  }

  ngOnInit(): void {    
    if(this.data){
      this.selectedBiocross = this.biocrossOptions.find(
          (option) => option.value === this.data.biocross
      )?.value
      this.selectedCashpath = this.cashpathOptions.find(
          (option) => option.value === this.data.biocross
      )?.value
      this.selectedCyto = this.cytoOptions.find(
          (option) => option.value === this.data.biocross
      )?.value
      this.selectedProfileBiocross = this.profileBiocrossBooks.find(
          (option) => option.value === this.data.biocross
      )?.value
    }
  }

  // the biocross dropdown has a condition where the value of the dropdown is dependent on the selected order type
  biocrossOptions = [
    { label: 'Emalahleni', value: 'Emalahleni' },
    { label: 'Trichardt', value: 'Trichardt' },
  ];

  cashpathOptions = [
    { label: 'Carryline', value: 'Carryline' },
    { label: 'Cashpath', value: 'Cashpath' },
    { label: 'Emalahleni', value: 'Emalahleni' },
    { label: 'Trichardt', value: 'Trichardt' },
  ];

  cytoOptions = [
    { label: 'Cyto', value: 'Cyto' },
    { label: 'Downs', value: 'Downs' },
    { label: 'Gynae Test', value: 'Gynae Test' },
    { label: 'Gynae', value: 'Gynae' },
    { label: 'Histo', value: 'Histo' },
    { label: 'Emalahleni Cyto', value: 'Emalahleni Cyto' },
    { label: 'Emalahleni Downs', value: 'Emalahleni Downs' },
    { label: 'Emalahleni Gynae Test', value: 'Emalahleni Gynae Test' },
    { label: 'Emalahleni Gynae', value: 'Emalahleni Gynae' },
    { label: 'Trichardt Cyto', value: 'Trichardt Cyto' },
    { label: 'Trichardt Downs', value: 'Trichardt Downs' },
    { label: 'Trichardt Gynae Test', value: 'Trichardt Gynae Test' },
    { label: 'Trichardt Gynae', value: 'Trichardt Gynae' },
  ];

  profileBiocrossBooks = [
    { label: 'Emalahleni', value: 'Emalahleni' },
    { label: 'Trichardt', value: 'Trichardt' },
  ];

  sizes = [
    { value: 'A5', viewValue: 'A5' },
    { value: 'A4', viewValue: 'A4' },
  ];

  // Logic to determine if a dropdown is required for the order type
  hasDropdown(orderType: string): boolean {
    return (
      orderType === 'A5 Biocross' ||
      orderType === 'A4 Cashpath' ||
      orderType === 'A4 (Cyto, Downs, Gynae, Histo)'
    );
  }

  onQuantityChange() {
    let qty = this.inputFormGroup!.get('quantity')?.value || 0;
    this.quantityChange.emit(qty);

    // check if the qty is an odd number and increment the value by 1
    if (qty % 2 !== 0) {
      qty = 0;
      this.inputFormGroup?.patchValue({ quantity: qty });
      this.errorUneven = true;
    } else {
      this.errorUneven = false;
    }
  }
}
